import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Lock } from "@material-ui/icons";
import {
    TextField,
    InputAdornment,
    Button,
    Container,
    CssBaseline,
    Typography,
    CardContent,
    Card,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { withRouter } from "react-router";
import { changePassword } from "../../../services/user.service";
import { error, success } from "../../../services/toast.service";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        paddingTop: "100px",
        paddingLeft: 0,
        paddingRight: 0,
    },
    header: {
        color: "#737373",
    },
    card: {
        width: "fit-content",
        marginRight: "auto",
        marginLeft: "auto",
        padding: "22px",
    },
    backgroundColor: {
        background: "#FFF",
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

interface State {
    password: string;
    confirmPassword: string;
    showPassword: boolean;
}

const ResetPassword = (props: any) => {
    const query = new URLSearchParams(useLocation().search);

    const classes = useStyles();

    const [state, setState] = useState<State>({
        password: "",
        confirmPassword: "",
        showPassword: false,
    });

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword });
    };

    const changeUserPassword = async () => {
        const result = await changePassword(state.password, query.get("reset-password-token") as string);
        result.status === "success" ? success("Password was changed successfully!") : error(result.data);
        props.history.push("/sign-in");
    };

    return (
        <Container component="main" maxWidth="xs" className={classes.container}>
            <Card className={classes.card}>
                <CardContent>
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Typography component="h1" variant="h5" className={classes.header}>
                            Change your password
                        </Typography>
                        <form className={classes.form}>
                            <TextField
                                className={classes.backgroundColor}
                                placeholder="Password"
                                required={true}
                                onChange={handleChange("password")}
                                type={state.showPassword ? "text" : "password"}
                                id="outlined-start-adornment"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Lock onClick={handleClickShowPassword} />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                className={classes.backgroundColor}
                                placeholder="Confirm Password"
                                required={true}
                                onChange={handleChange("confirmPassword")}
                                type={state.showPassword ? "text" : "password"}
                                id="outlined-start-adornment"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Lock onClick={handleClickShowPassword} />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={() => changeUserPassword()}
                            >
                                Reset Password
                            </Button>
                        </form>
                    </div>
                </CardContent>
            </Card>
        </Container>
    );
};

export default withRouter(ResetPassword);
