import React, { useEffect, useCallback, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import InfiniteScroll from "react-infinite-scroll-component";
import Avatar from "@material-ui/core/Avatar";
import MessageItem from "./MessageItem";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../hooks";
import { IImages, IMessage, MessagePosition } from "../../../../stores/message/MessageStore";
import MessageInput from "./input";
import ChatToolbar from "./ChatToolbar";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CircularProgress from "@material-ui/core/CircularProgress";
import MediaPreview from "../../../common/MediaPreview";
import { error } from "../../../../services/toast.service";
import { banners, icons, sounds } from "../../../../utils/contants";
import MessageCommentItem from "./MessageCommentItem";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hideEditor: {
            display: "none",
        },
        loading: {
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: theme.spacing(1),
        },
        editor: {
            padding: theme.spacing(2),
            width: "100%",
            height: "unset",
            background: "white",
            marginTop: theme.spacing(1.5),
            position: "fixed",
            bottom: 0,
            [theme.breakpoints.up("md")]: {
                position: "absolute",
            },
        },
        container: {
            height: "100%",
        },
        messageList: {
            height: "100%",
            overflow: "auto",
            display: "flex",
            flexDirection: "column-reverse",
            " &::-webkit-scrollbar": {
                width: "12px",
                height: "10px",
                marginRight: "5px",
            },
            "&::-webkit-scrollbar-track": {
                background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
                background: "#A0A0A0",
                borderRadius: "7px",
                backgroundClip: "padding-box",
                border: "3px solid transparent",
                minHeight: "50px",
                "&:hover": {
                    background: "#A0A0A0",
                    backgroundClip: "padding-box",
                },
            },
            paddingBottom: "110px",
        },
        dropzone: {
            outline: "none",
            position: "relative",
        },
        messageEditWrapper: {
            color: "gray",
            fontSize: theme.spacing(1.5),
            marginTop: theme.spacing(1),
        },
        cancelMessageEdit: {
            color: "red",
            fontWeight: 600,
            cursor: "pointer",
            marginRight: theme.spacing(0.5),
        },
        typingWrapper: {
            width: "60px",
            height: "38px",
            padding: "0 10px",
        },
        typingIcon: {
            width: "40px",
            height: "65px",
            marginTop: "-36px",
        },
        avatarSender: {
            placeSelf: "flex-end",
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(2),
        },
        snooze: {
            backgroundImage: `url(${banners.snooze})`,
            backgroundRepeat: "no-repeat",
            height: "197px",
            width: "269px",
        },
        iconWrapper: {
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        messageMargin: {
            margin: theme.spacing(3),
            marginBottom: 0,
        },
        inputRoot: {
            borderRadius: "20px",
            padding: theme.spacing(2.5),
            width: "100%",
            backgroundColor: theme.palette.background.default,
        },
        flexColumn: {
            display: "flex",
            flexDirection: "column",
        },
        dropzoneModal: {
            width: "90%",
            top: "9%",
            right: "5%",
            height: "86%",
            position: "absolute",
            zIndex: 100,
            padding: "20px",
            border: "#1085f0 dashed",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: "20px",
        },
        paddingMobile: {
            display: "flex",
            flexDirection: "column-reverse",
            paddingTop: "58px",
        },
    })
);

const MessageList = observer((props: { toggleUserProfile: () => void }) => {
    const classes = useStyles();
    const { conversationStore, messageStore, userStore } = useStores();
    const { selectedConversation } = conversationStore;

    let currentMediaIndex = messageStore.imagesToPreview.index;
    let mediaToPreviewArray = messageStore.imagesToPreview.images;
    let currentMediaType = mediaToPreviewArray[currentMediaIndex]?.type;
    let currentMediaUrl = mediaToPreviewArray[currentMediaIndex]?.url
        ? mediaToPreviewArray[currentMediaIndex]?.url
        : mediaToPreviewArray[currentMediaIndex]?.localUrl;
    const conversationId = selectedConversation?._id;

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        if (acceptedFiles.length > 0) {
            messageStore.uploadFiles(acceptedFiles);
        }
        if (fileRejections && fileRejections.length > 0) {
            error("some files are not accepted");
        }
    }, []);

    const { acceptedFiles, getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
        onDrop,
        accept: "image/*, video/*, .pdf, .csv, .xls, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    const cancelEditMessage = () => {
        messageStore.setMessageToEdit(null);
    };
    if (!selectedConversation) {
        return (
            <div className={classes.iconWrapper} id="message-body">
                <div className={classes.snooze} />
            </div>
        );
    }

    const addNewMessage = () => {
        if (selectedConversation && conversationId) {
            messageStore.sendMessage(
                selectedConversation._id,
                messageStore.newMessages[conversationId].text,
                messageStore.newMessages[conversationId].media,
                messageStore.newMessages[conversationId].replyMessage,
                selectedConversation.provider
            );
        }
    };
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            width={1}
            height={1}
            {...getRootProps({ className: `${classes.dropzone}` })}
            id="message-body"
        >
            {isDragActive && (
                <div className={classes.dropzoneModal}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height="100%"
                        flexDirection="column"
                    >
                        <CloudUploadIcon />
                        <span>Drop file to upload</span>
                    </Box>
                </div>
            )}
            <ChatToolbar toggleUserProfile={props.toggleUserProfile} />
            <div id="scrollableDiv" className={classes.messageList}>
                <InfiniteScroll
                    dataLength={messageStore.messages.length}
                    next={() => messageStore.getMessagesByConversationId(messageStore.messages.length)}
                    hasMore={messageStore.messages.length < messageStore.total}
                    loader={<CircularProgress className={classes.loading} />}
                    style={{ display: "flex", flexDirection: "column-reverse" }}
                    inverse={true}
                    scrollableTarget="scrollableDiv"
                >
                    {selectedConversation && selectedConversation.typing ? (
                        <Box display="flex" mt="8px">
                            <Avatar
                                src={
                                    (conversationStore.selectedConversation &&
                                        conversationStore.selectedConversation.picture) ||
                                    ""
                                }
                                className={classes.avatarSender}
                            />

                            <div className={classes.typingWrapper}>
                                <img className={classes.typingIcon} src={icons.typing} />
                            </div>
                        </Box>
                    ) : null}
                    <div className={classes.paddingMobile}>
                        {messageStore.messages.map((message: IMessage, index: number) => {
                            let poss = messageStore.currentMessagePosition(message, index);
                            const isFirst = poss === MessagePosition.FIRST;
                            const isMiddle = poss === MessagePosition.MIDDLE;
                            const isLast = poss === MessagePosition.LAST;
                            const isLastMessage = index === messageStore.messages.length - 1;
                            //check for chat type
                            return (
                                <MessageItem
                                    index={index}
                                    key={index}
                                    message={message}
                                    isFirst={isFirst}
                                    isMiddle={isMiddle}
                                    isLast={isLast}
                                    isLastMessage={isLastMessage}
                                    contactPicture={
                                        (conversationStore.selectedConversation &&
                                            conversationStore.selectedConversation.picture) ||
                                        ""
                                    }
                                    userPicture={userStore.user.picture ? userStore.user.picture : icons.avatar}
                                />
                            );
                        })}
                    </div>
                </InfiniteScroll>
            </div>

            <Box
                alignContent="flex-end"
                className={clsx([classes.editor], {
                    [classes.hideEditor]: !conversationStore.selectedConversation?._id,
                })}
            >
                <MessageInput
                    onDropFiles={(acceptedFiles: any, fileRejections: any) => onDrop(acceptedFiles, fileRejections)}
                    rootStyling={classes.inputRoot}
                    addNewMessage={addNewMessage}
                    handleChange={messageStore.setMessageBody}
                    value={messageStore.newMessages[conversationId as string].text || null}
                    media={messageStore.getNewMessageMedia()}
                    removeUploadedMedia={(currentMedia: IImages) => messageStore.removeMediaFromMessage(currentMedia)}
                    previewUploadedMedia={(image: { imageFrom: string; imageUrl: string }) =>
                        messageStore.setImagesToPreview(image)
                    }
                />
                {messageStore.messageToEditId && (
                    <div className={classes.messageEditWrapper}>
                        You are editing a message. Click here to{" "}
                        <span className={classes.cancelMessageEdit} onClick={() => cancelEditMessage()}>
                            cancel
                        </span>
                        !
                    </div>
                )}

                <div>
                    {messageStore.imagesToPreview.images.length > 0 && (
                        <MediaPreview
                            mediaType={currentMediaType}
                            currentMediaIndex={currentMediaIndex}
                            mediaArrayLength={mediaToPreviewArray.length}
                            mediaUrl={currentMediaUrl}
                            previewLeftImage={() => messageStore.previewLeftImage()}
                            previewRightImage={() => messageStore.previewRightImage()}
                            closeDialog={() => messageStore.setImagesToPreview({ imageFrom: "reset", imageUrl: "" })}
                            openDialog={mediaToPreviewArray.length > 0 ? true : false}
                        />
                    )}
                </div>
            </Box>
        </Box>
    );
});

export default MessageList;
