import React, { useEffect, useRef } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { observer } from "mobx-react-lite";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import { io } from "socket.io-client";
import { withRouter } from "react-router";

import { useStores } from "../../hooks";
import Notifications from "../notifications/index";
import { INotification } from "../../stores/NotificationsStore";
import { setLocalStorage, getLocalStorage } from "../../services/localStorageservice";
import Managment from "../account/Managment";
import { logos } from "../../utils/contants";
import FeedBack from "./Feedback";
import { useCallbackOutsideElement } from "../../utils/helperFunctions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            display: "flex",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            justifyContent: "space-between",
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
            color: "#1085F0",
            fontWeight: "bold",
        },
        appBar: {
            zIndex: 1200,
            backgroundColor: "white",
        },
        notificationIcon: {
            width: "20px",
            height: "17.14px",
        },
        icons: {
            color: "#000000",
        },
        notificationNumber: {
            marginRight: theme.spacing(2),
            cursor: "pointer",
        },
        logo: {
            backgroundImage: `url(${logos.kiboMinified})`,
            width: "100px",
            height: "36px",
            marginTop: "3px",
            cursor: "pointer",
        },
        row: {
            display: "flex",
            alignItems: "center",
        },
        btn: {
            padding: theme.spacing(1),
        },
        badge: {
            top: "4px",
            right: "4px",
        },
    })
);

const NavBar = observer((props: any) => {
    const classes = useStyles();
    const { uiStore, notificationsStore, userStore, workspaceStore } = useStores();
    let workspace = workspaceStore.workspaces[0];

    useEffect(() => {
        const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT as string, { transports: ["websocket"] });
        socket.on("NOTIFICATIONS", (notification: INotification) => {
            notificationsStore.addNotification(notification);
            userStore.setUser({
                ...userStore.user,
                unreadNotifications: (userStore.user.unreadNotifications as number) + 1,
            });
            const user = JSON.parse(getLocalStorage("user") as string);
            setLocalStorage("user", JSON.stringify({ ...user, unreadNotifications: user.unreadNotifications + 1 }));
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const getWorkspaces = async () => {
        if (userStore.isAuthenticated && workspace) {
            props.history.push(`/workspace/${workspace._id}/inbox/`);
        } else {
            props.history.push("");
        }
    };

    const notificationRef = useRef<HTMLHeadingElement>(null);
    const mangmentRef = useRef<HTMLHeadingElement>(null);

    useCallbackOutsideElement(notificationRef, () => uiStore.setDisplayNotificationDropdown(false));
    useCallbackOutsideElement(mangmentRef, () => uiStore.setDisplayAccountDropdown(false));
    return (
        <div className={classes.root}>
            <div className={classes.logo} onClick={() => getWorkspaces()} />
            {userStore.user && (
                <div className={classes.row}>
                    <FeedBack />
                    <div ref={notificationRef}>
                        <Badge
                            badgeContent={userStore.user.unreadNotifications}
                            color="primary"
                            className={classes.notificationNumber}
                            classes={{ badge: classes.badge }}
                        >
                            <IconButton
                                onClick={() =>
                                    uiStore.setDisplayNotificationDropdown(!uiStore.displayNotificationsDropdown)
                                }
                                classes={{ root: classes.btn }}
                            >
                                <NotificationsIcon className={`${classes.icons} ${classes.notificationIcon}`} />
                            </IconButton>
                        </Badge>
                        {uiStore.displayNotificationsDropdown && <Notifications />}
                    </div>
                    <div ref={mangmentRef}>
                        <Managment />
                    </div>
                </div>
            )}
        </div>
    );
});

export default withRouter(NavBar);
