import React from "react";
import Rating from "react-rating";
import { icons } from "../../../../utils/contants";

const RatingComponent = () => {
    const ratingChanged = (newRating) => {
        console.log(newRating);
    };

    return (
        <div>
            <Rating
                readonly
                initialRating={2.6}
                emptySymbol={<img src={icons.emptyStar} />}
                fullSymbol={<img src={icons.fullStar} />}
                fractions={3}
            />
        </div>
    );
};

export default RatingComponent;
