import { observable, action, runInAction } from "mobx";
import { getLocalStorage, setLocalStorage } from "../services/localStorageservice";

import { StoreClassInterface, RootStore } from "./RootStore";
import { UserStore } from "./UserStore";
import { WorkspaceStore } from "./WorkspaceStore";

export class TourStore implements StoreClassInterface {
    @observable step: number = 0;
    @observable isTourOpen: boolean = false;
    @observable disableNavigation: boolean = false;
    @observable changeSteps: boolean = false;

    readonly name = "TourStore";
    readonly rootStore: RootStore;
    readonly workspaceStore: WorkspaceStore;
    readonly userStore: UserStore;
    readonly pageSize = 20;

    constructor(root: RootStore, workspaceStore: WorkspaceStore, userStore: UserStore) {
        this.rootStore = root;
        this.workspaceStore = workspaceStore;
        this.userStore = userStore;
        this.configureTourSettings();
    }

    @action.bound nextStep(): void {
        this.step = this.step + 1;
    }

    @action.bound prevStep(): void {
        this.step = this.step - 1;
    }
    @action.bound setIsTourOpen(value: boolean): void {
        this.isTourOpen = value;
    }

    @action.bound setStep(value: number): void {
        this.step = value;
    }
    @action.bound setDisableNavigation(value: boolean): void {
        this.disableNavigation = value;
    }
    @action.bound configureTourSettings(): void {
        const workspace = this.workspaceStore.workspaces[0];
        const tourCookie = getLocalStorage("tour");
        const currentLocation = window.location.pathname;
        if (tourCookie === "complete") {
            this.setIsTourOpen(false);
            return;
        }
        if (this.userStore.isAuthenticated) {
            if (workspace && workspace._id) {
                this.setIsTourOpen(true);
                this.changeSteps = true;
                if (`/workspace/${workspace._id}/channels/` === currentLocation) {
                    this.setStep(4);
                    this.setDisableNavigation(false);
                } else {
                    this.setStep(0);
                    this.setDisableNavigation(false);
                }
            } else {
                this.changeSteps = false;
                this.setIsTourOpen(true);
                this.setDisableNavigation(true);
                this.setStep(0);
            }
        }
    }
    @action.bound closeTour(): void {
        this.setIsTourOpen(false);
        setLocalStorage("tour", "complete");
    }
    @action shouldOpenTour(): boolean {
        const tourCookie = getLocalStorage("tour");
        const accountsNumber = this.workspaceStore.workspaces[0].accountsNumber;
        return tourCookie !== "complete" && accountsNumber === 0;
    }
}
