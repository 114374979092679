import React from "react";
import { configure } from "mobx";
import { RootStore } from "../stores/RootStore";
import { ErrorStore } from "../stores/ErrorStore";
import { UserStore } from "../stores/UserStore";
import { ConversationStore } from "../stores/ConversationStore";
import { MessageStore } from "../stores/message/MessageStore";
import { EditorStore } from "../stores/message/EditorStore";
import { CommentStore } from "../stores/message/CommentStore";
import { UiStore } from "../stores/UiStore";
import { NotificationsStore } from "../stores/NotificationsStore";
import { ContactStore } from "../stores/ContactStore";
import { ChannelStore } from "../stores/ChannelStore";
import { WorkspaceStore } from "../stores/WorkspaceStore";
import { TourStore } from "../stores/TourStore";

configure({
    enforceActions: "observed",
});

const rootStore = new RootStore();

export interface StoresContext {
    rootStore: RootStore;
    errorStore: ErrorStore;
    userStore: UserStore;
    conversationStore: ConversationStore;
    messageStore: MessageStore;
    editorStore: EditorStore;
    uiStore: UiStore;
    notificationsStore: NotificationsStore;
    contactStore: ContactStore;
    channelStore: ChannelStore;
    workspaceStore: WorkspaceStore;
    tourStore: TourStore;
    commentStore: CommentStore;
}

export const Context: React.Context<StoresContext> = React.createContext({
    rootStore: rootStore,
    errorStore: rootStore.errorStore,
    userStore: rootStore.userStore,
    conversationStore: rootStore.conversationStore,
    messageStore: rootStore.messageStore,
    editorStore: rootStore.editorStore,
    uiStore: rootStore.uiStore,
    notificationsStore: rootStore.notificationsStore,
    contactStore: rootStore.contactStore,
    channelStore: rootStore.channelStore,
    workspaceStore: rootStore.workspaceStore,
    tourStore: rootStore.tourStore,
    commentStore: rootStore.commentStore,
});
