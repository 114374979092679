import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import SettingsNavigation from "./SettingsNavigation";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            "justify-content": "flex-start",
        },
        wrapper: {
            width: "min-content",
        },
        row: {
            display: "grid",
            "grid-template-columns": "1fr",
            gridGap: "1em",
        },
        card: {
            borderRadius: "0px 0px 20px 20px",
            padding: theme.spacing(2),
            backgroundColor: "white",
        },
        label: {
            color: "#737373",
        },
    })
);

const Notifications = (): JSX.Element => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: false,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <SettingsNavigation />
                <div className={clsx(classes.row, classes.card)}>
                    <span>Miscellaneous</span>
                    <FormControlLabel
                        control={
                            <Switch checked={state.checkedA} onChange={handleChange} name="checkedA" color="primary" />
                        }
                        label={<span className={classes.label}> Email me when the cheduled queue is empty </span>}
                    />
                    <FormControlLabel
                        control={
                            <Switch checked={state.checkedB} onChange={handleChange} name="checkedB" color="primary" />
                        }
                        label={
                            <span className={classes.label}>
                                {" "}
                                Email me when a social account needs reauthentication
                            </span>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default Notifications;
