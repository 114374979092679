import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { emojis } from "../../../utils/contants";
import Emoji from "./Emoji";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            gap: "1em",
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "10px",
            boxShadow: "rgb(0 0 0 / 30%) 0px 4px 12px",
        },
        triangle: {
            width: 0,
            height: 0,
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            borderTop: "16px solid white",
            marginRight: "11px",
        },
        alignEnd: {
            display: "flex",
            justifyContent: "flex-end",
        },
    })
);

const EmojiPicker = (props: { handleEmojiClick: Function }) => {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.root}>
                {emojis.map((emoji, index) => {
                    return (
                        <div key={index}>
                            {" "}
                            <Emoji emoji={emoji} handleAddEmoji={(emoji) => props.handleEmojiClick(emoji)} />{" "}
                        </div>
                    );
                })}
            </div>
            <div className={classes.alignEnd}>
                <div className={classes.triangle} />
            </div>
        </div>
    );
};

export default EmojiPicker;
