import React, { useEffect } from "react";
import Tour from "reactour";
import { observer } from "mobx-react-lite";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useStores } from "../hooks";
import { Button } from "@material-ui/core";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const useStyles = makeStyles((theme: Theme) => ({
    link: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        cursor: "pointer",
    },
    lastStep: {
        display: "flex",
        flexDirection: "column",
    },
}));

const AppTour = observer(() => {
    const { tourStore } = useStores();
    const { step, disableNavigation, isTourOpen, setDisableNavigation, nextStep, prevStep, closeTour, changeSteps } =
        tourStore;
    const classes = useStyles();

    useEffect(() => {
        if (step === 3 || step === 4) {
            setDisableNavigation(true);
        }
    }, [step]);

    const workspaceStep = [
        {
            selector: "#first-step",
            content: (
                <div>
                    Welcome to Kibo! 😊
                    <br />
                    <br />
                    A workspace is made up of unique channels and members.
                    <br />
                    <br />
                    We're currently working on supporting multiple workspaces, which is ideal if you manage different
                    brands, but for now let's create your first workspace.
                </div>
            ),
        },
    ];

    const steps = [
        {
            selector: "#left-panel",
            content: `This is the navigation panel that will allow you to switch between Messages, Contacts, and Channels.`,
        },
        {
            selector: "#conversation-panel",
            content: `This is the navigation panel that will allow you to switch between Messages, Contacts, and Channels.`,
        },
        {
            selector: "#message-body",
            content: `This is the navigation panel that will allow you to switch between Messages, Contacts, and Channels.`,
        },
        {
            selector: "#connect-channel",
            content: `Let's connect your first channel by clicking here`,
        },
        {
            selector: "#add-channel",
            content: `To add a new channel, click here. `,
        },
        {
            selector: "#end-tour",
            content: () => (
                <div className={classes.lastStep}>
                    Select one of the supported channels and follow the instructions. <br />
                    If you have any questions please contact us via email at{" "}
                    <a className={classes.link} href="mailto:support@kibo.ai">
                        support@kibo.ai
                    </a>{" "}
                    or join our support group on{" "}
                    <a className={classes.link} href="https://www.facebook.com/groups/kiboconnect" target="_blank">
                        Facebook
                    </a>
                    .
                    <Button variant="contained" color="primary" onClick={() => closeTour()}>
                        Finish
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <Tour
            steps={changeSteps ? steps : workspaceStep}
            isOpen={isTourOpen}
            onRequestClose={() => closeTour()}
            disableFocusLock={true}
            nextStep={nextStep}
            startAt={step}
            goToStep={step}
            prevStep={prevStep}
            showNavigation={!disableNavigation}
            showNavigationNumber={!disableNavigation}
            showButtons={!disableNavigation}
            rounded={7}
            closeWithMask={false}
            onAfterOpen={(target) => disableBodyScroll(target)}
            onBeforeClose={(target) => enableBodyScroll(target)}
        />
    );
});

export default AppTour;
