import { observable } from "mobx";
import { ErrorStore } from "./ErrorStore";
import { UserStore } from "./UserStore";
import { ConversationStore } from "./ConversationStore";
import { MessageStore } from "./message/MessageStore";
import { EditorStore } from "./message/EditorStore";
import { CommentStore } from "./message/CommentStore";
import { UiStore } from "./UiStore";
import { NotificationsStore } from "./NotificationsStore";
import { ContactStore } from "./ContactStore";
import { ChannelStore } from "./ChannelStore";
import { WorkspaceStore } from "./WorkspaceStore";
import { TourStore } from "./TourStore";

export class RootStore {
    @observable initialized = false;
    readonly errorStore: ErrorStore;
    readonly userStore: UserStore;
    readonly conversationStore: ConversationStore;
    readonly messageStore: MessageStore;
    readonly editorStore: EditorStore;
    readonly uiStore: UiStore;
    readonly notificationsStore: NotificationsStore;
    readonly contactStore: ContactStore;
    readonly channelStore: ChannelStore;
    readonly workspaceStore: WorkspaceStore;
    readonly tourStore: TourStore;
    readonly commentStore: CommentStore;

    constructor() {
        this.uiStore = new UiStore(this);
        this.errorStore = new ErrorStore(this);
        this.workspaceStore = new WorkspaceStore(this);
        this.userStore = new UserStore(this, this.workspaceStore);
        this.conversationStore = new ConversationStore(this, this.uiStore);
        this.messageStore = new MessageStore(this);
        this.editorStore = new EditorStore(this);
        this.notificationsStore = new NotificationsStore(this);
        this.contactStore = new ContactStore(this, this.uiStore);
        this.channelStore = new ChannelStore(this);
        this.tourStore = new TourStore(this, this.workspaceStore, this.userStore);
        this.commentStore = new CommentStore(this);
    }
}

export type Store = { [key: string]: any };

export interface StoreClassInterface {
    readonly name: string;
}
