import React, { useState, useRef, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Dropzone from "react-dropzone";
import Box from "@material-ui/core/Box";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import { EditorState, Editor, convertToRaw, ContentState, getDefaultKeyBinding, Modifier, RichUtils } from "draft-js";
import { Guid } from "guid-typescript";

import { observer } from "mobx-react-lite";
import { useStores } from "../../../../../hooks";
import FilePreview from "../../../../common/MediaUploadPreview";
import clsx from "clsx";
import ReplyMessageComposer from "./ReplyMessageComposer";
import { useCallbackOutsideElement } from "../../../../../utils/helperFunctions";

import "draft-js/dist/Draft.css";
import { IImages } from "../../../../../stores/message/MessageStore";
import EmojiPicker from "../../../../common/emojiPicker/EmojiPicker";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            color: theme.palette.primary.main,
        },
        mediaWrapper: {
            display: "flex",
            maxWidth: "120px",
            flexDirection: "row-reverse",
        },
        media: {
            height: "100px",
            width: "100px",
            borderRadius: "16px",
        },
        videoIcon: {
            position: "absolute",
            color: "white",
        },
        sendIconWrapper: {
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(1.5),
        },
        sendIconRoot: {
            borderRadius: "50%",
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(1.5),
            cursor: "pointer",
            display: "flex",
        },
        medias: {
            overflow: "auto",
            " &::-webkit-scrollbar": {
                width: "12px",
                height: "10px",
                marginRight: "5px",
            },
            "&::-webkit-scrollbar-track": {
                background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
                background: "#A0A0A0",
                borderRadius: "7px",
                backgroundClip: "padding-box",
                border: "3px solid transparent",
                minHeight: "50px",
                "&:hover": {
                    background: "#A0A0A0",
                    backgroundClip: "padding-box",
                },
            },
        },
        dropzone: {
            outline: "none",
        },
        attachFileIcon: {
            cursor: "pointer",
        },
        emjoiPicker: {
            position: "absolute",
            bottom: "30px",
            right: "12%",
            zIndex: 99,
        },
        closeEmojiPicker: {
            display: "none",
        },
        emojiIcon: {
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            paddingRight: theme.spacing(1),
        },
        emojiIconPrimaryColor: {
            color: theme.palette.primary.main,
        },
        emjoiPickerWrapper: {
            position: "relative",
        },
        replyComposerRoot: {
            marginBottom: theme.spacing(1),
        },
        hideReplyComposer: {
            display: "none",
        },
        editorContainer: {
            cursor: "text",
            width: "100%",
            maxHeight: 200,
            overflowY: "auto",
        },
        editor: {
            width: "100%",
        },
    })
);

const InputField = observer(
    (props: {
        onDropFiles: Function;
        rootStyling: any;
        replyMessage?: any;
        addNewMessage: any;
        messageId?: string;
        value: string | null;
        handleChange: any;
        media: Array<IImages>;
        typingComments?: Boolean;
        previewUploadedMedia: Function;
        removeUploadedMedia: Function;
    }) => {
        const {
            onDropFiles,
            rootStyling,
            addNewMessage,
            replyMessage,
            messageId,
            value,
            handleChange,
            media,
            previewUploadedMedia,
            removeUploadedMedia,
            typingComments,
        } = props;
        const classes = useStyles();
        const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
        const { conversationStore, messageStore } = useStores();
        const wrapperRef = useRef<HTMLHeadingElement>(null);
        useCallbackOutsideElement(wrapperRef, () => setOpenEmojiPicker(false));
        const editorId: string = Guid.create().toString();
        const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
        const editor: any = React.useRef(null);
        const focusEditor = () => editor.current.focus();

        useEffect(() => {
            if (value?.trim()) {
                startWithContent(value);
            } else {
                let editorState = EditorState.createEmpty();
                handleEditorStateChange(editorState);
            }
        }, [conversationStore.selectedConversation]);

        const startWithContent = (value: string) => {
            let newEditor = EditorState.createWithContent(ContentState.createFromText(value));
            newEditor = EditorState.moveFocusToEnd(newEditor);
            handleEditorStateChange(newEditor);
        };

        const removeSelectedText = () => {
            const contentState = editorState.getCurrentContent();
            const selectionState = editorState.getSelection();
            const start = selectionState.getStartOffset();
            const end = selectionState.getEndOffset();
            if (start !== end) {
                let newContentState = Modifier.removeRange(contentState, selectionState, "backward");
                return EditorState.push(editorState, newContentState, "remove-range");
            }
            return editorState;
        };

        function addTextToCursor(text: string) {
            let nextEditorState = removeSelectedText();
            let newContentState = nextEditorState.getCurrentContent();
            const selectionState = editorState.getSelection();
            const start = selectionState.getStartOffset();
            const insertSelection = selectionState.merge({
                anchorOffset: start,
                focusOffset: start,
            });
            const newSelection = selectionState.merge({
                anchorOffset: start + text.length,
                focusOffset: start + text.length,
            });
            newContentState = Modifier.insertText(newContentState, insertSelection, text);
            nextEditorState = EditorState.push(nextEditorState, newContentState, "insert-characters");
            handleEditorStateChange(EditorState.forceSelection(nextEditorState, newSelection));
        }

        const handleKeyCommand = (command: string, editorState: EditorState, eventTimeStamp: number) => {
            if (command === "enter_command") {
                let editorState = EditorState.createEmpty();
                addNewMessage();
                handleEditorStateChange(editorState);
                return "handled";
            }
            if (command === "enter_shift_command") {
                scrollToCursorOnEnter();
            }

            return "not-handled";
        };

        const scrollToCursorOnEnter = () => {
            let nextEditorState = RichUtils.insertSoftNewline(editorState);
            nextEditorState = EditorState.forceSelection(nextEditorState, nextEditorState.getSelection());

            // Auto scroll if at the bottom and pressing enter
            let $element: any = document.getElementById(editorId);
            let shouldScroll = $element.scrollHeight - $element.scrollTop === $element.clientHeight;

            handleEditorStateChange(nextEditorState).then(() => {
                if (shouldScroll) {
                    $element.scrollTop = $element.scrollHeight;
                }
            });

            return "handled";
        };

        const myKeyBindingFn = (e: any) => {
            if (e.keyCode === 13 && e.shiftKey) {
                return "enter_shift_command";
            } else if (e.keyCode === 13) {
                return "enter_command";
            }
            return getDefaultKeyBinding(e);
        };

        const setTyping = (value: string) => {
            if (!typingComments) return;
            if (value.trim().length > 0 && !messageStore.isTyping) return messageStore.handleTyping(true);
            if (value.trim().length === 0 && messageStore.isTyping) return messageStore.handleTyping(true);
        };

        const handleEditorStateChange = (state: EditorState) => {
            let tempState = state;
            const blocks = convertToRaw(tempState.getCurrentContent()).blocks;
            const value = blocks.map((block) => (!block.text.trim() && "\n") || block.text).join("\n");
            if (value.length === 2) {
                tempState = EditorState.moveFocusToEnd(tempState);
            }
            setTyping(value);
            handleChange(value);
            setEditorState(tempState);
            return Promise.resolve(true);
        };
        const addEmoji = (emoji: any) => {
            addTextToCursor(emoji + " ");
        };

        return (
            <Box className={rootStyling}>
                <Box className={clsx({ [classes.hideReplyComposer]: !replyMessage }, [classes.replyComposerRoot])}>
                    <ReplyMessageComposer isChatMessage={false} replyMessage={replyMessage} />
                </Box>
                <Box display="flex" flexDirection="row">
                    <Box width={1} display="inline-grid">
                        <div className={classes.editorContainer} onClick={focusEditor} id={editorId}>
                            <div className={classes.editor}>
                                <Editor
                                    ref={editor}
                                    editorState={editorState}
                                    onChange={handleEditorStateChange}
                                    handleKeyCommand={handleKeyCommand}
                                    keyBindingFn={myKeyBindingFn}
                                    placeholder="Write something!"
                                />
                            </div>
                        </div>
                        <Box display="flex" className={classes.medias}>
                            {media.map((file, index) => (
                                <FilePreview
                                    key={index}
                                    index={index}
                                    previewUploadedMedia={previewUploadedMedia}
                                    removeUploadedMedia={removeUploadedMedia}
                                    currentMedia={file}
                                    selectedConversationId={
                                        conversationStore.selectedConversation
                                            ? conversationStore.selectedConversation._id
                                            : ""
                                    }
                                />
                            ))}
                        </Box>
                    </Box>
                    <Box alignItems={"flex-end"} display="flex">
                        <div className={classes.emjoiPickerWrapper} ref={wrapperRef}>
                            <div className={classes.emojiIcon} onClick={() => setOpenEmojiPicker(!openEmojiPicker)}>
                                <SentimentSatisfiedIcon
                                    className={clsx({ [classes.emojiIconPrimaryColor]: openEmojiPicker })}
                                />
                            </div>
                            <div
                                className={clsx({
                                    [classes.closeEmojiPicker]: !openEmojiPicker,
                                    [classes.emjoiPicker]: openEmojiPicker,
                                })}
                            >
                                <EmojiPicker
                                    handleEmojiClick={(emoji) => {
                                        addEmoji(emoji);
                                    }}
                                />
                            </div>
                        </div>
                        <Dropzone onDrop={(files: Array<File>) => props.onDropFiles(files)} multiple={true}>
                            {({ getRootProps, getInputProps }) => (
                                <Box className="container">
                                    <Box
                                        {...getRootProps({
                                            className: `${classes.dropzone}`,
                                        })}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <input {...getInputProps()} />
                                        <AttachFileIcon className={classes.attachFileIcon} />
                                    </Box>
                                </Box>
                            )}
                        </Dropzone>
                    </Box>
                </Box>
            </Box>
        );
    }
);

export default InputField;
