import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#1085F0",
        },
        secondary: {
            main: "#3D3133",
        },
        success: {
            main: "#4FDA6E",
        },
        error: {
            main: "#FF576B",
        },
        warning: {
            main: "#FF8863",
        },
        background: {
            default: "#F2F2F2",
            paper: "#FFFFFF",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    typography: {
        fontFamily: "Sarabun",
        fontSize: 16,
    },
});

export default theme;
