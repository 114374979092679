import { observable, action, runInAction } from "mobx";
import { StoreClassInterface, RootStore } from "./RootStore";
import { searchContacts, getContactProfile, updateContact } from "../services/contact.service";
import { IConversation } from "./ConversationStore";
import { UiStore } from "./UiStore";

export interface IContact {
    fullName: string;
    picture: string;
    provider: string;
    socialId: string;
    createdAt: Date;
    updatedAt: Date;
    email: string;
    gender: string;
    address: string;
    phone: string;
    _id: string;
    conversations: Array<IConversation>;
    website: string;
    notifications: boolean;
    language: string;
    privateNotes: string;
    company: string;
    jobTitle: string;
    employees: string;
    companyWebsite: string;
    companyAddress: string;
    workspaceId: string;
    data: Object;
}

export class ContactStore implements StoreClassInterface {
    @observable contacts: Array<IContact> = [];
    @observable selectedContact: null | IContact = null;
    @observable total: number = 0;
    @observable search: string = "";
    @observable isFetching: boolean = false;

    readonly name = "ContactStore";
    readonly rootStore: RootStore;
    readonly pageSize: number = 20;
    private readonly uiStore: UiStore;

    constructor(root: RootStore, uiStore: UiStore) {
        this.rootStore = root;
        this.uiStore = uiStore;
    }

    @action getContacts = async (skip: number = this.contacts.length) => {
        this.isFetching = true;

        const result = await searchContacts(this.pageSize, skip, this.search);

        runInAction(() => {
            this.contacts = skip === 0 ? result.data.contacts : [...this.contacts, ...result.data.contacts];
            this.total = result.data.total;
            this.isFetching = false;
        });
    };

    @action.bound updateSelectedContact = (key: string, value: string) => {
        if (key === "notifications") {
            let notificationState = value === "Enabled" ? true : false;
            this.selectedContact = { ...this.selectedContact, [key]: notificationState } as IContact;
        } else {
            this.selectedContact = { ...this.selectedContact, [key]: value } as IContact;
        }
    };
    @action.bound updateSelectedContactData = (value: string, key: string) => {
        if (this.selectedContact) {
            this.selectedContact.data = { ...this.selectedContact.data, [key]: value } as IContact;
        }
    };
    @action getContact = async (contactId: string) => {
        this.uiStore.setDisplayNavBar(true);
        const result = await getContactProfile(contactId);

        runInAction(() => {
            this.selectedContact = result.data;
        });
    };

    @action clearSelectedContact = () => {
        this.selectedContact = null;
        this.uiStore.setDisplayNavBar(false);
    };

    @action removePropertyFromContactData(key: string) {
        let contactData = this.selectedContact?.data;
        if (contactData) {
            delete this.selectedContact?.data[key as keyof Object];
        }
        this.saveUpdatedContact();
    }

    @action.bound addPropertyFromContactData(key: string, value: string) {
        if (this.selectedContact) {
            this.selectedContact.data = { ...this.selectedContact?.data, [key]: value };
        }
        this.saveUpdatedContact();
    }

    @action saveUpdatedContact = async () => {
        await updateContact(this.selectedContact as IContact);
    };

    @action setSearch = (search: string) => {
        this.search = search;
        setTimeout(() => {
            this.getContacts(0);
        }, 500);
    };

    @action reset = () => {
        this.search = "";
        this.contacts = [];
        this.total = 0;
    };
}
