import { observable, action, computed, runInAction } from "mobx";
import { getLocalStorage, setLocalStorage } from "../services/localStorageservice";
import { getWorkspacesByUser } from "../services/workspace.service";
import { StoreClassInterface, RootStore } from "./RootStore";

export interface IWorkspace {
    _id: string;
    workspaceId: string;
    userId: string;
}
export class WorkspaceStore implements StoreClassInterface {
    @observable workspaces: Array<any> = [];
    readonly name = "WorkspaceStore";
    readonly rootStore: RootStore;

    constructor(root: RootStore) {
        this.rootStore = root;
        if (this.workspaces.length === 0) {
            this.getWorkspaces();
        }
    }

    @action async getWorkspaces() {
        let workspaceFromCookie = getLocalStorage("workspace");
        workspaceFromCookie = workspaceFromCookie && JSON.parse(workspaceFromCookie as string);
        let workspaceFromServer: any = null;
        if (!workspaceFromCookie) {
            workspaceFromServer = await getWorkspacesByUser();
            workspaceFromServer &&
                setLocalStorage(
                    "workspace",
                    JSON.stringify({
                        ...workspaceFromServer.data.userWorkspace[0],
                        accountsNumber: workspaceFromServer.data.accountsNumber,
                    })
                );
        }
        if (workspaceFromCookie || workspaceFromServer) {
            runInAction(() => {
                this.workspaces = workspaceFromCookie
                    ? [workspaceFromCookie]
                    : [
                          {
                              ...workspaceFromServer.data.userWorkspace[0],
                              accountsNumber: workspaceFromServer.data.accountsNumber,
                          },
                      ];
            });
        }
        return true;
    }
}
