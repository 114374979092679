import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";

import { useTheme, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";
import Drawer from "../../../common/Drawer";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            height: "100%",
            [theme.breakpoints.down("sm")]: { width: "100%" },
        },
        drawerPaper: {
            width: "100%",
            height: "100%",
            position: "relative",
            backgroundColor: "white",
            borderTopRightRadius: "20px",
            [theme.breakpoints.up("md")]: { borderTopRightRadius: "0px" },
        },
        infoIcon: {
            cursor: "pointer",
        },
        displayNone: {
            display: "none",
        },
    })
);

const UserProfile = (props: { open: boolean; toggleUserProfile: () => void }) => {
    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.up("md"));

    return (
        <Box
            className={clsx([classes.root], {
                [classes.displayNone]: !mobile && !props.open,
            })}
        >
            <Drawer open={props.open} toggleUserProfile={props.toggleUserProfile} />
        </Box>
    );
};

export default UserProfile;
