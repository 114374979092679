import React from "react";
import { Link, useLocation } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import { observer } from "mobx-react-lite";
import clsx from "clsx";

import { useStores } from "../../../hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            overflow: "auto",
            backgroundColor: "white",
            borderRadius: "20px 20px 0px 0px",
            padding: 5,
            " &::-webkit-scrollbar": {
                width: "12px",
                height: "10px",
                marginRight: "5px",
            },
            "&::-webkit-scrollbar-track": {
                background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
                background: "#A0A0A0",
                borderRadius: "7px",
                backgroundClip: "padding-box",
                border: "3px solid transparent",
                minHeight: "50px",
                "&:hover": {
                    background: "#A0A0A0",
                    backgroundClip: "padding-box",
                },
            },
            marginBottom: 5,
        },
        element: {
            padding: 15,
            width: 150,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        firstElement: {
            borderRadius: "20px 0px 0px 0px",
        },
        lastElement: {
            borderRadius: "0px 20px 0px 0px",
        },
        selectedElement: {
            backgroundColor: theme.palette.background.default,
        },
        elementContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    })
);

const SettingsNavigation = observer(() => {
    const classes = useStyles();
    const location = useLocation();
    const { userStore } = useStores();

    return (
        <div className={classes.root}>
            <div className={classes.elementContainer}>
                <ListItem
                    onClick={() => {
                        userStore.initializeUserSettings();
                    }}
                    button
                    className={clsx(classes.firstElement, classes.element)}
                    component={Link}
                    to={"/settings/account"}
                    selected={location.pathname === "/settings/account" || location.pathname === "/settings"}
                    classes={{ selected: classes.selectedElement }}
                >
                    Account
                </ListItem>
            </div>
            <div className={classes.elementContainer}>
                <ListItem
                    onClick={() => {
                        userStore.initializeUserSettings();
                    }}
                    button
                    className={classes.element}
                    component={Link}
                    to={"/settings/access"}
                    selected={location.pathname === "/settings/access"}
                    classes={{ selected: classes.selectedElement }}
                >
                    Access & Login
                </ListItem>
            </div>
            {/* <div className={classes.elementContainer}>
                <ListItem
                    button
                    component={Link}
                    className={classes.element}
                    to={"/settings/billing"}
                    selected={location.pathname === "/settings/billing"}
                    classes={{ selected: classes.selectedElement }}
                >
                    Billing
                </ListItem>
            </div>
            <div className={classes.elementContainer}>
                <ListItem
                    button
                    component={Link}
                    className={classes.element}
                    to={"/settings/notifications"}
                    selected={location.pathname === "/settings/notifications"}
                    classes={{ selected: classes.selectedElement }}
                >
                    Notifications
                </ListItem>
            </div>
            <div className={classes.elementContainer}>
                <ListItem
                    button
                    component={Link}
                    className={clsx(classes.lastElement, classes.element)}
                    to={"/settings/bonus"}
                    selected={location.pathname === "/settings/bonus"}
                    classes={{ selected: classes.selectedElement }}
                >
                    Bonus
                </ListItem>
            </div> */}
        </div>
    );
});

export default SettingsNavigation;
