import { Grid, makeStyles, TextField } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import InputBase from "@material-ui/core/InputBase";

import AccountProvider from "../AccountProvider";
import { useStores } from "../../../../hooks";
import { addAccount, updateAccount } from "../../../../services/account.service";
import { error, success } from "../../../../services/toast.service";
import { uploadImage } from "../../../../services/upload.service";
import { getPhoneNumber, getPhoto } from "../../../../services/whatsapp.service";
import Modal from "../../../common/Modal";

const useStyles = makeStyles((theme) => ({
    backgroundColor: {
        background: "#FFF",
    },
    container: {
        minWidth: "320px",
        display: "grid",
        gap: "24px",
        [theme.breakpoints.down("sm")]: {
            minWidth: "250px",
        },
    },

    title: {
        color: "#6D6D6D",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2.5),
    },
    displayColumn: {
        display: "flex",
        flexDirection: "column",
    },
    input: {
        "& input": {
            background: theme.palette.background.default,
            border: "1px solid #fff",
            fontFamily: "SOURCE SANS PRO",
            color: "#3D3D3D",
            width: "100%",
            paddingLeft: theme.spacing(1),
        },
        "&: hover": {
            border: "none",
        },
        "& fieldset": {
            border: "none",
        },
    },
    placeholder: {
        fontSize: 14,
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(1),
    },
}));

const WhatsappKey = observer(
    (props: { open: boolean; setOpen: Function; addNewAccount: Function; isEdited: boolean }) => {
        const classes = useStyles();

        const { channelStore, workspaceStore } = useStores();
        const { selectedAccount } = channelStore;

        const [key, setKey] = useState<string>("");

        const createWhatsappChannel = async () => {
            let workspace = workspaceStore.workspaces[0];
            try {
                const phoneResult = await getPhoneNumber(key);
                // const photoResult = await getPhoto(key);
                // const photoUrl = "";
                // if (photoResult.data !== "") {
                //     const formData = new FormData();
                //     formData.append("images", photoResult.data);
                //     const res = await uploadImage(formData);
                // }

                if (!props.isEdited) {
                    const result = await addAccount({
                        provider: "whatsapp",
                        name: phoneResult.data,
                        picture: "",
                        key: key,
                        workspaceId: workspace?._id,
                    });

                    props.addNewAccount(result.data);
                } else {
                    if (selectedAccount && selectedAccount.name === phoneResult.data) {
                        await updateAccount({ ...selectedAccount, key: key });
                        props.addNewAccount({ ...selectedAccount, key: key });
                        success("Whatsapp account was updated successfully!");
                    } else {
                        error("Key provided is not valid for this whatsapp account!");
                    }
                }
            } catch {
                error(
                    props.isEdited
                        ? "There was an error reconnecting this account!"
                        : "There was an error adding this whatsapp account"
                );
            }
        };

        return (
            <Modal
                open={props.open}
                onClose={() => props.setOpen(false)}
                handleConfirm={() => createWhatsappChannel()}
                title={"Whatsapp Credentials"}
                disableBackdropClick={true}
            >
                <AccountProvider provider="whatsapp" />
                <div className={classes.title}>Enter the credentials to add your WhatsApp account</div>

                <div className={classes.container}>
                    <div className={classes.displayColumn}>
                        <span className={classes.placeholder}>360 Dialog Key*</span>
                        <InputBase
                            id="outlined-basic"
                            className={classes.input}
                            required={true}
                            onChange={(event) => setKey(event.target.value)}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
);

export default WhatsappKey;
