import React, { useState, useRef } from "react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { makeStyles, Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckIcon from "@material-ui/icons/Check";
import Button from "@material-ui/core/Button";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { useStores } from "../../../../hooks";
import Dropdown, { IDropdownData } from "../../../common/Dropdown";
import { icons } from "../../../../utils/contants";
import { useCallbackOutsideElement } from "../../../../utils/helperFunctions";
import ConfirmDialog from "../../../common/ConfirmDialog";

const useStyles = makeStyles((theme: Theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    toolbar: {
        alignItems: "center",
        paddingLeft: theme.spacing(2),
        minHeight: "60px",
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    avatarListItem: {
        minWidth: "40px",
    },
    appBar: {
        display: "flex",
        alignItems: "center",
        background: "white",
        boxShadow: "0 0 black",
        justifyContent: "space-between",
        [theme.breakpoints.up("md")]: {
            position: "absolute",
        },
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 99,
    },
    toolbarDisplay: {
        display: "none",
    },
    avatarText: {
        color: theme.palette.secondary.main,
        fontWeight: 700,
    },
    iconWrapper: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
        color: theme.palette.primary.main,
    },
    dropdownWrapper: {
        display: "flex",
        cursor: "pointer",
        position: "relative",
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    iconColor: {
        color: theme.palette.primary.main,
    },
    solvedButton: {
        color: theme.palette.success.main,
        borderRadius: "36px",
        border: "1px solid",
        borderColor: theme.palette.success.main,
        marginRight: theme.spacing(2),
        fontWeight: 700,
    },
    solvedIconWrapper: {
        color: "white",
        backgroundColor: theme.palette.success.main,
        borderRadius: "100%",
        display: "flex",
    },
    unsolvedButton: {
        color: theme.palette.secondary.main,
        borderRadius: "36px",
        border: "1px solid",
        borderColor: theme.palette.secondary.main,
        marginRight: theme.spacing(2),
        fontWeight: 700,
    },
    unsolvedIconWrapper: {
        color: "white",
        backgroundColor: theme.palette.secondary.main,
        borderRadius: "100%",
        display: "flex",
    },
    contactName: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        paddingRight: "10px",
        [theme.breakpoints.down("xs")]: {
            maxWidth: "130px !important",
        },
        [theme.breakpoints.down("md")]: {
            maxWidth: "200px",
        },
        maxWidth: "250px",
    },
}));

const ChatToolbar = observer((props: { toggleUserProfile: () => void }) => {
    const { conversationStore, uiStore } = useStores();
    const wrapperRef = useRef<HTMLHeadingElement>(null);
    const classes = useStyles();
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("sm"));
    const dropdownData: IDropdownData[] = [
        {
            value: "Mark as unread",
            handleClick: () => {
                conversationStore.markAsUnreadConversation(conversationStore.selectedConversation);
                uiStore.setChatToolbarActions(false);
            },
        },
        {
            value: "Delete Conversation",
            handleClick: () => {
                uiStore.setDisplayConfimDialog(true);
            },
            colorDanger: true,
        },
    ];
    if (!conversationStore.selectedConversation) {
        return null;
    }
    useCallbackOutsideElement(wrapperRef, () => uiStore.setChatToolbarActions(false));
    return (
        <Box
            className={clsx([classes.appBar], {
                [classes.toolbarDisplay]: !conversationStore.selectedConversation && !desktop,
            })}
        >
            <Box className={classes.iconWrapper}>
                <IconButton size="small" onClick={() => conversationStore.clearSelectedConversation()}>
                    <ArrowBackIcon className={classes.iconColor} />
                </IconButton>
            </Box>
            <Toolbar className={classes.toolbar}>
                <Box display="flex" onClick={() => !desktop && props.toggleUserProfile()}>
                    <ListItemAvatar className={classes.avatarListItem}>
                        <Avatar src={conversationStore.selectedConversation?.picture} className={classes.small} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography className={classes.avatarText}>
                                <span className={classes.contactName}>
                                    {" "}
                                    {conversationStore.selectedConversation?.contactName}
                                </span>
                            </Typography>
                        }
                    />
                </Box>
            </Toolbar>
            <Box display="flex" alignItems="center">
                {!desktop ? (
                    <IconButton
                        size="small"
                        onClick={() => conversationStore.solveConversation(conversationStore.selectedConversation)}
                    >
                        {conversationStore.selectedConversation?.resolved ? (
                            <img src={icons.solvedMobile} />
                        ) : (
                            <img src={icons.solveMobile} />
                        )}
                    </IconButton>
                ) : (
                    <Button
                        variant="outlined"
                        onClick={() => conversationStore.solveConversation(conversationStore.selectedConversation)}
                        className={clsx({
                            [classes.solvedButton]: conversationStore.selectedConversation?.resolved,
                            [classes.unsolvedButton]: !conversationStore.selectedConversation?.resolved,
                        })}
                        startIcon={
                            <div
                                className={clsx({
                                    [classes.solvedIconWrapper]: conversationStore.selectedConversation?.resolved,
                                    [classes.unsolvedIconWrapper]: !conversationStore.selectedConversation?.resolved,
                                })}
                            >
                                <CheckIcon />
                            </div>
                        }
                    >
                        {conversationStore.selectedConversation?.resolved ? "Done" : "Solve"}
                    </Button>
                )}

                <div className={classes.dropdownWrapper} ref={wrapperRef}>
                    <Dropdown
                        type="string"
                        hasInputField={false}
                        value={null}
                        dropdownData={dropdownData}
                        isDropdownOpen={uiStore.displayChatToolbarActions}
                        toggleDropdown={uiStore.setChatToolbarActions}
                        startAdornmentIcon={
                            <IconButton size="small">
                                <MoreVertIcon color="secondary" />
                            </IconButton>
                        }
                    />
                </div>
                <ConfirmDialog
                    title={"Delete Conversation?"}
                    isFetching={false}
                    body={"Are you sure?"}
                    handleOnConfrim={() => {
                        conversationStore.deleteConv();
                        uiStore.setChatToolbarActions(false);
                        uiStore.setDisplayConfimDialog(false);
                    }}
                />
            </Box>
        </Box>
    );
});

export default ChatToolbar;
