import React from "react";
import { makeStyles, Theme, createStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import SearchIcon from "@material-ui/icons/Search";
import { observer } from "mobx-react-lite";

import { useStores } from "../../../hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: "5px",
            [theme.breakpoints.up("md")]: {
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "white",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            maxHeight: "64px",
        },
        select: {
            width: "100%",
            borderRadius: "30px",
            height: "41px",
            background: "white",
            "&:focus": {
                background: "white",
            },
        },
        selectLabel: {
            left: "20px",
            top: "10px",
            position: "absolute",
            color: "#a09c9c",
        },
        selectFormControl: {
            width: "280px",
            padding: theme.spacing(2),
        },
        searchBarContainer: {
            marginTop: "18px",
            marginBottom: theme.spacing(2),
        },
        searchBar: {
            border: "none",
            background: "transparent",
            width: "100%",
            outline: "none",
            padding: 0,
            maxWidth: 250,
        },
    })
);

const ContactFilters = observer(() => {
    const classes = useStyles();
    const { contactStore } = useStores();
    const theme = useTheme();

    return (
        <Box className={classes.root}>
            <Box display="flex" alignItems="center" className={classes.searchBarContainer}>
                <Box mr={theme.spacing(0.2)}>
                    <SearchIcon />
                </Box>
                <input
                    type="text"
                    placeholder="Search"
                    className={classes.searchBar}
                    onChange={(event) => contactStore.setSearch(event.target.value as string)}
                />
            </Box>
        </Box>
    );
});

export default ContactFilters;
