import React from "react";
import { useStores } from "../../../hooks";
import { deleteAccount } from "../../../services/account.service";
import { IChannel } from "../../../stores/ChannelStore";

import MittoModal from "../../common/Modal";

const DeleteChannel = (props: { open: boolean; setOpen: Function; account: IChannel }) => {
    const { channelStore } = useStores();
    const deleteChannel = async (id: string) => {
        await deleteAccount(id);
        channelStore.deleteAccount(id);
        props.setOpen(false);
    };
    return (
        <MittoModal
            open={props.open}
            onClose={() => props.setOpen(false)}
            title={"Delete Channel"}
            handleConfirm={() => deleteChannel(props.account._id as string)}
        >
            Are you sure you want to delete this channel ?
        </MittoModal>
    );
};

export default DeleteChannel;
