import React, { useState, useRef } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import Box from "@material-ui/core/Box";

import { observer } from "mobx-react-lite";
import { useStores } from "../../../../../hooks";
import { useCallbackOutsideElement } from "../../../../../utils/helperFunctions";
import InputField from "./InputField";
import { IImages } from "../../../../../stores/message/MessageStore";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            color: theme.palette.primary.main,
        },
        media: {
            height: "100px",
            width: "100px",
            borderRadius: "16px",
        },
        videoIcon: {
            position: "absolute",
            color: "white",
        },
        sendIconWrapper: {
            paddingLeft: theme.spacing(2),
            paddingBottom: theme.spacing(1.5),
        },
        sendIconRoot: {
            borderRadius: "50%",
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(1.5),
            cursor: "pointer",
            display: "flex",
        },
        medias: {
            overflow: "auto",
            " &::-webkit-scrollbar": {
                width: "12px",
                height: "10px",
                marginRight: "5px",
            },
            "&::-webkit-scrollbar-track": {
                background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
                background: "#A0A0A0",
                borderRadius: "7px",
                backgroundClip: "padding-box",
                border: "3px solid transparent",
                minHeight: "50px",
                "&:hover": {
                    background: "#A0A0A0",
                    backgroundClip: "padding-box",
                },
            },
        },
        dropzone: {
            outline: "none",
        },
        attachFileIcon: {
            cursor: "pointer",
        },
        emjoiPicker: {
            position: "absolute",
            bottom: "30px",
            right: "12%",
            zIndex: 99,
        },
        closeEmojiPicker: {
            display: "none",
        },
        emojiIcon: {
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            paddingRight: theme.spacing(1),
        },
        emojiIconPrimaryColor: {
            color: theme.palette.primary.main,
        },
        emjoiPickerWrapper: {
            position: "relative",
        },
        replyComposerRoot: {
            marginBottom: theme.spacing(1),
        },
        hideReplyComposer: {
            display: "none",
        },
    })
);

const MessageInput = observer(
    (props: {
        onDropFiles: Function;
        rootStyling: any;
        addNewMessage: Function;
        handleChange: any;
        value: string | null;
        media: Array<IImages>;
        previewUploadedMedia: Function;
        removeUploadedMedia: Function;
    }) => {
        const {
            addNewMessage,
            onDropFiles,
            rootStyling,
            handleChange,
            value,
            media,
            previewUploadedMedia,
            removeUploadedMedia,
        } = props;

        const classes = useStyles();
        const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
        const { conversationStore, messageStore, editorStore } = useStores();
        const { selectedConversation } = conversationStore;
        const conversationId = selectedConversation?._id;
        const replyMessage = conversationId ? messageStore.newMessages[conversationId].replyMessage : null;
        const wrapperRef = useRef<HTMLHeadingElement>(null);
        useCallbackOutsideElement(wrapperRef, () => setOpenEmojiPicker(false));

        return (
            <Box display="flex">
                <InputField
                    replyMessage={replyMessage}
                    addNewMessage={addNewMessage}
                    rootStyling={rootStyling}
                    onDropFiles={onDropFiles}
                    value={value}
                    handleChange={handleChange}
                    media={media}
                    previewUploadedMedia={(image) => previewUploadedMedia(image)}
                    removeUploadedMedia={(currentMedia) => removeUploadedMedia(currentMedia)}
                />
                <Box alignItems={"flex-end"} display="flex" className={classes.sendIconWrapper}>
                    <Box className={classes.sendIconRoot}>
                        <SendIcon onClick={() => addNewMessage()} className={classes.icon} />
                    </Box>
                </Box>
            </Box>
        );
    }
);

export default MessageInput;
