import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { observer } from "mobx-react-lite";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { useStores } from "../../hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: 20,
        },
        button: {
            border: "1.5px solid",
            borderRadius: 24,
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main,
            fontSize: 13,
            "&:hover": {
                backgroundColor: "rgba(255, 87, 107, 0.04)",
            },
        },
        title: {
            textAlign: "center",
            color: theme.palette.secondary.main,
            fontWeight: "bold",
        },
        body: {
            textAlign: "center",
            color: theme.palette.secondary.main,
        },
        footer: {
            display: "flex",
            justifyContent: "center",
        },
        cancelBtn: {
            border: " 1.5px solid #737373",
            borderRadius: 24,
            color: theme.palette.secondary.main,
            fontSize: 13,
            marginRight: 20,
        },
        container: {
            display: "grid",
            gridAutoRows: "1fr",
            gridGap: "1em",
            padding: "20px",
            "grid-auto-rows": "minmax(min-content, max-content)",
        },
    })
);

const ConfirmDialog = observer(
    (props: { title: string; body: string; handleOnConfrim: Function; isFetching: boolean }) => {
        const { title, body, handleOnConfrim, isFetching } = props;
        const { uiStore } = useStores();
        const classes = useStyles();

        const handleClose = () => {
            uiStore.setDisplayConfimDialog(false);
        };
        return (
            <div>
                <Dialog
                    open={uiStore.displayConfimDialog}
                    classes={{ paper: classes.root }}
                    onClose={() => {
                        isFetching && uiStore.setDisplayConfimDialog(true);
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xs"
                >
                    <div className={classes.container}>
                        <span className={classes.title} id="alert-dialog-title">
                            {title}
                        </span>
                        <span className={classes.body} id="alert-dialog-description">
                            {body}
                        </span>
                        <div className={classes.footer}>
                            <Button
                                variant="outlined"
                                className={classes.cancelBtn}
                                onClick={handleClose}
                                disabled={isFetching}
                            >
                                Cancel
                            </Button>
                            <Button variant="outlined" className={classes.button} onClick={() => handleOnConfrim()}>
                                Agree
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
);

export default ConfirmDialog;
