import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            border: "none",
            background: "transparent",
            cursor: "pointer",
            fontSize: "1.3em",
            width: "1.5em",
            height: "1.5em",
            padding: 0,
            margin: 0,
            "&:hover": {
                background: "#e8f4f9",
                borderRadius: "5px",
            },
        },
    })
);

const Emoji = (props: { emoji: any; handleAddEmoji: Function }) => {
    const classes = useStyles();
    const { emoji } = props;
    // const handleAddEmoji = (emoji)=>{
    //     console.log(emoji)
    // }
    return (
        <button
            className={classes.root}
            onClick={() => {
                props.handleAddEmoji(emoji);
            }}
        >
            {emoji}
        </button>
    );
};

export default Emoji;
