import { observable, action, runInAction } from "mobx";
import { setLocalStorage } from "../services/localStorageservice";
import { updateUserProfile } from "../services/user.service";
import { StoreClassInterface, RootStore } from "./RootStore";

export class UiStore implements StoreClassInterface {
    @observable displayAccountDropdown: boolean = false;
    @observable displayTimezoneDropdown: boolean = false;
    @observable displayNotificationsDropdown: boolean = false;
    @observable displayTimeFormatDropdown: boolean = false;
    @observable displayConfimDialog: boolean = false;
    @observable displayChatFiltersDropdown: boolean = false;
    @observable displayNavBar: boolean = false;
    @observable displayChatToolbarActions: boolean = false;
    @observable displayMessageToolbarActions: boolean = false;
    @observable isTabActive: boolean = true;

    readonly name = "UiStore";
    readonly rootStore: RootStore;

    constructor(root: RootStore) {
        this.rootStore = root;
    }

    @action.bound setDisplayAccountDropdown(value: boolean) {
        this.displayAccountDropdown = value;
    }
    @action setIsTabActive(value: boolean) {
        this.isTabActive = value;
    }
    @action.bound async setDisplayNotificationDropdown(value: boolean) {
        const user = this.rootStore.userStore.user;
        if (value && (user.unreadNotifications as number) > 0) {
            await updateUserProfile({ ...user, unreadNotifications: 0 });
            this.rootStore.userStore.setUser({
                ...this.rootStore.userStore.user,
                unreadNotifications: 0,
            });
            setLocalStorage("user", JSON.stringify({ ...user, unreadNotifications: 0 }));
        }
        runInAction(() => {
            this.displayNotificationsDropdown = value;
        });
    }
    @action.bound setDisplayTimezoneDropdown(value: boolean) {
        this.displayTimezoneDropdown = value;
    }
    @action.bound setDisplayTimeFormatDropdown(value: boolean) {
        this.displayTimeFormatDropdown = value;
    }

    @action.bound setDisplayConfimDialog(value: boolean) {
        this.displayConfimDialog = value;
    }
    @action.bound setChatFiltersDropdown(value: boolean) {
        this.displayChatFiltersDropdown = value;
    }

    @action.bound setDisplayNavBar(value: boolean) {
        this.displayNavBar = value;
    }

    @action.bound setChatToolbarActions(value: boolean) {
        this.displayChatToolbarActions = value;
    }

    @action.bound setMessageToolbarActions(value: boolean) {
        this.displayMessageToolbarActions = value;
    }
}
