import { observable, action, reaction } from "mobx";
import { EditorState, convertToRaw, ContentState, Modifier, RichUtils } from "draft-js";
import { Guid } from "guid-typescript";

import { StoreClassInterface, RootStore } from "../RootStore";
import { setIsTyping } from "../../services/conversation.service";

export class EditorStore implements StoreClassInterface {
    @observable editorState: EditorState = EditorState.createEmpty();
    @observable isTyping: boolean = false;

    readonly name = "EditorStore";
    readonly rootStore: RootStore;
    readonly editorId: string = Guid.create().toString();

    constructor(root: RootStore) {
        this.rootStore = root;
        reaction(
            () => this.rootStore.messageStore.currentConversationId,
            () => this.onConversationChange()
        );
    }
    @action handleTyping(value: boolean) {
        this.isTyping = value;
        setIsTyping(this.rootStore.messageStore.currentConversationId, this.isTyping);
        this.rootStore.messageStore.setMessagesAsRead();
    }

    @action setEditorState(state: EditorState): void {
        this.editorState = state;
        const blocks = convertToRaw(this.editorState.getCurrentContent()).blocks;
        const value = blocks.map((block) => (!block.text.trim() && "\n") || block.text).join("\n");
        if (value.trim().length > 0 && !this.isTyping) {
            this.handleTyping(true);
        } else if (value.trim().length === 0 && this.isTyping) {
            this.handleTyping(false);
        }
        this.rootStore.messageStore.setMessageBody(value);
    }

    @action onConversationChange(): void {
        const messageStore = this.rootStore.messageStore;
        let editorState = EditorState.createEmpty();
        const message = messageStore.getNewMessageBody();
        if (message) {
            let contentState = ContentState.createFromText(message);
            editorState = EditorState.createWithContent(contentState);
        }
        editorState = EditorState.moveFocusToEnd(editorState);
        this.onEditorStateChanged(editorState);
    }

    @action.bound onEditorStateChanged(state: EditorState) {
        this.setEditorState(state);
        return Promise.resolve(true);
    }

    @action addTextToCursor(text: string) {
        let nextEditorState = this.removeSelectedText();
        let newContentState = nextEditorState.getCurrentContent();
        const selectionState = this.editorState.getSelection();
        const start = selectionState.getStartOffset();
        const insertSelection = selectionState.merge({
            anchorOffset: start,
            focusOffset: start,
        });
        const newSelection = selectionState.merge({
            anchorOffset: start + text.length,
            focusOffset: start + text.length,
        });
        newContentState = Modifier.insertText(newContentState, insertSelection, text);
        nextEditorState = EditorState.push(nextEditorState, newContentState, "insert-characters");
        this.onEditorStateChanged(EditorState.forceSelection(nextEditorState, newSelection));
    }

    removeSelectedText = () => {
        const contentState = this.editorState.getCurrentContent();
        const selectionState = this.editorState.getSelection();
        const start = selectionState.getStartOffset();
        const end = selectionState.getEndOffset();
        if (start !== end) {
            let newContentState = Modifier.removeRange(contentState, selectionState, "backward");
            return EditorState.push(this.editorState, newContentState, "remove-range");
        }
        return this.editorState;
    };

    @action scrollToCursorOnEnter() {
        let nextEditorState = RichUtils.insertSoftNewline(this.editorState);
        nextEditorState = EditorState.forceSelection(nextEditorState, nextEditorState.getSelection());

        // Auto scroll if at the bottom and pressing enter
        let $element: any = document.getElementById(this.editorId);
        let shouldScroll = $element.scrollHeight - $element.scrollTop === $element.clientHeight;

        this.onEditorStateChanged(nextEditorState).then(() => {
            if (shouldScroll) {
                $element.scrollTop = $element.scrollHeight;
            }
        });

        return "handled";
    }
    //refactor this also
    @action startWithContent(value: string) {
        this.setEditorState(EditorState.createWithContent(ContentState.createFromText(value)));
    }
}
