import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { getFeedbackToken } from "../../services/feedback.service";

declare global {
    interface Window {
        nolt: any;
    }
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
            fontWeight: 600,
            cursor: "pointer",
            marginRight: theme.spacing(2),
        },
    })
);
const FeedBack = () => {
    const classes = useStyles();
    const SDK_URL = "https://cdn.nolt.io/widgets.js";
    const BOARD_URL = "https://feedback.kibo.ai";
    let JWT: string | null = null;
    const handleScriptLoad = () => {
        window.nolt("init", { selector: "#nolt", url: BOARD_URL });
        if (JWT) {
            window.nolt("identify", { jwt: JWT as string });
            setTimeout(function () {
                document.getElementById("nolt")?.click();
            }, 100);
        }
    };

    const loadScript = async () => {
        if (typeof window.nolt !== "function") {
            const script = document.createElement("script");
            script.src = SDK_URL;
            script.async = false;
            script.onload = () => handleScriptLoad();
            document.body.appendChild(script);
            const res = await getFeedbackToken();
            JWT = res.data;
            setTimeout(function () {
                document.getElementById("nolt")?.click();
            }, 100);
        } else {
            document.getElementById("nolt")?.click();
        }
    };

    return (
        <div className={`${classes.root}`} id="nolt" onClick={() => loadScript()}>
            Feedback
        </div>
    );
};

export default FeedBack;
