import React, { useEffect, useRef } from "react";
import { withRouter } from "react-router";
import clsx from "clsx";
import { Theme } from "@material-ui/core/styles";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import ConversationListItem from "./ConversationListItem";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import InfiniteScroll from "react-infinite-scroll-component";
import { observer } from "mobx-react-lite";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useStores } from "../../../../hooks";
import { IConversation } from "../../../../stores/ConversationStore";
import Search from "./Search";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            [theme.breakpoints.up("md")]: {
                backgroundColor: "white",
            },
        },
        list: {
            padding: 0,
            [theme.breakpoints.down("sm")]: {
                paddingBottom: "58px",
            },
        },
        conversationDivider: {
            height: "5px",
            background: theme.palette.background.default,
        },
        loading: {
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: theme.spacing(1),
        },
        conversationList: {
            height: "100%",
            overflow: "auto",
            paddingBottom: "65px",
            display: "flex",
            flexDirection: "column",
            "&::-webkit-scrollbar": {
                width: "12px",
                height: "10px",
                marginRight: "5px",
            },
            "&::-webkit-scrollbar-track": {
                background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
                background: "#A0A0A0",
                borderRadius: "7px",
                backgroundClip: "padding-box",
                border: "3px solid transparent",
                minHeight: "50px",
                "&:hover": {
                    background: "#A0A0A0",
                    backgroundClip: "padding-box",
                },
            },
        },
    })
);

const ConversationList = observer((props: any) => {
    const { conversationStore } = useStores();
    const classes = useStyles();

    useEffect(() => {
        if (conversationStore.conversations.length === 0) {
            conversationStore.getConversations();
        }
    }, []);

    return (
        <div className={clsx([classes.root])} id="conversation-panel">
            {/* <CssBaseline /> */}
            <div>
                <Search />
                <Divider className={classes.conversationDivider} />
            </div>
            <Box id="scrollableConvDiv" className={classes.conversationList}>
                <InfiniteScroll
                    dataLength={conversationStore.conversations.length}
                    next={() => conversationStore.getConversations()}
                    hasMore={conversationStore.conversations.length < conversationStore.total}
                    loader={
                        <Box component="div" textAlign="center">
                            <CircularProgress className={classes.loading} />{" "}
                        </Box>
                    }
                    style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
                    scrollableTarget="scrollableConvDiv"
                >
                    <Box component="div">
                        <List className={classes.list}>
                            {conversationStore.conversations.map((conversation: IConversation, index: number) => {
                                return (
                                    <Box key={index}>
                                        <ConversationListItem conversation={conversation} history={props.history} />
                                        {index !== conversationStore.conversations.length - 1 && (
                                            <Divider className={classes.conversationDivider} />
                                        )}
                                    </Box>
                                );
                            })}
                            <Divider className={classes.conversationDivider} />
                        </List>
                    </Box>
                </InfiniteScroll>
            </Box>
        </div>
    );
});

export default withRouter(ConversationList);
