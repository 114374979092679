import { whatsappAxiosInstance } from "../utils/axios.instance";

export const getPhoneNumber = async (key: string) => {
    const response = await whatsappAxiosInstance({
        method: "GET",
        url: `phone/${key}`,
    });

    return response.data;
};

export const getPhoto = async (key: string) => {
    const response = await whatsappAxiosInstance({
        method: "GET",
        url: `photo/${key}`,
    });

    return response.data;
};
