import { observable, action, computed, runInAction } from "mobx";
import momentTz from "moment-timezone";
import { Guid } from "guid-typescript";

import { StoreClassInterface, RootStore } from "./RootStore";
import {
    signUp,
    isUserHuman,
    updateUserProfile,
    logout,
    deleteUser,
    changeEmail,
    updatePassword,
    signIn,
} from "../services/user.service";
import { deleteLocalStorage, getLocalStorage, setLocalStorage } from "../services/localStorageservice";
import { uploadFiles } from "../services/upload.service";
import Auth from "../services/auth.service";
import { error } from "../services/toast.service";
import { WorkspaceStore } from "./WorkspaceStore";

export interface IUser {
    _id?: string;
    fullName?: string;
    email: string;
    password: string;
    confirmPassword?: string;
    timezone?: string;
    picture?: string;
    timeFormat?: string;
    currentPassword?: string;
    unreadNotifications?: number;
    displayName?: string;
}
export enum TimeFormat {
    Military = "24-hr format(military clock)",
    Standart = "12-hr format (AM/PM)",
}
export class UserStore implements StoreClassInterface {
    @observable userError: IUser | null = null;
    @observable user: IUser = {
        fullName: "",
        email: "",
        password: "",
        confirmPassword: "",
        timezone: "",
        timeFormat: TimeFormat.Military,
        displayName: "",
    };
    @observable userSettings: IUser = {
        fullName: "",
        email: "",
        password: "",
        confirmPassword: "",
        timezone: "",
        timeFormat: "",
        displayName: "",
        picture: "",
    };
    @observable humanUser: boolean = false;
    @observable searchedTimezones = momentTz.tz.names();
    @observable searchedTimeFormats = [TimeFormat.Military, TimeFormat.Standart];
    @observable newPassword = "";
    @observable confirmPassword = "";
    @observable currentPassword = "";
    @observable isFetching = false;
    @observable isAuthenticated: IUser | null = null;

    readonly name = "UserStore";
    readonly rootStore: RootStore;
    readonly workspaceStore: WorkspaceStore;

    constructor(root: RootStore, workspaceStore: WorkspaceStore) {
        this.rootStore = root;
        this.workspaceStore = workspaceStore;
        this.initializeUserSettings();
        let res = JSON.parse(getLocalStorage("user") as any);
        this.isAuthenticated = JSON.parse(Auth.isAuthenticated() as string);
        runInAction(() => {
            this.user = res;
            this.initializeUserSettings();
        });
    }

    @action setUser = (user: IUser): void => {
        this.user = user;
        this.userSettings = user;
        this.isAuthenticated = user;
    };

    @action signIn = async (email: string, password: string) => {
        try {
            const result = await signIn(email, password);
            setLocalStorage("user", JSON.stringify(result.data.user));
            setLocalStorage("token", result.data.token);
            setLocalStorage("userProfile", true);
            await this.workspaceStore.getWorkspaces();
            this.setUser(result.data.user);
        } catch {
            error("Username or password is not correct");
        }
    };
    @action.bound initializeUserSettings() {
        this.userSettings = Object.assign({}, this.user);
    }

    @computed get userValidFullName(): boolean {
        return this.userError?.fullName ? true : false;
    }

    @computed get userValdiEmail(): boolean {
        return this.userError?.email ? true : false;
    }

    @computed get userValidPassword(): boolean {
        return this.userError?.password ? true : false;
    }

    @computed get userValidConfimPassword(): boolean {
        return this.userError?.confirmPassword ? true : false;
    }

    @action signUp = async (user: IUser) => {
        let res = await signUp(user);
        return res;
    };

    @action isUserHuman = async (value: string | null) => {
        let res = await isUserHuman(value);
        this.humanUser = res.data;
    };

    @action validateUser = () => {
        let userAttemtingToLogIn: IUser = {
            email: "",
            password: "",
        };
        userAttemtingToLogIn.fullName = this.user.fullName ? "" : "This field is required.";
        userAttemtingToLogIn.email = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.user.email)
            ? ""
            : "This is not a valid email.";
        // --> /^[A-Za-z]\w{7,14}$/.test(this.user.password)
        userAttemtingToLogIn.password =
            this.user.password.length > 7
                ? ""
                : "Password must have 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter";
        userAttemtingToLogIn.confirmPassword =
            this.user.password === this.user.confirmPassword ? "" : "Passwords does not match";

        this.userError = { ...userAttemtingToLogIn };

        return Object.values(userAttemtingToLogIn).every((x) => x === "");
    };

    @action.bound searchForSpecificTimezone(value: string) {
        let tempTimezones: string[] = [];
        this.userSettings.timezone = value;
        if (value === "") {
            this.searchedTimezones = momentTz.tz.names();
        } else {
            momentTz.tz.names().forEach((timezone) => {
                if (timezone.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
                    tempTimezones.push(timezone);
                }
            });
            this.searchedTimezones = tempTimezones;
        }
    }

    @action.bound searchForSpecificTimeFormats(value: string) {
        let tempTimeFormats: TimeFormat[] = [];
        this.userSettings.timeFormat = value;
        if (value === "") {
            this.searchedTimezones = [TimeFormat.Military, TimeFormat.Standart];
        } else {
            [TimeFormat.Military, TimeFormat.Standart].forEach((timezone) => {
                if (timezone.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
                    tempTimeFormats.push(timezone);
                }
            });
            this.searchedTimeFormats = tempTimeFormats;
        }
    }

    @action.bound setUserTimeZone(value: string): void {
        this.userSettings.timezone = value;
        this.rootStore.uiStore.setDisplayTimezoneDropdown(false);
        this.updateUser();
    }

    @action.bound setUserTimeFormat(value: string): void {
        this.userSettings.timeFormat = value;
        this.rootStore.uiStore.setDisplayTimeFormatDropdown(false);
        this.updateUser();
    }

    @action.bound setUserName(value: string): void {
        this.userSettings.fullName = value;
    }

    @action.bound async setUserProfile(image: File[]) {
        this.isFetching = true;
        let imagesToUpload = {
            localUrl: window.URL.createObjectURL(image[0]),
            file: image[0],
            loading: true,
            url: "",
            name: image[0].name,
            mediaId: Guid.create(),
            type: "IMAGE",
        };

        let response = await uploadFiles([imagesToUpload]);
        runInAction(() => {
            this.userSettings.picture = response[0].transforms[0].location;
            this.isFetching = false;
        });
        this.updateUser();
    }

    @action.bound deleteProfilePicture(): void {
        this.userSettings.picture = "";
        this.updateUser();
    }

    @action.bound setEmail(value: string): void {
        this.userSettings.email = value;
    }

    @action.bound setDisplayName(value: string): void {
        this.userSettings.displayName = value;
    }

    @action.bound setCurrentPassword(value: string): void {
        this.currentPassword = value;
    }

    @action.bound setNewPassword(value: string): void {
        this.newPassword = value;
    }

    @action.bound setConfrimtPassword(value: string): void {
        this.confirmPassword = value;
    }

    @action.bound async updateUser() {
        try {
            await updateUserProfile(this.userSettings);
            runInAction(() => {
                this.user = Object.assign({}, this.userSettings);
                setLocalStorage("user", JSON.stringify(this.userSettings));
            });
        } catch (error) {
            console.error("Something went wrong!");
        }
    }

    @action.bound validateUserAccessSettings() {
        let userAttemtingToLogIn: IUser = {
            email: "",
            password: "",
            confirmPassword: "",
            currentPassword: "",
        };
        userAttemtingToLogIn.currentPassword =
            this.user.password === this.currentPassword ? "" : "Passwords does not match";
        userAttemtingToLogIn.email = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.userSettings.email)
            ? ""
            : "This is not a valid email.";
        userAttemtingToLogIn.password = /^[A-Za-z]\w{7,14}$/.test(this.newPassword)
            ? ""
            : "Password must have 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter";
        userAttemtingToLogIn.confirmPassword =
            this.newPassword === this.confirmPassword ? "" : "Passwords does not match";

        this.userError = { ...userAttemtingToLogIn };

        return Object.values(userAttemtingToLogIn).every((x) => x === "");
    }

    @action.bound editUserEmail() {
        let emailValidation = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.userSettings.email)
            ? ""
            : "This is not a valid email.";
        if (this.user.email !== this.userSettings.email && !emailValidation) {
            changeEmail(this.user._id as string, this.userSettings.email);
        }
    }

    @action.bound async editUserAccessSettings() {
        this.validateUserAccessSettings();
        if (this.user.password !== this.newPassword && !this.userError?.password && !this.userError?.confirmPassword) {
            const res = await updatePassword(this.user._id as string, this.user.password, this.newPassword);
            this.setUser({ ...this.user, password: res.data });
        }
    }

    @action.bound async logoutUser() {
        try {
            const res = await logout(this.user._id as string);
            if (res.status === "success") {
                deleteLocalStorage("user");
                deleteLocalStorage("token");
                deleteLocalStorage("workspace");
                this.isAuthenticated = null;
            }
        } catch (error) {
            console.error(error);
        }
    }

    @action async deleteUser() {
        this.isFetching = true;
        try {
            const res = await deleteUser(this.user._id as string);
            if (res.status === "success") {
                deleteLocalStorage("user");
                deleteLocalStorage("token");
            }
            this.isFetching = false;
        } catch (error) {
            console.error(error);
        }
    }
}
