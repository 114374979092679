import { IMessage } from "../stores/message/MessageStore";
import { messageAxiosInstance } from "../utils/axios.instance";

export const getMessages = async (conversationId: string, pageSize: number, skip: number, parentId: string | null) => {
    const response = await messageAxiosInstance({
        url: `${conversationId}/${pageSize}/${skip}/${parentId}`,
        method: "GET",
    });

    return response.data;
};

export const addNewMessage = async (message: any) => {
    const response = await messageAxiosInstance({
        url: "/",
        method: "POST",
        data: message,
    });
    return response.data;
};

export const markAsRead = async (messageIds: (string | undefined)[], conversationId: string | undefined) => {
    const response = await messageAxiosInstance({
        url: `/read`,
        method: "PUT",
        data: {
            ids: messageIds,
            conversationId: conversationId,
        },
    });
    return response;
};

export const deleteMessage = async (message: any, type?: string) => {
    try {
        const response = await messageAxiosInstance({
            url: `/${message._id}/${message.conversationId}/${type}`,
            method: "DELETE",
        });
        return response.data;
    } catch (error) {
        return error;
    }
};
