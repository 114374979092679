import React, { useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import theme from "../utils/uiCustomTheme";
import Tour from "reactour";
import { Box } from "@material-ui/core";

import Alert from "../components/common/Alert";
import PrivateRoute from "../routes/PrivateRoute";
import AccountSettings from "../components/account/settings/AccountSettings";
import Access from "../components/account/settings/Access";
import ConfirmNewEmail from "../components/account/settings/ConfirmNewEmail";
// import Chat from "../components/dashboard/chat/Chat";
import ConfirmEmail from "../components/account/login/ConfirmEmail";
import ForgotPassword from "../components/account/login/ForgotPassword";
import GoogleLogin from "../components/account/login/GoogleLogin";
import ResetPassword from "../components/account/login/ResetPassword";
import Campaigns from "../components/dashboard/campaigns/Campaigns";
import Channels from "../components/dashboard/channels/ChannelList";
import ContactProfile from "../components/dashboard/contacts/ContactProfile/Profile";
import LandingPage from "../components/landing-page/LandingPage";
import FacebookChannel from "../components/dashboard/channels/facebook/FacebookAccounts";
import TwitterChannel from "../components/dashboard/channels/twitter/TwitterAccount";
import ContactsList from "../components/dashboard/contacts/ContactList";
import Billing from "../components/account/settings/Billing";
import Notifications from "../components/account/settings/Notifications";
import Bonus from "../components/account/settings/Bonus";
import ActivateUser from "../components/landing-page/ActivateUser";
import asyncComponent from "../components/common/AsyncComponent";
import Privacy from "../components/terms-conditions/Privacy";
import Terms from "../components/terms-conditions/Terms";
import Chat from "../components/dashboard/chat/Chat";
import SignIn from "../components/account/login/SignIn";
import SignUp from "../components/account/login/SignUp";

import Workspace from "../components/dashboard/workspace/Workspace";
import AppTour from "../components/AppTour";
const App = (): JSX.Element => {
    return (
        <Box>
            <Alert />
            <BrowserRouter>
                <Switch>
                    <Route exact path={"/"} component={LandingPage} />
                    <Route exact path={"/activate-user"} component={ActivateUser} />
                    <Route exact path={"/sign-up"} component={SignUp} />
                    <Route exact path={"/sign-in"} component={SignIn} />
                    <Route exact path={"/forgot-password"} component={ForgotPassword} />
                    <Route exact path={"/change-password"} component={ResetPassword} />
                    <Route exact path={"/confirm-email"} component={ConfirmEmail} />
                    <Route exact path={"/confirm-new-email"} component={ConfirmNewEmail} />
                    <Route exact path={"/google"} component={GoogleLogin} />
                    <Route exact path={"/workspace"} component={Workspace} />
                    <Route exact path={"/facebook"} component={FacebookChannel} />
                    <Route exact path={"/twitter"} component={TwitterChannel} />
                    <Route exact path={"/privacy"} component={Privacy} />
                    <Route exact path={"/terms"} component={Terms} />
                    <PrivateRoute exact path={"/workspace/:workspaceId/channels"} component={Channels} />
                    <PrivateRoute exact path={"/workspace/:workspaceId/contacts/:id"} component={ContactProfile} />
                    <PrivateRoute exact path={"/workspace/:workspaceId/contacts"} component={ContactsList} />
                    <PrivateRoute exact path={"/campaigns"} component={Campaigns} />
                    <PrivateRoute
                        exact
                        path={"/workspace/:workspaceId/inbox/:conversationId?"}
                        component={Chat}
                    ></PrivateRoute>
                    <PrivateRoute exact path={"/settings"} component={AccountSettings}></PrivateRoute>
                    <PrivateRoute exact path={"/settings/account"} component={AccountSettings}></PrivateRoute>
                    <PrivateRoute exact path={"/settings/access"} component={Access}></PrivateRoute>
                    <PrivateRoute exact path={"/settings/notifications"} component={Notifications}></PrivateRoute>
                    <PrivateRoute exact path={"/settings/billing"} component={Billing}></PrivateRoute>
                    <PrivateRoute exact path={"/settings/bonus"} component={Bonus}></PrivateRoute>
                </Switch>
            </BrowserRouter>
            <AppTour />
        </Box>
    );
};

export default App;
