import { useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { setLocalStorage } from "../../../services/localStorageservice";
import { getUserByIdAndToken } from "../../../services/user.service";
import { useStores } from "../../../hooks";
import Auth from "../../../services/auth.service";
import { getWorkspacesByUser } from "../../../services/workspace.service";

const GoogleLogin = observer((props: any) => {
    const { userStore } = useStores();
    const query = new URLSearchParams(useLocation().search);

    const getWorkspaces = async () => {
        const result = await getWorkspacesByUser();
        const workspaces = result.data;
        if (Auth.isAuthenticated() && workspaces && workspaces.length > 0) {
            setLocalStorage("workspace", JSON.stringify(workspaces[0]));
            props.history.push(`workspace/${workspaces[0]._id}/inbox/`);
        }
    };

    const login = async () => {
        const token = query.get("token") as string;
        const id = query.get("id") as string;
        const result = await getUserByIdAndToken(id, token);
        if (result.status === "success") {
            setLocalStorage("token", token);
            setLocalStorage("user", JSON.stringify(result.data));
            userStore.setUser(result.data);
            getWorkspaces();
        } else {
            props.history.push("/sign-in");
        }
    };

    useEffect(() => {
        login();
    });

    return null;
});

export default withRouter(GoogleLogin);
