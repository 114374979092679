import React, { useState, useEffect } from "react";
import InputBase from "@material-ui/core/InputBase";
import { observer } from "mobx-react-lite";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { EditorState, convertToRaw, ContentState, Modifier, RichUtils, Editor } from "draft-js";

import { useStores } from "../../../../hooks";
import ContactCard from "./Card";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        background: "transparent",
        border: "none",
        outline: "none",
        color: theme.palette.secondary.main,
    },
}));

const Notes = observer((props: any) => {
    const classes = useStyles();
    const { contactStore, editorStore } = useStores();
    const { selectedContact } = contactStore;
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

    useEffect(() => {
        if (selectedContact?.["privateNotes"] !== getEditorStatValue()) {
            let editorStateWithValue: EditorState = EditorState.createWithContent(
                ContentState.createFromText(selectedContact?.["privateNotes"] || "")
            );
            onChange(editorStateWithValue);
        }
    }, [selectedContact]);

    const getEditorStatValue = () => {
        const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
        const value = blocks.map((block) => (!block.text.trim() && "\n") || block.text).join("\n");
        return value;
    };

    const onChange = (editorState: EditorState) => {
        setEditorState(editorState);
    };

    const saveData = () => {
        const value = getEditorStatValue();
        if (value !== selectedContact?.["privateNotes"]) {
            contactStore.updateSelectedContact("privateNotes", value);
            contactStore.saveUpdatedContact();
        }
    };

    return (
        <ContactCard title={"Private notes"} input={true}>
            <div
                onBlur={() => {
                    saveData();
                }}
            >
                <Editor editorState={editorState} onChange={onChange} placeholder="Write something!" />
            </div>
        </ContactCard>
    );
});

export default Notes;
