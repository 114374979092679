import React, { useEffect } from "react";
import { createStyles, makeStyles, withStyles, Theme, useTheme } from "@material-ui/core/styles";
import ContactFilters from "./ContactFilters";
import clsx from "clsx";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TableHead from "@material-ui/core/TableHead";
import { observer } from "mobx-react-lite";
import { CircularProgress } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { badgeProvider } from "../../../utils/helperFunctions";
import { useStores } from "../../../hooks";
import { warning } from "../../../services/toast.service";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "transparent",
        color: "#737373",
        border: "none",
    },
    body: {
        border: "none",
        cursor: "pointer",
    },
}))(TableCell);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            tableLayout: "fixed",
        },
        tableCell: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        whiteTableRow: {
            backgroundColor: "white",
        },
        tableRowText: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        actionButtons: {
            borderRadius: "32px",
            border: "1px solid #1085F0",
            fontSize: "12px",
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        contactsRoot: {
            [theme.breakpoints.up("md")]: {
                marginRight: theme.spacing(4),
            },
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
        },
        search: {
            padding: theme.spacing(2),
        },
        loading: {
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            margin: theme.spacing(1),
        },
        tableHead: {
            backgroundColor: "transparent",
        },
        actionWrapper: {
            display: "flex",
        },
        tableBody: {
            [theme.breakpoints.down("sm")]: {
                paddingBottom: "58px",
            },
            "&::-webkit-scrollbar": {
                width: "12px",
                height: "10px",
                marginRight: "5px",
            },
            "&::-webkit-scrollbar-track": {
                background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
                background: "#A0A0A0",
                borderRadius: "7px",
                backgroundClip: "padding-box",
                border: "3px solid transparent",
                minHeight: "50px",
                "&:hover": {
                    background: "#A0A0A0",
                    backgroundClip: "padding-box",
                },
            },
        },
        loaderWrapper: {
            position: "absolute",
            left: "50%",
        },
    })
);

const ContactList = observer((props: any) => {
    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.up("sm"));
    const { contactStore } = useStores();

    useEffect(() => {
        contactStore.clearSelectedContact();
    });

    const viewContactProfile = (contactId: string) => {
        props.history.push(`contacts/${contactId}`);
    };

    const sendMessage = (contact: any) => {
        if (contact.conversationId) {
            props.history.push(`/workspace/${contact.workspaceId}/inbox/${contact.conversationId}`);
        } else {
            warning("There is no conversation with this contact!");
        }
    };

    useEffect(() => {
        contactStore.getContacts();

        return () => contactStore.reset();
    }, []);

    return (
        <Box height="100%" className={classes.contactsRoot}>
            <ContactFilters />

            <TableContainer id="scrollableTable" className={classes.tableBody}>
                <InfiniteScroll
                    dataLength={contactStore.contacts.length}
                    next={() => contactStore.getContacts()}
                    hasMore={contactStore.contacts.length < contactStore.total}
                    loader={<CircularProgress className={classes.loading} />}
                    scrollableTarget="scrollableTable"
                >
                    <Table stickyHeader className={classes.table} aria-label="sticky table">
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <StyledTableCell padding="checkbox"></StyledTableCell>
                                <StyledTableCell align="left">{"Name"}</StyledTableCell>
                                {mobile && (
                                    <React.Fragment>
                                        <StyledTableCell>{"Channel"}</StyledTableCell>
                                        <StyledTableCell>{"Email"}</StyledTableCell>
                                        <StyledTableCell>{"Gender"}</StyledTableCell>
                                    </React.Fragment>
                                )}
                                <StyledTableCell>Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {contactStore.isFetching && contactStore.contacts.length < 1 ? (
                            <span className={classes.loaderWrapper}>
                                <CircularProgress />
                            </span>
                        ) : (
                            <TableBody>
                                {contactStore.contacts.map((contact, index) => (
                                    <TableRow
                                        key={index}
                                        className={clsx({ [classes.whiteTableRow]: index % 2 === 0 })}
                                        onClick={() => viewContactProfile(contact._id)}
                                    >
                                        <StyledTableCell className={classes.tableCell}>
                                            <Avatar src={contact.picture} />
                                        </StyledTableCell>
                                        <StyledTableCell className={clsx(classes.tableCell, classes.tableRowText)}>
                                            {contact.fullName}
                                        </StyledTableCell>
                                        {mobile && (
                                            <React.Fragment>
                                                <StyledTableCell
                                                    className={clsx(classes.tableCell, classes.tableRowText)}
                                                >
                                                    <Box display="flex" alignItems="center">
                                                        <Box mr="5px">{badgeProvider(contact.provider)}</Box>
                                                        {contact.provider}
                                                    </Box>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className={clsx(classes.tableCell, classes.tableRowText)}
                                                >
                                                    {contact.email}
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className={clsx(classes.tableCell, classes.tableRowText)}
                                                >
                                                    {contact.gender}
                                                </StyledTableCell>
                                            </React.Fragment>
                                        )}
                                        <StyledTableCell
                                            className={clsx(
                                                classes.tableCell,
                                                classes.tableRowText,
                                                classes.actionWrapper
                                            )}
                                        >
                                            <Button
                                                variant="outlined"
                                                className={classes.actionButtons}
                                                color="primary"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    sendMessage(contact);
                                                }}
                                            >
                                                Send message
                                            </Button>
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </InfiniteScroll>
            </TableContainer>
        </Box>
    );
});

export default withRouter(ContactList);
