import { observer } from "mobx-react-lite";
import React from "react";
import { withRouter } from "react-router";
import { useStores } from "../../../../hooks";
import { warning } from "../../../../services/toast.service";
import ConversationListItem from "../../chat/conversations/ConversationListItem";
import ContactCard from "./Card";

const Conversations = observer((props: any) => {
    const { contactStore, conversationStore } = useStores();
    const { selectedContact } = contactStore;

    const goToConversation = (conversation: any) => {
        let conversationId = conversation._id;

        if (conversationId) {
            props.history.push(`/workspace/${selectedContact?.workspaceId}/inbox/${conversationId}`);
        } else {
            warning("There is no conversation with this contact!");
        }
    };

    return (
        <ContactCard title={"Conversations"}>
            {selectedContact ? (
                selectedContact.conversations.map((conversation) => {
                    conversationStore.calculateLastMessageTime(conversation.lastMessageTime, conversation._id);
                    return (
                        <div onClick={() => goToConversation(conversation)}>
                            <ConversationListItem conversation={conversation} history={props.history} />
                        </div>
                    );
                })
            ) : (
                <div></div>
            )}
        </ContactCard>
    );
});

export default withRouter(Conversations);
