import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import { useStores } from "../../hooks";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useSound from "use-sound";
import { io } from "socket.io-client";

import Box from "@material-ui/core/Box";
import NavBar from "../common/NavBar";
import Sidebar from "../common/Sidebar";
import { sounds } from "../../utils/contants";
import { IMessage } from "../../stores/message/MessageStore";
import { IConversation } from "../../stores/ConversationStore";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100vh",
        },
        mainLayout: {
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column-reverse",
                paddingBottom: 0,
                overflow: "hidden",
            },
            [theme.breakpoints.up("md")]: {
                height: "calc(100vh - 64px)",
                paddingBottom: theme.spacing(2.5),
            },
            height: "100%",
        },
        navBarWrapper: {
            marginBottom: theme.spacing(3),
            [theme.breakpoints.down("sm")]: {
                marginBottom: theme.spacing(0),
            },
        },
        displayNone: {
            display: "none",
        },
        pageHeight: {
            height: "100vh",
        },
    })
);

const MainLayout = observer((props: any) => {
    const classes = useStyles();
    const theme = useTheme();
    const { conversationStore, messageStore, uiStore } = useStores();
    const normalScreen = useMediaQuery(theme.breakpoints.up("md"));
    const [playSound] = useSound(sounds.notification);
    let pageTitle = "Kibo - Unified Inbox";
    useEffect(() => {
        window.addEventListener("focus", onFocus);
        return () => {
            window.removeEventListener("focus", onFocus);
        };
    }, []);

    // User has switched back to the tab
    const onFocus = () => {
        uiStore.setIsTabActive(true);
        document.title = pageTitle;
    };

    // User has switched away from the tab (AKA tab is hidden)
    const onBlur = () => {
        uiStore.setIsTabActive(false);
    };

    useEffect(() => {
        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);
        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        };
    });

    const handlePlaySound = () => {
        if (uiStore.isTabActive) return;
        playSound();
        countUnreadMessages();
    };

    useEffect(() => {
        countUnreadMessages();
    }, [conversationStore.conversations]);

    const countUnreadMessages = () => {
        if (uiStore.isTabActive) return;
        let totalUnread = 0;
        conversationStore.conversations.forEach((el) => {
            totalUnread = totalUnread + el.unreadMessages;
            console.log(el.unreadMessages);
        });
        if (uiStore.isTabActive || totalUnread === 0) return (document.title = pageTitle);
        document.title = "(" + "" + totalUnread + ")" + " " + pageTitle;
    };

    const handleMessageReceived = (data) => {
        handlePlaySound();
        if (
            !conversationStore.conversations.find(
                (conversation: IConversation) => conversation._id == data.conversation._id
            )
        )
            return conversationStore.addConversation(data.conversation);

        if (conversationStore.selectedConversation?._id == data.conversation._id) {
            messageStore.addMessage(data.message);
            conversationStore.markAsReadConversation(data.conversation);
        } else {
            conversationStore.updateConversation(data.conversation);
        }
    };

    useEffect(() => {
        const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT as string, { transports: ["websocket"] });
        socket.on("MESSAGE", (data: { message: IMessage; conversation: IConversation }) => {
            if (data.message.received) return handleMessageReceived(data);
        });

        socket.on("INITIAL_MESSAGE", (data: { message: IMessage }) => {
            if (!data.message.received) {
                messageStore.addMessage(data.message);
            }
        });
        socket.on("TYPING", (data: any) => {
            if (data.fromChat && data.conversationId === conversationStore.selectedConversation?._id) {
                conversationStore.setIsTyping(data);
                setTimeout(() => {
                    conversationStore.setIsTyping({ ...data, typing: false });
                }, 15000);
            }
        });
        socket.on("DELETED_MESSAGE", (data: string) => {
            messageStore.removeDeletedMessage(data);
        });
        return () => {
            socket.disconnect();
        };
    }, [uiStore.isTabActive]);

    return (
        <Box display="flex" className={classes.root} flexDirection="column" bgcolor="background">
            <Box
                className={clsx({
                    [classes.displayNone]: uiStore.displayNavBar && !normalScreen,
                })}
            >
                <NavBar />
            </Box>
            <Box
                display="flex"
                className={clsx([classes.mainLayout], {
                    [classes.pageHeight]: uiStore.displayNavBar && !normalScreen,
                })}
            >
                <Sidebar />
                {props.children}
            </Box>
        </Box>
    );
});

export default MainLayout;
