import { url } from "inspector";
import { IImages, IMessage } from "../stores/message/MessageStore";
import { facebookAxiosInstance } from "../utils/axios.instance";

export const getPages = async (id: string, accessToken: string, isEdited: string, socialId: string | undefined) => {
    const response = await facebookAxiosInstance({
        url: `/pages/${id}/${accessToken}/${isEdited}/${socialId}`,
        method: "GET",
    });

    return response.data;
};

export const addMessage = async (
    receiptId: string,
    senderId: string,
    text: string,
    files: Array<IImages>,
    replyMessage: undefined | null | IMessage
) => {
    const response = await facebookAxiosInstance({
        url: "/message",
        method: "POST",
        data: {
            receiptId: receiptId,
            senderId: senderId,
            message: text,
            files: files,
            replyMessage: replyMessage,
        },
    });

    return response.data;
};
