import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { observer } from "mobx-react-lite";

import Modal from "../../../common/Modal";
import { addAccount } from "../../../../services/account.service";
import AccountProvider from "../AccountProvider";
import { useStores } from "../../../../hooks";
import { error } from "../../../../services/toast.service";
import ForwardEmailDialog from "./ForwardEmailDialog";

const useStyles = makeStyles((theme) => ({
    backgroundColor: {
        background: "#FFF",
    },
    container: {
        minWidth: "320px",
        display: "grid",
        gap: "24px",
        [theme.breakpoints.down("sm")]: {
            minWidth: "250px",
        },
    },
    title: {
        color: "#6D6D6D",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2.5),
    },
    displayColumn: {
        display: "flex",
        flexDirection: "column",
    },
    input: {
        "& input": {
            background: theme.palette.background.default,
            border: "1px solid #fff",
            fontFamily: "SOURCE SANS PRO",
            color: "#3D3D3D",
            width: "100%",
            paddingLeft: theme.spacing(1),
        },
        "&: hover": {
            border: "none",
        },
        "& fieldset": {
            border: "none",
        },
    },
    placeholder: {
        fontSize: 14,
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(1),
    },
}));

const Email = observer((props: { open: boolean; setOpen: Function; addNewAccount: Function }) => {
    const classes = useStyles();
    const { workspaceStore } = useStores();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [emailId, setEmailId] = useState("");
    const [openEmailDialog, setOpenEmailDialog] = useState(false);
    const createEmail = async () => {
        try {
            if (name && email) {
                let workspace = workspaceStore.workspaces[0];
                const result = await addAccount({
                    provider: "email",
                    name: name,
                    email: email,
                    workspaceId: workspace?._id,
                });
                if (result.status === "success") {
                    props.addNewAccount(result.data);
                    setEmailId(result.data.forwardEmail);
                    setOpenEmailDialog(true);
                }
            }
        } catch (err) {
            error("There was an error adding the accounts");
        }
    };
    return (
        <>
            <Modal
                open={props.open}
                onClose={() => props.setOpen(false)}
                handleConfirm={() => createEmail()}
                title={"Email"}
                disableBackdropClick={true}
            >
                <AccountProvider provider="email" />
                <div className={classes.title}>Enter the credentials to add your Email account</div>

                <div className={classes.container}>
                    <div className={classes.displayColumn}>
                        <span className={classes.placeholder}>Name*</span>
                        <InputBase
                            id="outlined-basic"
                            className={classes.input}
                            required={true}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </div>
                    <div className={classes.displayColumn}>
                        <span className={classes.placeholder}>Email*</span>
                        <InputBase
                            id="outlined-basic"
                            className={classes.input}
                            required={true}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </div>
                </div>
            </Modal>
            <ForwardEmailDialog open={openEmailDialog} setOpen={setOpenEmailDialog} forwardEmail={emailId} />
        </>
    );
});

export default Email;
