import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { Button } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import TwitterIcon from "@material-ui/icons/Twitter";

import SettingsNavigation from "./SettingsNavigation";
import { icons, logos } from "../../../utils/contants";

const ArticleIcon = <img src={icons.article} alt="Article" />;
const CapterraIcon = <img src={logos.capterra} alt="Capterra" />;
const CrozdeskIcon = <img src={logos.crozdesk} alt="Crozdesk" />;
const FacebookIcon = <img src={logos.facebook} alt="Facebook" />;
const GoogleIcon = <img src={logos.googleBig} alt="Google" />;
const LinkedinIcon = <img src={logos.linkedin} alt="LinkedIn" />;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            "justify-content": "flex-start",
        },
        wrapper: {
            width: "min-content",
        },
        row: {
            display: "grid",
            "grid-template-columns": "1fr",
            gridGap: "1em",
        },
        discountContainer: {
            display: "grid",
            "grid-template-columns": "1fr 1fr",
            gridGap: "1em",
        },
        card: {
            borderRadius: "0px 0px 20px 20px",
            padding: theme.spacing(2),
            backgroundColor: "white",
        },
        label: {
            color: "#737373",
            marginLeft: theme.spacing(1),
        },
        defaultIcon: {
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            backgroundColor: "#c4c4c4",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        backgroundPrimary: {
            backgroundColor: theme.palette.primary.main,
        },
        backgroundTwitter: {
            backgroundColor: "#55ACEE",
        },
        buttonDefault: {
            width: "100%",
            border: "1.5px solid",
            borderColor: "#3D3133",
            color: "#3D3133",
            borderRadius: "32px",
            fontSize: 14,
            fontWeight: 700,
            "&:hover": {
                border: "1.5px solid",
            },
        },
        alignHorizontally: {
            display: "flex",
            alignItems: "center",
        },
        white: {
            color: "white",
        },
        fontBold: {
            fontWeight: 700,
        },
    })
);

const Bouns = (): JSX.Element => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <SettingsNavigation />
                <div className={clsx(classes.row, classes.card)}>
                    <span>Discounts</span>
                    <div className={classes.discountContainer}>
                        <div className={classes.alignHorizontally}>
                            {ArticleIcon}
                            <span className={classes.label}>Publish an article about Mitto on your site</span>
                        </div>
                        <Button variant="outlined" classes={{ root: classes.buttonDefault }}>
                            Earn 20% of all future invoices
                        </Button>
                    </div>
                    <div className={classes.discountContainer}>
                        <div className={classes.alignHorizontally}>
                            <div className={clsx(classes.defaultIcon, classes.backgroundPrimary)}>
                                <StarIcon className={classes.white} />
                            </div>
                            <span className={classes.label}>Give feedback (used internally)</span>
                        </div>
                        <Button variant="outlined" classes={{ root: classes.buttonDefault }}>
                            Earn 20% of all future invoices
                        </Button>
                    </div>
                    <div className={classes.discountContainer}>
                        <div className={classes.alignHorizontally}>
                            {CapterraIcon}
                            <span className={classes.label}>
                                Leace a review for Mitto on <span className={classes.fontBold}>Capterra</span>
                            </span>
                        </div>
                        <Button variant="outlined" classes={{ root: classes.buttonDefault }}>
                            Earn 20% of all future invoices
                        </Button>
                    </div>
                    <div className={classes.discountContainer}>
                        <div className={classes.alignHorizontally}>
                            <div className={classes.defaultIcon} />
                            <span className={classes.label}>
                                Leave a review for Mitton on <span className={classes.fontBold}>G2 Crowd</span>
                            </span>
                        </div>
                        <Button variant="outlined" classes={{ root: classes.buttonDefault }}>
                            Earn 20% of all future invoices
                        </Button>
                    </div>
                    <div className={classes.discountContainer}>
                        <div className={classes.alignHorizontally}>
                            {CrozdeskIcon}
                            <span className={classes.label}>
                                Leave a review for Mitton on <span className={classes.fontBold}>Crozdesk</span>
                            </span>
                        </div>
                        <Button variant="outlined" classes={{ root: classes.buttonDefault }}>
                            Earn 20% of all future invoices
                        </Button>
                    </div>
                    <div className={classes.discountContainer}>
                        <div className={classes.alignHorizontally}>
                            <div className={classes.defaultIcon} />
                            <span className={classes.label}>
                                Leave a review for Mitton on <span className={classes.fontBold}>Finances Online</span>
                            </span>
                        </div>
                        <Button variant="outlined" classes={{ root: classes.buttonDefault }}>
                            Earn 20% of all future invoices
                        </Button>
                    </div>
                    <div className={classes.discountContainer}>
                        <div className={classes.alignHorizontally}>
                            {LinkedinIcon}
                            <span className={classes.label}>
                                Leave a review for Mitton on <span className={classes.fontBold}>Linkedin</span>
                            </span>
                        </div>
                        <Button variant="outlined" classes={{ root: classes.buttonDefault }}>
                            Earn 20% of all future invoices
                        </Button>
                    </div>
                    <div className={classes.discountContainer}>
                        <div className={classes.alignHorizontally}>
                            {FacebookIcon}
                            <span className={classes.label}>
                                Leave a review for Mitton on <span className={classes.fontBold}>Facebook</span>
                            </span>
                        </div>
                        <Button variant="outlined" classes={{ root: classes.buttonDefault }}>
                            Earn 20% of all future invoices
                        </Button>
                    </div>
                    <div className={classes.discountContainer}>
                        <div className={classes.alignHorizontally}>
                            <div className={clsx(classes.defaultIcon, classes.backgroundTwitter)}>
                                <TwitterIcon className={classes.white} />
                            </div>
                            <span className={classes.label}>
                                Leave a review for Mitton on <span className={classes.fontBold}>Twitter</span>
                            </span>
                        </div>
                        <Button variant="outlined" classes={{ root: classes.buttonDefault }}>
                            Earn 20% of all future invoices
                        </Button>
                    </div>
                    <div className={classes.discountContainer}>
                        <div className={classes.alignHorizontally}>
                            {GoogleIcon}
                            <span className={classes.label}>
                                Leave a review for Mitton on <span className={classes.fontBold}>Google</span>
                            </span>
                        </div>
                        <Button variant="outlined" classes={{ root: classes.buttonDefault }}>
                            Earn 20% of all future invoices
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bouns;
