import { useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { activateTemporaryUser } from "../../services/user.service";

const ActivateUser = (props: any) => {
    const query = new URLSearchParams(useLocation().search);

    const activateUser = async () => {
        const email = query.get("email") as string;

        try {
            await activateTemporaryUser(email);
            props.history.push({
                pathname: "/",
                state: "success",
            });
        } catch {
            props.history.push({
                pathname: "/",
                state: "error",
            });
        }
    };

    useEffect(() => {
        activateUser();
    });

    return null;
};

export default withRouter(ActivateUser);
