import React from "react";

import { Route, Redirect } from "react-router-dom";
import MainLayout from "../components/layouts/MainLayout";
import Auth from "../services/auth.service";
import { hasWorkspace } from "../services/workspace.service";

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (Auth.isAuthenticated()) {
                    if (hasWorkspace()) {
                        return (
                            <MainLayout>
                                <Component {...props} />
                            </MainLayout>
                        );
                    } else {
                        return <Redirect to={{ pathname: "/workspace" }} />;
                    }
                } else {
                    return <Redirect to={{ pathname: "/sign-in" }} />;
                }
            }}
        />
    );
};

export default PrivateRoute;
