import React, { useRef } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { observer } from "mobx-react-lite";
import { withRouter } from "react-router";
import InputBase from "@material-ui/core/InputBase";
import clsx from "clsx";
import WatchLaterIcon from "@material-ui/icons/WatchLater";

import Dropdown, { IDropdownData } from "../../common/Dropdown";
import { useStores } from "../../../hooks";
import ProfilePicture from "../../common/ProfilePicture";
import SettingsNavigation from "./SettingsNavigation";
import ConfirmDialog from "../../common/ConfirmDialog";
import { useCallbackOutsideElement } from "../../../utils/helperFunctions";
import { icons } from "../../../utils/contants";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            "justify-content": "flex-start",
        },
        wrapper: {
            //width: "min-content",
            width: "100%",
            maxWidth: "700px",
        },
        account: {
            display: "grid",
            "grid-template-rows": "1fr 1fr",
            gridGap: "2em",
        },
        card: {
            borderRadius: "0px 0px 20px 20px",
            padding: theme.spacing(2),
            backgroundColor: "white",
        },
        deleteAccountWrapper: {
            display: "flex",
            flexDirection: "column",
            borderRadius: "20px",
            padding: theme.spacing(2),
            backgroundColor: "white",
            marginTop: theme.spacing(2),
        },
        profile: {
            display: "flex",
            flexDirection: "column",
        },
        row: {
            display: "grid",
            "grid-template-columns": "1fr",
            gridGap: "1em",
        },
        section: {
            display: "grid",
            "grid-template-columns": "1fr",
            gridGap: "2em",
        },
        col: {
            display: "grid",
            "grid-template-columns": "1fr 1fr",
            gridGap: "1em",
            marginTop: theme.spacing(0.5),
        },
        input: {
            background: theme.palette.background.default,
            borderRadius: 4,
            border: "1px solid #fff",
            paddingLeft: theme.spacing(1),
            color: "#3D3D3D",
            width: "100%",
            height: 40,
            marginTop: theme.spacing(0.5),
        },
        placeholder: {
            fontSize: 13,
            color: theme.palette.primary.main,
        },
        timezoneText: {
            display: "grid",
            alignContent: "center",
            color: "#737373",
        },
        noGap: {
            gridGap: "0em !important",
        },
        headerText: {
            color: theme.palette.secondary.main,
        },
        bodyext: {
            fontSize: 13,
            color: theme.palette.secondary.main,
        },
        button: {
            border: "1.5px solid",
            borderColor: theme.palette.error.main,
            borderRadius: 24,
            color: theme.palette.error.main,
            fontSize: 13,
            "&:hover": {
                backgroundColor: "rgba(255, 87, 107, 0.04)",
            },
        },
        pictureCenter: {
            justifyItems: "center",
        },
    })
);

const AccountSettings = observer((props: { history: any }) => {
    const classes = useStyles();
    const { uiStore, userStore } = useStores();
    const timezoneRef = useRef<HTMLHeadingElement>(null);
    const timeFormatRef = useRef<HTMLHeadingElement>(null);
    const timezoneData: IDropdownData[] = userStore.searchedTimezones.map((element) => ({
        value: element,
        handleClick: userStore.setUserTimeZone,
    }));
    const timeFormatData: IDropdownData[] = userStore.searchedTimeFormats.map((element) => ({
        value: element,
        handleClick: userStore.setUserTimeFormat,
    }));

    useCallbackOutsideElement(timezoneRef, () => uiStore.setDisplayTimezoneDropdown(false));
    useCallbackOutsideElement(timeFormatRef, () => uiStore.setDisplayTimeFormatDropdown(false));
    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <SettingsNavigation />
                <div className={clsx(classes.section, classes.card)}>
                    <div className={classes.col}>
                        <div className={classes.row}>
                            <span className={classes.headerText}>Profile</span>
                            <div>
                                <span className={classes.placeholder}>Full Name</span>
                                <InputBase
                                    id="outlined-basic"
                                    value={userStore.userSettings.fullName as string}
                                    className={classes.input}
                                    onChange={(e) => userStore.setUserName(e.target.value)}
                                    onBlur={() => {
                                        userStore.updateUser();
                                    }}
                                />
                            </div>
                            <div>
                                <span className={classes.placeholder}>Display Name</span>
                                <InputBase
                                    id="outlined-basic"
                                    value={userStore.userSettings.displayName as string}
                                    className={classes.input}
                                    onBlur={() => {
                                        userStore.updateUser();
                                    }}
                                    onChange={(e) => userStore.setDisplayName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={clsx(classes.row, classes.pictureCenter)}>
                            <ProfilePicture
                                removeCurrentPicture={() => userStore.deleteProfilePicture()}
                                isFetching={userStore.isFetching}
                                uploadImage={userStore.setUserProfile}
                                image={userStore.userSettings.picture}
                            />
                        </div>
                    </div>

                    <div className={classes.row}>
                        <span>Time Settings</span>
                        <div className={clsx(classes.row)}>
                            <div>
                                <span className={classes.placeholder}>Timezone</span>
                                <div className={classes.col} ref={timezoneRef}>
                                    <Dropdown
                                        type="string"
                                        hasInputField={true}
                                        value={userStore.userSettings.timezone as string}
                                        onChange={userStore.searchForSpecificTimezone}
                                        dropdownData={timezoneData}
                                        isDropdownOpen={uiStore.displayTimezoneDropdown}
                                        toggleDropdown={uiStore.setDisplayTimezoneDropdown}
                                        startAdornmentIcon={<img src={icons.timezone} />}
                                    />
                                    <span className={classes.timezoneText}>Select your timezone</span>
                                </div>
                            </div>
                            <div>
                                <span className={classes.placeholder}>Time format</span>
                                <div className={classes.col} ref={timeFormatRef}>
                                    <Dropdown
                                        type="string"
                                        hasInputField={true}
                                        value={userStore.userSettings.timeFormat as string}
                                        onChange={userStore.searchForSpecificTimeFormats}
                                        dropdownData={timeFormatData}
                                        isDropdownOpen={uiStore.displayTimeFormatDropdown}
                                        toggleDropdown={uiStore.setDisplayTimeFormatDropdown}
                                        startAdornmentIcon={
                                            <WatchLaterIcon
                                                style={{ color: "#737373", width: "20px", height: "20px" }}
                                            />
                                        }
                                    />
                                    <span className={classes.timezoneText}>Your preferred time format</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={clsx(classes.deleteAccountWrapper, classes.row)}>
                    <span className={classes.headerText}>Delete Account</span>
                    <span className={classes.bodyext}>
                        Delete your account and all your data from Mitto (workspaces, conversations, settings, analytics
                        etc). If you have an active paid subscription with us, it will be automatically cancelled.
                    </span>
                    <div>
                        <Button
                            variant="outlined"
                            className={classes.button}
                            onClick={() => uiStore.setDisplayConfimDialog(true)}
                        >
                            Delete Kibo
                        </Button>
                    </div>
                </div>
                <ConfirmDialog
                    title={"Delete Account ?"}
                    body={
                        "Because we value your privacy, all your data will be permanently deleted right away.This cannot be undone. Are you sure?"
                    }
                    isFetching={userStore.isFetching}
                    handleOnConfrim={async () => {
                        await userStore.deleteUser();
                        props.history.push("/sign-in");
                    }}
                />
            </div>
        </div>
    );
});

export default withRouter(AccountSettings);
