import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";

import { observer } from "mobx-react-lite";
import { IChannel } from "../../../stores/ChannelStore";
import { useStores } from "../../../hooks";
import clsx from "clsx";
import AccountProvider from "./AccountProvider";
import { icons } from "../../../utils/contants";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexDirection: "column",
            backgroundColor: theme.palette.background.paper,
            borderRadius: "5px",
            height: "200px",
            padding: theme.spacing(2),
            flex: "auto",
            minWidth: "150px",
            margin: "8px",
            justifyContent: "space-between",
        },
        spaceBetween: {
            display: "flex",
            justifyContent: "space-between",
        },
        deleteBtn: {
            background: "#F2F9FF",
            borderRadius: "30px",
            padding: "5px",
            cursor: "pointer",
        },
        reauthBtn: {
            border: "1px solid #000000",
            boxSizing: "border-box",
            borderRadius: "30px",
            fontWeight: 700,
            textTransform: "unset",
            width: "100%",
        },
        alignCenter: {
            alignItems: "baseline",
            justifyContent: "center",
        },
        displayFlex: {
            display: "flex",
        },
        avatar: {
            width: "55px",
            height: "55px",
        },
        nameWrapper: {
            flexDirection: "column",
            marginLeft: theme.spacing(2),
            minWidth: "0px",
        },
        ellipsis: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            width: "100%",
        },
        name: {
            fontWeight: 700,
            margin: "2px 0 2px 0",
        },
    })
);

const AccountCard = observer((props: { account: IChannel; openDialog: any; reauthenticate: any }) => {
    const classes = useStyles();
    const { account, openDialog, reauthenticate } = props;
    const { channelStore } = useStores();

    return (
        <div className={clsx(classes.root, classes.displayFlex)}>
            <div className={clsx(classes.spaceBetween, classes.displayFlex)}>
                <div>
                    <AccountProvider provider={account.provider} />
                </div>
                <div
                    className={clsx(classes.deleteBtn, classes.displayFlex)}
                    onClick={() => {
                        channelStore.setSelectedAccount(account);
                        openDialog(true);
                    }}
                >
                    <img src={icons.delete} />
                </div>
            </div>
            <div className={clsx(classes.displayFlex)}>
                <Avatar className={classes.avatar} src={account.picture} />
                <div className={clsx(classes.nameWrapper, classes.alignCenter, classes.displayFlex)}>
                    <div className={clsx(classes.name, classes.ellipsis)}>{account.name}</div>
                    {account.provider !== "facebook" && account.provider !== "twitter" && (
                        <div className={classes.ellipsis}>
                            {account.provider === "email" ? account.email : account.name}
                        </div>
                    )}
                </div>
            </div>
            <div className={clsx(classes.alignCenter, classes.displayFlex)}>
                <Button className={classes.reauthBtn} variant="outlined" onClick={() => reauthenticate(account)}>
                    {account.provider === "email"
                        ? "Forward Email"
                        : account.provider === "chat"
                        ? "View Chat Id"
                        : "Reauthenticate"}
                </Button>
            </div>
        </div>
    );
});

export default AccountCard;
