import React from "react";
import clsx from "clsx";
import {
    createStyles,
    makeStyles,
    Grid,
    Divider,
    Theme,
    Typography,
    ListItemText,
    IconButton,
    Box,
    ListItemAvatar,
    Chip,
    Badge,
    Avatar,
} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import { observer } from "mobx-react-lite";

import { useStores } from "../../hooks";
import { badgeProvider } from "../../utils/helperFunctions";
import { withRouter } from "react-router";
import { AnyARecord } from "dns";
import { IWorkspace } from "../../stores/WorkspaceStore";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            height: "100%",
            width: "100%",
        },
        margin: {
            margin: theme.spacing(1),
        },
        backgroundColor: {
            backgroundColor: "white",
            borderTopRightRadius: "20px",
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        hide: {
            display: "none",
        },
        drawer: {
            width: "100%",
            flexShrink: 0,
            whiteSpace: "nowrap",
            borderTopRightRadius: "20px",
        },
        drawerOpen: {
            minWidth: "230px",
            borderLeft: "none",
            borderTopRightRadius: "20px",
            position: "inherit",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            display: "none",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: "hidden",
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up("md")]: {
                width: theme.spacing(9) + 1,
            },
        },
        divider: {
            height: "5px",
            background: theme.palette.background.default,
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        marginBottom: {
            marginBottom: theme.spacing(2),
        },
        assignedOperator: {
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
        },
        mainInformationChip: {
            borderRadius: "50px",
            backgroundColor: theme.palette.background.default,
            margin: theme.spacing(0.5),
            padding: "4px 8px",
            maxWidth: "250px",
        },
        drawerBody: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            height: "100%",
        },
        mainInformationText: {
            [theme.breakpoints.down("md")]: {
                display: "flex",
                alignItems: "flexEnd",
                justifyContent: "flex-end",
            },
        },

        name: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            paddingRight: "10px",
            [theme.breakpoints.down("xs")]: {
                maxWidth: "150px !important",
            },
            [theme.breakpoints.down("md")]: {
                maxWidth: "100px",
            },
            [theme.breakpoints.up("md")]: {
                maxWidth: "200px",
            },
        },
        notesContainer: {
            maxWidth: "250px",
        },
        wrapper: {
            height: "90%",
            display: "flex",
            alignItems: "center",
        },
    })
);

const TemporaryDrawer = observer((props: any) => {
    const classes = useStyles();
    const { conversationStore, workspaceStore } = useStores();
    const { selectedConversation } = conversationStore;
    const { open, toggleUserProfile } = props;

    const workspace: IWorkspace = workspaceStore.workspaces[0];

    const viewProfile = () => {
        props.history.push(`/workspace/${workspace?._id}/contacts/${selectedConversation?.contactUid}`);
    };

    if (!selectedConversation) {
        return null;
    }
    return (
        <div className={classes.root}>
            <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                className={clsx({ [classes.hide]: open }, [classes.backgroundColor])}
            >
                <Box>
                    <IconButton className={classes.margin} size="small" onClick={() => toggleUserProfile()}>
                        <InfoOutlinedIcon color={"secondary"} />
                    </IconButton>
                </Box>
            </Box>
            <Drawer
                anchor={"right"}
                open={open}
                onClose={() => toggleUserProfile()}
                variant="persistent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <Box className={classes.drawerBody}>
                    <Box display="flex" justifyContent="flex-end">
                        <IconButton className={classes.margin} size="small" onClick={() => toggleUserProfile()}>
                            <HighlightOffRoundedIcon color={"secondary"} />
                        </IconButton>
                    </Box>
                    <div className={classes.wrapper}>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <ListItemAvatar>
                                <Badge
                                    overlap="circle"
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    badgeContent={badgeProvider(selectedConversation.provider)}
                                >
                                    <Avatar src={selectedConversation.picture} />
                                </Badge>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography component={"span"} variant={"body2"}>
                                        <Box fontWeight="fontWeightBold" className={classes.name}>
                                            {selectedConversation.contactName}
                                        </Box>
                                    </Typography>
                                }
                            />
                            <Chip
                                variant="outlined"
                                color="primary"
                                size="medium"
                                clickable
                                label={
                                    <Box fontWeight={600} onClick={() => viewProfile()}>
                                        See Full Profile
                                    </Box>
                                }
                            />
                        </Grid>
                    </div>
                    {/*
                    <Divider className={classes.divider} />
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Box fontWeight={600} className={classes.marginBottom}>
                            Assigned Operator
                        </Box>
                        <Grid container direction="row" alignItems="center">
                             <Chip
                                avatar={ <Avatar src={selectedConversation.assignedOperatorPicture} />}
                                label={selectedConversation.assignedOperatorName}
                                clickable
                                onDelete={() => {}}
                                deleteIcon={<ExpandMoreIcon />}
                                size="medium"
                                className={classes.assignedOperator}
                            /> 
                        </Grid>
                    </Grid>
                    */}
                    {/* <Divider className={classes.divider} />
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Box fontWeight={600} className={classes.marginBottom}>
                            Main Information
                        </Box>
                        <Grid container direction="row" alignItems="center" className={classes.mainInformationChip}>
                            <Grid item xs={4}>
                                <LocationOnIcon color="primary" />
                            </Grid>
                            <Grid item xs={8} className={classes.mainInformationText}>
                                <Box>London, Uk</Box>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" alignItems="center" className={classes.mainInformationChip}>
                            <Grid item xs={4}>
                                <QueryBuilderIcon color="primary" />
                            </Grid>
                            <Grid item xs={8} className={classes.mainInformationText}>
                                <Box>20:00</Box>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" alignItems="center" className={classes.mainInformationChip}>
                            <Grid item xs={4}>
                                <LanguageIcon color="primary" />
                            </Grid>
                            <Grid item xs={8} className={classes.mainInformationText}>
                                <Box>English</Box>
                            </Grid>
                        </Grid>
                    </Grid> */}
                    {/* <Divider className={classes.divider} />
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Box fontWeight={600} className={classes.marginBottom}>
                            Visitor Device Info
                        </Box>
                        <Box>
                            <Box>
                                <LaptopMacIcon fontSize="large" />
                            </Box>
                        </Box>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Box
                            display="flex"
                            justifyContent="space-around"
                            width={1}
                            className={clsx(classes.marginBottom, classes.notesContainer)}
                        >
                            <Box fontWeight={600}>Notes</Box>
                            <Box>
                                <IconButton size="small">
                                    <EditIcon fontSize="small" color={"secondary"} />
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid> */}
                </Box>
            </Drawer>
        </div>
    );
});

export default withRouter(TemporaryDrawer);
