import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Email } from "@material-ui/icons";
import {
    TextField,
    InputAdornment,
    Button,
    Container,
    CssBaseline,
    Typography,
    CardContent,
    Card,
} from "@material-ui/core";
import { resetPasswordEmail } from "../../../services/user.service";
import { error, success } from "../../../services/toast.service";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        paddingTop: "100px",
        paddingLeft: 0,
        paddingRight: 0,
    },
    header: {
        color: "#737373",
    },
    card: {
        width: "fit-content",
        marginRight: "auto",
        marginLeft: "auto",
        padding: "22px",
    },
    backgroundColor: {
        background: "#FFF",
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const ForgotPassword = () => {
    const classes = useStyles();

    const [email, setEmail] = useState<string>("");

    const resetPassword = async () => {
        const result = await resetPasswordEmail(email);
        result.status === "success" ? success(result.data) : error(result.data);
    };

    return (
        <Container component="main" maxWidth="xs" className={classes.container}>
            <Card className={classes.card}>
                <CardContent>
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Typography component="h1" variant="h5" className={classes.header}>
                            Enter your email
                        </Typography>
                        <form className={classes.form}>
                            <TextField
                                className={classes.backgroundColor}
                                placeholder="Email"
                                required={true}
                                onChange={(event) => setEmail(event.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Email />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                autoFocus
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={() => resetPassword()}
                            >
                                Reset Password
                            </Button>
                        </form>
                    </div>
                </CardContent>
            </Card>
        </Container>
    );
};

export default ForgotPassword;
