import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import Button from "@material-ui/core/Button";

import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: "max-content",
        borderRadius: "20px",
        padding: theme.spacing(2.5),
        width: "100%",
        maxWidth: 560,
        [theme.breakpoints.up("sm")]: {
            minWidth: 430,
        },
        [theme.breakpoints.between("sm", "md")]: {
            width: 560,
        },
        [theme.breakpoints.between("md", "lg")]: {
            width: 430,
        },
        marginBottom: "1em",
        border: "none",
        backgroundColor: "white",
        [theme.breakpoints.up("lg")]: {
            width: 560,
        },
    },
    contactHeader: {
        fontWeight: 700,
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing(2),
        display: "flex",
        justifyContent: "space-between",
        height: 30,
    },
    contactContent: {
        textAlign: "center",
    },
    input: {
        background: "rgba(255, 136, 99, 0.15)",
        border: "1px solid",
        borderColor: theme.palette.warning.main,
        boxSizing: "border-box",
        borderRadius: "20px",
    },
    button: {
        border: "1.5px solid #737373",
        boxSizing: "border-box",
        borderRadius: "36px",
        color: theme.palette.secondary.main,
        fontSize: "13px",
        fontWeight: 700,
        padding: "2px 10px",
    },
}));

const Card = (props: {
    title?: string;
    input?: boolean;
    button?: boolean;
    handleClick?: Function;
    children: JSX.Element | Array<JSX.Element>;
}) => {
    const classes = useStyles();
    const { title, input, button, children, handleClick } = props;
    return (
        <div className={clsx(classes.root, { [classes.input]: input })}>
            {title && (
                <div className={classes.contactHeader}>
                    {title}

                    {button && (
                        <Button
                            variant="outlined"
                            startIcon={<ControlPointIcon />}
                            onClick={() => {
                                handleClick && handleClick();
                            }}
                            className={classes.button}
                        >
                            add data
                        </Button>
                    )}
                </div>
            )}
            <div className={classes.contactContent}>{children}</div>
        </div>
    );
};

export default Card;
