import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Auth from "../../services/auth.service";
import { createTemporaryUser } from "../../services/user.service";
import { Redirect } from "react-router-dom";
import { TextField, Button } from "@material-ui/core";
import { success, error } from "../../services/toast.service";
import { capitalizeFirstLetter } from "../../utils/helperFunctions";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import { banners, logos } from "../../utils/contants";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        overflow: "auto",
        height: "100vh",
        justifyContent: "space-between",
    },
    row: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1,
        flexDirection: "column",
        maxWidth: "900px",
        marginTop: "8vh",
    },
    paper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        marginBottom: "0.5em",
    },
    h2: {
        fontWeight: 500,
        fontSize: "45px",
        fontFamily: "Source Sans Pro",
        color: theme.palette.secondary.main,
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            fontSize: "25px",
        },
    },
    h3: {
        fontSize: "25px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "20px",
        },
    },
    title: {
        fontWeight: 700,
        fontSize: "86px",
        fontFamily: "Source Sans Pro",
        color: theme.palette.primary.main,
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            fontSize: "35px",
            marginTop: "20px",
        },
    },
    form: {
        display: "flex",
        width: "80%",
        alignItems: "center",
        justifyContent: "center",
    },
    headerContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginBottom: "3em",
        [theme.breakpoints.down("xs")]: {
            marginBottom: "2em",
        },
    },
    button: {
        margin: 0,
        height: "60px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        padding: "6px 24px",
        borderRadius: "0px 5px 5px 0px",
    },
    unitContainer: {
        backgroundColor: theme.palette.background.default,
        padding: "10px 24.5px",
        borderRadius: "5px",
        flexDirection: "column",
        width: "min-content",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2, 1.5),
        },
    },
    unitText: {
        color: theme.palette.secondary.main,
        fontSize: 15,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        },
    },
    field: {
        "& input": {
            background: "#F6FBFF",
            padding: "18px 14px",
            border: "1px solid #DDEAF2",
            fontFamily: "SOURCE SANS PRO",
        },
        "&: hover": {
            border: "none",
        },
        "& fieldset": {
            border: "none",
        },
    },
    subHeader: {
        color: theme.palette.background.paper,
        fontSize: "20px",
        marginBottom: theme.spacing(3),
        textAlign: "center",
        maxWidth: "650px",
        [theme.breakpoints.down("sm")]: {
            margin: "2em 16px 1em 16px",
        },
    },
    iconContainer: {
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#1A3361",
        padding: "3px",
        width: "30px",
        marginLeft: "0.5em",
        marginRight: "0.5em",
        [theme.breakpoints.down("xs")]: {
            width: "22px",
        },
    },
    defaultIcon: {
        padding: "1px",
        height: "25px",
        color: theme.palette.background.paper,
        [theme.breakpoints.down("xs")]: {
            width: "19px",
            height: "17px",
        },
        top: "1px",
        position: "relative",
    },
    disabledBtn: {
        backgroundColor: "rgb(235, 240, 239) !important",
    },
    landingTop: {
        backgroundImage: `url(${banners.landingTop})`,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.down("xs")]: {
            backgroundImage: `url(${banners.landingTopMobile})`,
        },
    },
    landingBottom: {
        height: "550px",
        width: "100%",
        background: `url(${banners.landingBottom}) no-repeat right 100%`,
        [theme.breakpoints.down("xs")]: {
            backgroundImage: `url(${banners.landingBottomMobile})`,
        },
    },
    logo: {
        backgroundImage: `url(${logos.kiboBlack})`,
        backgroundRepeat: "no-repeat",
        width: "200px",
        height: "80px",
        [theme.breakpoints.down("xs")]: {
            backgroundImage: `url(${logos.kiboBlackMobile})`,
            width: "120px",
            height: "45px",
        },
    },
    publerLogo: {
        backgroundImage: `url(${logos.publer})`,
        backgroundRepeat: "no-repeat",
        width: "120px",
        height: "45px",
        marginLeft: "10px",
    },
    paragraph: {
        fontFamily: "Source Sans Pro",
        textAlign: "center",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            padding: "0 30px",
        },
    },
    flex: {
        display: "flex",
    },
    fbGroupLink: {
        textDecoration: "none",
        color: theme.palette.primary.main,
        "&: hover": {
            color: "rgba(6, 133, 240, 0.1)",
        },
    },
    groupDivider: {
        marginTop: "3em",
        [theme.breakpoints.down("xs")]: {
            marginTop: "3em",
        },
    },
    sectionDivider: {
        marginTop: "1em",
        [theme.breakpoints.down("xs")]: {
            marginTop: "1em",
        },
    },
}));

const LandingPage = (props: any) => {
    const classes = useStyles();

    const status = props.location.state;

    const [email, setEmail] = useState<string>("");
    const [isFetching, setIsFetching] = useState<boolean>(false);
    useEffect(() => {
        if (status === "error") {
            error("There was an error activating the email!");
        }
    }, []);

    const createUser = async (event: any) => {
        event.preventDefault();
        setIsFetching(true);
        try {
            await createTemporaryUser(email);
            success("Confirmation email was sent successfully!");
            setIsFetching(false);
        } catch (err) {
            if (err.response.data.status === "fail") {
                error(err.response.data.data.email);
            } else {
                error("This email is already registered!");
            }
            setIsFetching(false);
        }
    };

    if (Auth.isAuthenticated()) return <Redirect to={{ pathname: "workspace" }} />;

    return (
        <div className={classes.container}>
            <div className={classes.landingTop} />

            <div className={classes.row}>
                <div className={classes.logo} />
                <div className={classes.headerContainer}>
                    <span className={classes.title}>Unified inbox</span>
                    <span className={classes.h2}>for Live chat, emails, messages,</span>
                    <span className={clsx(classes.h3, classes.h2)}>comments, mentions and reviews</span>
                </div>
                <div className={classes.headerContainer}>
                    {status === "success" ? (
                        <span className={classes.h3}>Email was successfully confirmed!</span>
                    ) : (
                        <span className={classes.h3}>Coming soon!</span>
                    )}
                </div>
                <div>
                    {status === "success" ? (
                        <div className={classes.paragraph}>
                            You will receive an email once the closed beta version is ready for testing.
                            <br /> Thank you for your interest!
                        </div>
                    ) : (
                        <>
                            <div className={classes.paper}>
                                <form className={classes.form} onSubmit={(event) => createUser(event)}>
                                    <TextField
                                        placeholder="Email address"
                                        required={true}
                                        fullWidth
                                        InputProps={{
                                            classes: {
                                                root: classes.field,
                                            },
                                        }}
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
                                        variant="outlined"
                                        autoFocus
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        className={classes.button}
                                        disabled={email === "" || isFetching}
                                        classes={{ disabled: classes.disabledBtn }}
                                    >
                                        subscribe
                                    </Button>
                                </form>
                            </div>
                            <div className={clsx(classes.paragraph, classes.sectionDivider)}>
                                Sign up to receive an invitation to the closed beta. Be among the very first users of
                                Kibo, <br /> help us build the best unified inbox, and get rewarded.
                            </div>
                        </>
                    )}
                </div>
                <div className={classes.groupDivider}>
                    <div className={classes.paragraph}>
                        For more updates, join our{" "}
                        <a
                            className={classes.fbGroupLink}
                            href="https://www.facebook.com/groups/KiboConnect"
                            target="_blank"
                        >
                            support group
                        </a>{" "}
                        on Facebook or follow Kibo on social media
                    </div>
                    <div className={clsx(classes.paper, classes.sectionDivider)}>
                        <div className={clsx(classes.iconContainer)}>
                            <a href="https://www.facebook.com/kibochatbot/" target="_blank">
                                <FacebookIcon className={clsx(classes.defaultIcon)} />
                            </a>
                        </div>
                        <div className={clsx(classes.iconContainer)}>
                            <a href="https://twitter.com/kibo_ai" target="_blank">
                                <TwitterIcon className={clsx(classes.defaultIcon)} />
                            </a>
                        </div>
                        <div className={clsx(classes.iconContainer)}>
                            <a href="https://www.instagram.com/kibo.ai/" target="_blank">
                                <InstagramIcon className={clsx(classes.defaultIcon)} />
                            </a>
                        </div>
                        <div className={clsx(classes.iconContainer)}>
                            <a href="https://www.linkedin.com/showcase/kibo-ai/" target="_blank">
                                <LinkedInIcon className={clsx(classes.defaultIcon)} />
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    className={clsx(classes.paragraph, classes.flex, classes.groupDivider)}
                    style={{ fontWeight: "bold" }}
                >
                    From the creators of{" "}
                    <a href="https://publer.io" target="_blank">
                        <div className={classes.publerLogo} />
                    </a>
                </div>
            </div>
            <div style={{ width: "100%", marginTop: "-37vh" }}>
                <div className={classes.landingBottom} />
            </div>
        </div>
    );
};

export default withRouter(LandingPage);
