export const setLocalStorage = (name: string, value: any) => {
    window.localStorage.setItem(name, value);
};

export const getLocalStorage = (name: string): any => {
    return window.localStorage.getItem(name);
};

export const deleteLocalStorage = (name: string) => {
    window.localStorage.removeItem(name);
};
