import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { TextField, InputAdornment, Button } from "@material-ui/core";
import { Email, Lock } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import clsx from "clsx";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import SettingsNavigation from "./SettingsNavigation";
import { useStores } from "../../../hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            "justify-content": "flex-start",
        },
        backgroundColor: {
            background: "#FFF",
        },
        wrapper: {
            width: "100%",
            maxWidth: "700px",
        },
        accessWrapper: {
            display: "flex",
            width: "100%",
            maxWidth: 800,
            flexDirection: "column",
            borderRadius: "17px",
            padding: theme.spacing(2),
            backgroundColor: "white",
        },
        row: {
            display: "grid",
            "grid-template-columns": "1fr",
            gridGap: "1em",
        },
        card: {
            borderRadius: "0px 0px 20px 20px",
            padding: theme.spacing(2),
            backgroundColor: "white",
        },
        input: {
            background: theme.palette.background.default,
            "& fieldset": {
                border: "none",
            },
            "& input": {
                fontFamily: "SOURCE SANS PRO",
            },
            borderRadius: "5px",
            width: "100%",
            height: 40,
            marginTop: theme.spacing(0.5),
        },
        placeholder: {
            fontSize: 13,
            color: theme.palette.primary.main,
        },
        grayColor: {
            color: "#737373",
        },
        forgotPasswordText: {
            display: "grid",
            alignContent: "center",
            color: "#737373",
        },
        headerText: {
            color: theme.palette.secondary.main,
        },
        button: {
            border: "1.5px solid !important",
            color: theme.palette.primary.main,
            fontSize: 13,
            borderRadius: "20px",
        },
        endAdornament: {
            cursor: "pointer",
        },
    })
);
interface IShowPassword {
    first: boolean;
    second: boolean;
}
const Access = observer(() => {
    const classes = useStyles();
    const { userStore } = useStores();
    const [showPassword, setShowPassword] = useState<IShowPassword>({ first: false, second: false });

    const handleClickShowPassword = (key: keyof IShowPassword, value: boolean) => {
        setShowPassword({ ...showPassword, [key]: value });
    };

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <SettingsNavigation />
                <div className={clsx(classes.row, classes.card)}>
                    <div>
                        <span className={classes.placeholder}>Email</span>
                        <TextField
                            onChange={(e) => {
                                userStore.setEmail(e.target.value);
                            }}
                            value={userStore.userSettings.email}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" classes={{ root: classes.grayColor }}>
                                        <Email />
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            error={userStore.userError && userStore.userError.email ? true : false}
                            helperText={userStore.userError?.email}
                            classes={{ root: classes.input }}
                            size="small"
                        />
                    </div>
                    <div>
                        <Button
                            onClick={() => {
                                userStore.editUserEmail();
                            }}
                            disabled={userStore.userSettings.email === userStore.user.email}
                            variant="outlined"
                            color={"primary"}
                            classes={{ root: classes.button }}
                        >
                            Change email
                        </Button>
                    </div>
                    <span className={classes.headerText}>Change Password</span>

                    {userStore.userSettings.password ? (
                        <div>
                            <span className={classes.placeholder}>Current Password</span>
                            <TextField
                                placeholder={"Enter current password"}
                                className={classes.input}
                                onChange={(e) => {
                                    userStore.setCurrentPassword(e.target.value);
                                }}
                                value={userStore.currentPassword}
                                id="outlined-start-adornment"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" classes={{ root: classes.grayColor }}>
                                            <Lock />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                size="small"
                                classes={{ root: classes.input }}
                                error={userStore.userError && userStore.userError.currentPassword ? true : false}
                                helperText={userStore.userError?.currentPassword}
                            />
                            <Link to="/forgot-password" className={classes.forgotPasswordText}>
                                Forgot password?
                            </Link>
                        </div>
                    ) : (
                        <span className={classes.forgotPasswordText}>
                            Since you have loged in with google set a new password
                        </span>
                    )}

                    <div>
                        <span className={classes.placeholder}>New Password</span>
                        <TextField
                            placeholder={"Enter new password"}
                            size="small"
                            classes={{ root: classes.input }}
                            onChange={(e) => {
                                userStore.setNewPassword(e.target.value);
                            }}
                            value={userStore.newPassword}
                            type={showPassword.first ? "text" : "password"}
                            id="outlined-start-adornment"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.grayColor}>
                                        <Lock />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        className={clsx(classes.endAdornament, classes.grayColor)}
                                        onClick={() => handleClickShowPassword("first", !showPassword.first)}
                                    >
                                        {showPassword.first ? <Visibility /> : <VisibilityOff />}
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            className={classes.input}
                            error={userStore.userError && userStore.userError.password ? true : false}
                            helperText={userStore.userError?.password}
                        />
                    </div>
                    <div>
                        <span className={classes.placeholder}>Confirm Password</span>
                        <TextField
                            size="small"
                            placeholder={"Confirm password"}
                            classes={{ root: classes.input }}
                            type={showPassword.second ? "text" : "password"}
                            onChange={(e) => {
                                userStore.setConfrimtPassword(e.target.value);
                            }}
                            value={userStore.confirmPassword}
                            id="outlined-start-adornment"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" classes={{ root: classes.grayColor }}>
                                        <Lock />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        className={clsx(classes.endAdornament, classes.grayColor)}
                                        onClick={() => handleClickShowPassword("second", !showPassword.second)}
                                    >
                                        {showPassword.second ? <Visibility /> : <VisibilityOff />}
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            error={userStore.userError && userStore.userError.confirmPassword ? true : false}
                            helperText={userStore.userError?.confirmPassword}
                        />
                    </div>
                    <div>
                        <Button
                            onClick={() => {
                                userStore.editUserAccessSettings();
                            }}
                            disabled={userStore.newPassword.length === 0}
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                        >
                            Change Password
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Access;
