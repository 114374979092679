import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import Typography from "@material-ui/core/Typography";

import NavBar from "../common/NavBar";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.default,
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        display: "flex",
        justifyContent: "center",
        " &::-webkit-scrollbar": {
            width: "12px",
            height: "10px",
            marginRight: "5px",
        },
        "&::-webkit-scrollbar-track": {
            background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#A0A0A0",
            borderRadius: "7px",
            backgroundClip: "padding-box",
            border: "3px solid transparent",
            minHeight: "50px",
            "&:hover": {
                background: "#A0A0A0",
                backgroundClip: "padding-box",
            },
        },
    },
    container: {
        display: "grid",
        gridTemplateColumns: "1fr",
        height: "100%",
        gap: "3em",
        padding: "40px",
        maxWidth: "1000px",
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "7px",
        marginLeft: "30px",
        marginRight: "30px",
    },
}));

const Privacy = (props: any) => {
    const classes = useStyles();

    return (
        <div>
            <div>
                <NavBar />
            </div>
            <div className={classes.root}>
                <div className={classes.container}>
                    <div>
                        <Typography variant="h4" gutterBottom>
                            Privacy Policy
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Revised: 22/07/2021
                        </Typography>
                        <br />
                        <br />
                        <Typography variant="subtitle1" gutterBottom>
                            Kibo is provided by Kalemi Code ("we" or "us" or "our") a software company located in
                            Tirana, Albania with the registration code L71705026N.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            1. What does this Privacy Policy cover?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We provide this Privacy Policy to inform users of our policies and procedures regarding the
                            collection, use and disclosure of personally identifiable information received from users of
                            this website, located at <a href="https://kibo.ai">https://kibo.ai</a> ("Site"), as well as
                            all related websites.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Because Kibo is an app integrated with other communication channels, information that you
                            provide to us or that we collect is also generally accessible to those providers and subject
                            to their privacy policy. We do not control their privacy practices. For more information on
                            their privacy policies, please visit their respective privacy policies.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            2. Information Collection and Use
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Our primary goals in collecting personally identifiable information are to provide you with
                            the product and services made available through the Site, including, but not limited, to the
                            Service, to communicate with you, and to manage your registered user account, if you have
                            one.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            If you do not provide Personal Information when requested, you may not be able to benefit
                            from our Service if that information is necessary to provide you with the service or if we
                            are legally required to collect it.
                        </Typography>
                        <br />
                        <br />
                        <Typography variant="h6" gutterBottom>
                            Information Collected Upon Registration
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            If you desire to have access to certain restricted sections of the Site, you will be
                            required to become a registered user, and to submit certain personally identifiable
                            information to Kibo. This happens when you sign up for the Service. Personally identifiable
                            information that we may collect in such instances may include your full name, email address
                            and time zone. We do not store your IP or physical location.
                        </Typography>
                        <br />
                        <br />
                        <Typography variant="h6" gutterBottom>
                            Use of Contact Information
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            In addition, we may use your contact information to market to you, and provide you with
                            information about, our products and services, including but not limited to our Service. If
                            you decide at any time that you no longer wish to receive such information or communications
                            from us, please follow the unsubscribe instructions provided in any of the communications.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            3. Cookies
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Like many websites, we also use "cookie" technology to collect additional website usage data
                            and to improve the Site and our service. A cookie is a small data file that we transfer to
                            your computer's hard disk. We do not use cookies to collect personally identifiable
                            information. Kibo may use both session cookies and persistent cookies to better understand
                            how you interact with the Site and our Service, to monitor aggregate usage by our users and
                            web traffic routing on the Site, and to improve the Site and our services. A session cookie
                            enables certain features of the Site and our service and is deleted from your computer when
                            you disconnect from or leave the Site. A persistent cookie remains after you close your
                            browser and may be used by your browser on subsequent visits to the Site. Persistent cookies
                            can be removed by following your web browser help file directions. Most Internet browsers
                            automatically accept cookies. You can instruct your browser, by editing its options, to stop
                            accepting cookies or to prompt you before accepting a cookie from the websites you visit.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            4. Sharing of information
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Our primary purpose for using information is to allow you to manage the inboxes of the
                            social networks and other channels you've authenticated with. We may allow you to link your
                            account on Kibo with an account on a third party social network platform, such as Facebook,
                            and to transfer your information to and from the applicable third party platform. Once you
                            exchange messages with a social media platform, its use will be governed by that platform’s
                            privacy policy.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            5. Access to information
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            You can access and update personally identifiable information that you have provided to us
                            via your settings page. If you want your information deleted from our servers, simply delete
                            your Kibo account or contact us.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            6. Data Security
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We use third party vendors and hosting partners to provide the necessary hardware, software,
                            networking, storage, and related technology required to run Kibo. Although we own the code,
                            databases, and all rights to the Kibo application, you retain all rights to your data. You
                            can contact us at any time if you would like your data to be completely deleted from our
                            servers.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We are very concerned about safeguarding the confidentiality of your Personal Information.
                            We employ administrative and electronic measures designed to appropriately protect your
                            Personal Information against accidental or unlawful destruction, accidental loss,
                            unauthorized alteration, unauthorized disclosure or access, misuse, and any other unlawful
                            form of processing of the Personal Information in our possession. Please be aware that no
                            security measures are perfect or impenetrable. We cannot guarantee that information about
                            you will not be accessed, viewed, disclosed, altered, or destroyed by breach of any of our
                            administrative, physical, and electronic safeguards, subject to requirements under
                            applicable law to ensure or warrant information security.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We will make any legally-required disclosures of any breach of the security,
                            confidentiality, or integrity of your unencrypted electronically stored Personal Information
                            to you via email or conspicuous posting on our Site in the most expedient time possible and
                            without unreasonable delay, consistent with (i) the legitimate needs of law enforcement or
                            (ii) any measures necessary to determine the scope of the breach and restore the reasonable
                            integrity of the data system , and any other disclosures that may be required under
                            applicable law.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We also take measures to delete your Personal Information or keep it in a form that does not
                            permit identifying you when this information is no longer necessary for the purposes for
                            which we process it, unless we are required by law to keep this information for a longer
                            period. When determining the retention period, we take into account various criteria, such
                            as the type of products and services requested by or provided to you, the nature and length
                            of our relationship with you, possible re-enrollment with our products or services, the
                            impact on the services we provide to you if we delete some information from or about you,
                            mandatory retention periods provided by law and the statute of limitations.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            7. Links to Other Websites
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Our Site contains links to other websites. The fact that we link to a website is not an
                            endorsement, authorization or representation of our affiliation with that third party. We do
                            not exercise control over third party websites. These other websites may place their own
                            cookies or other files on your computer, collect data or solicit personally identifiable
                            information from you. Other sites follow different rules regarding the use or disclosure of
                            the personally identifiable information you submit to them. We encourage you to read the
                            privacy policies or statements of the other websites you visit.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            8. Data transfer
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            By using our Site you are consenting to our processing of your information as set forth in
                            this Privacy Policy now and as amended by us. "Processing" means using cookies on a computer
                            or using or touching information in any way, including, but not limited to, collecting,
                            storing, deleting, using, combining and disclosing information, all of which activities will
                            take place in our servers located in Frankfurt, Germany. If you reside outside Germnay, your
                            personally identifiable information will be transferred to Germany, and processed and stored
                            there under Germnay privacy standards. By visiting our Site and providing information to us,
                            you consent to such transfer to, and processing in Germany.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            9. Persons under age 13
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            In general, we do not knowingly collect personally identifiable information from individuals
                            under the age of 13. Please do not send any information about yourself to us if you are
                            under the age of 13. Should we learn that a person under 13 has provided us with personal
                            information, we will delete that information from our database.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            10. Material changes to this privacy policy
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            If we need to materially change this Privacy Policy in the future, we will notify you by
                            placing a prominent notice on this app. Please note that you are responsible for checking
                            the website and Privacy Policy periodically for notice of changes.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            11. Contact info
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Kalemi Code is the entity responsible for the processing of your Personal Information. If
                            you have any questions or comments regarding this Privacy Policy, or if you would like to
                            exercise your rights to your Personal Information, you may contact us by emailing us at
                            contact@kalemicode.com or by writing to us at:
                            <br />
                            <br />
                            <div>Kalemi Code</div>
                            <div>Rr. Sami Frasheri</div>
                            <div>Pallati 39. Kati 2. Apt 6</div>
                            <div>Tirana 1019,</div>
                            <div>Albania</div>
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Privacy);
