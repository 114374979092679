import { IChannel } from "../stores/ChannelStore";
import { accountAxiosInstance } from "../utils/axios.instance";
import { getLocalStorage } from "./localStorageservice";

export const getAccountsByWorkspaceId = async (pageSize: number, skip: number, search: string) => {
    const workspace = JSON.parse(getLocalStorage("workspace"));
    const response = await accountAxiosInstance({
        url: `/${workspace._id}/${pageSize}/${skip}/${search}`,
        method: "GET",
    });

    return response.data;
};

export const addAccount = async (account: IChannel) => {
    const response = await accountAxiosInstance({
        url: "/",
        method: "POST",
        data: account,
    });

    return response.data;
};

export const updateAccount = async (account: IChannel) => {
    const response = await accountAxiosInstance({
        url: `/${account._id}`,
        method: "PUT",
        data: account,
    });

    return response.data;
};

export const deleteAccount = async (accountId: string) => {
    const response = await accountAxiosInstance({
        url: `/${accountId}`,
        method: "DELETE",
    });

    return response.data;
};
