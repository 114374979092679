import React from "react";
import { makeStyles, Theme, createStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import SearchIcon from "@material-ui/icons/Search";
import { observer } from "mobx-react-lite";

import { useStores } from "../../../hooks";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchBarContainer: {
            marginTop: "18px",
            marginBottom: theme.spacing(2),
        },
        searchBar: {
            border: "none",
            background: "transparent",
            width: "100%",
            outline: "none",
            padding: 0,
            maxWidth: 250,
        },
    })
);

const SearchBar = observer(() => {
    const classes = useStyles();
    const { channelStore } = useStores();
    const theme = useTheme();

    return (
        <Box display="flex" alignItems="center" className={classes.searchBarContainer}>
            <Box mr={theme.spacing(0.2)}>
                <SearchIcon />
            </Box>
            <input
                type="text"
                placeholder="Search"
                className={classes.searchBar}
                onChange={(event) => channelStore.onChangeFilter(event.target.value)}
            />
        </Box>
    );
});

export default SearchBar;
