import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { observer } from "mobx-react-lite";
import { withRouter } from "react-router";

import { useStores } from "../../hooks";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PersonIcon from "@material-ui/icons/Person";
import ChatIcon from "@material-ui/icons/Chat";
import SendIcon from "@material-ui/icons/Send";
import BusinessIcon from "@material-ui/icons/Business";
import Box from "@material-ui/core/Box";
import SettingsIcon from "@material-ui/icons/Settings";
import WifiTetheringIcon from "@material-ui/icons/WifiTethering";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginRight: theme.spacing(4),
            [theme.breakpoints.down("sm")]: {
                marginRight: 0,
                flexDirection: "row",
                position: "fixed",
                bottom: 0,
                zIndex: 9999,
                left: 0,
                right: 0,
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        },
        drawerPaper: {
            [theme.breakpoints.up("md")]: {
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
            },
        },
        listItemIcons: {
            minWidth: "20px",
        },
        listItems: {
            [theme.breakpoints.up("md")]: {
                width: "55%",
            },
            borderRadius: "50%",
            justifyContent: "center",
            padding: theme.spacing(1),
            margin: theme.spacing(1),
            backgroundColor: "white",
        },
        workspaceIconWrapper: {
            borderRadius: "50%",
            justifyContent: "center",
            padding: theme.spacing(1),
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(1.5),
            backgroundColor: theme.palette.background.default,
            [theme.breakpoints.down("sm")]: {
                margin: theme.spacing(1),
                backgroundColor: theme.palette.secondary.main,
                "&:hover": {
                    backgroundColor: theme.palette.secondary.light,
                },
            },
        },
        workspaceIcon: {
            [theme.breakpoints.down("sm")]: {
                color: "white",
            },
            color: theme.palette.secondary.main,
        },
        firstGroupWrapper: {
            [theme.breakpoints.down("sm")]: {
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
            },
        },
        display: {
            display: "none",
        },
        divider: {
            height: "5px",
            background: theme.palette.background.default,
        },
        verticalDevider: {
            width: "5px",
            background: theme.palette.background.default,
        },
    })
);

const Sidebar = observer((props: any) => {
    const { conversationStore, workspaceStore, tourStore } = useStores();
    const { selectedConversation } = conversationStore;
    const classes = useStyles();
    const location = useLocation();
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("md"));

    const workspace = workspaceStore.workspaces[0];

    const gotToChannel = () => {
        props.history.push(`/workspace/${workspace._id}/channels/`);
        if (tourStore.shouldOpenTour()) {
            setTimeout(() => {
                window.location.reload();
            }, 1);
        }
    };
    return (
        <Box
            bgcolor="white"
            className={clsx([classes.drawerPaper], [classes.root], {
                [classes.display]: conversationStore.selectedConversation?._id && !desktop,
            })}
            id="left-panel"
        >
            <CssBaseline />
            <Box className={classes.firstGroupWrapper}>
                <Box display="flex">
                    <Box display="flex" justifyContent="center" className={clsx([classes.drawerPaper])}>
                        <Tooltip title="Workspace Name">
                            <ListItem button component={Link} to="#" className={classes.workspaceIconWrapper}>
                                <ListItemIcon className={classes.listItemIcons}>
                                    <BusinessIcon fontSize="small" className={classes.workspaceIcon} />
                                </ListItemIcon>
                            </ListItem>
                        </Tooltip>
                    </Box>
                    {!desktop && <div className={clsx({ [classes.verticalDevider]: !desktop })} />}
                </Box>
                {desktop && <Divider className={classes.divider} />}
                <Box display="flex" justifyContent="center">
                    <Tooltip title="Chat">
                        <ListItem
                            button
                            component={Link}
                            to={`/workspace/${workspace?._id}/inbox/${
                                selectedConversation ? selectedConversation._id : ""
                            }`}
                            selected={location.pathname.includes("inbox")}
                            className={classes.listItems}
                        >
                            <ListItemIcon className={classes.listItemIcons}>
                                <ChatIcon
                                    fontSize="small"
                                    color={location.pathname.includes("inbox") ? "primary" : "secondary"}
                                />
                            </ListItemIcon>
                        </ListItem>
                    </Tooltip>
                </Box>
                {/* <Box display="flex" justifyContent="center">
                    <Tooltip title="Campaigns">
                        <ListItem
                            button
                            component={Link}
                            to="/campaigns"
                            selected={location.pathname === "/campaigns"}
                            className={classes.listItems}
                        >
                            <ListItemIcon className={classes.listItemIcons}>
                                <SendIcon
                                    fontSize="small"
                                    color={location.pathname === "/campaigns" ? "primary" : "secondary"}
                                />
                            </ListItemIcon>
                        </ListItem>
                    </Tooltip>
                </Box> */}
                <Box display="flex" justifyContent="center">
                    <Tooltip title="Contacts">
                        <ListItem
                            button
                            component={Link}
                            to={`/workspace/${workspace?._id}/contacts`}
                            selected={location.pathname.includes("/contacts")}
                            className={classes.listItems}
                        >
                            <ListItemIcon className={classes.listItemIcons}>
                                <PersonIcon
                                    fontSize="small"
                                    color={location.pathname.includes("/contacts") ? "primary" : "secondary"}
                                />
                            </ListItemIcon>
                        </ListItem>
                    </Tooltip>
                </Box>
                <Box display="flex" justifyContent="center" id="connect-channel">
                    <Tooltip title="Channels">
                        <ListItem
                            button
                            //component={Link}
                            //to={`/workspace/${workspace?._id}/channels`}
                            selected={location.pathname.includes("channels")}
                            className={classes.listItems}
                            onClick={() => gotToChannel()}
                        >
                            <ListItemIcon className={classes.listItemIcons}>
                                <WifiTetheringIcon
                                    fontSize="small"
                                    color={location.pathname.includes("channels") ? "primary" : "secondary"}
                                />
                            </ListItemIcon>
                        </ListItem>
                    </Tooltip>
                </Box>
                {/*{!desktop && (*/}
                {/*    <Box display="flex" justifyContent="center">*/}
                {/*        <Tooltip title="Settings">*/}
                {/*            <ListItem*/}
                {/*                button*/}
                {/*                component={Link}*/}
                {/*                to="#"*/}
                {/*                selected={location.pathname === "#"}*/}
                {/*                className={classes.listItems}*/}
                {/*            >*/}
                {/*                <ListItemIcon className={classes.listItemIcons}>*/}
                {/*                    <SettingsIcon*/}
                {/*                        fontSize="small"*/}
                {/*                        color={location.pathname === "#" ? "primary" : "secondary"}*/}
                {/*                    />*/}
                {/*                </ListItemIcon>*/}
                {/*            </ListItem>*/}
                {/*        </Tooltip>*/}
                {/*    </Box>*/}
                {/*)}*/}
            </Box>
            {/*{desktop && (*/}
            {/*    <Box display="flex" justifyContent="center">*/}
            {/*        <Tooltip title="Settings">*/}
            {/*            <ListItem*/}
            {/*                button*/}
            {/*                component={Link}*/}
            {/*                to="#"*/}
            {/*                selected={location.pathname === "#"}*/}
            {/*                className={classes.listItems}*/}
            {/*            >*/}
            {/*                <ListItemIcon className={classes.listItemIcons}>*/}
            {/*                    <SettingsIcon*/}
            {/*                        fontSize="small"*/}
            {/*                        color={location.pathname === "#" ? "primary" : "secondary"}*/}
            {/*                    />*/}
            {/*                </ListItemIcon>*/}
            {/*            </ListItem>*/}
            {/*        </Tooltip>*/}
            {/*    </Box>*/}
            {/*)}*/}
        </Box>
    );
});

export default withRouter(Sidebar);
