import React from "react";
import clsx from "clsx";
import {
    createStyles,
    makeStyles,
    Grid,
    Theme,
    Typography,
    ListItemText,
    Box,
    Badge,
    ListItem,
    Avatar,
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../hooks";
import { IConversation } from "../../../../stores/ConversationStore";
import {
    timeGapBetweenCurrentAndGivenDate,
    badgeProvider,
    conversationStatusIcon,
} from "../../../../utils/helperFunctions";
import ConversationType from "./ConversationType";
import RatingComponent from "./RatingComponent";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        arrowButton: {
            background: theme.palette.secondary.main,
            "&:hover": {
                background: theme.palette.secondary.main,
            },
        },
        unreadButton: {
            background: theme.palette.primary.main,
            "&:hover": {
                background: theme.palette.primary.main,
            },
        },
        resolvedButton: {
            background: theme.palette.success.main,
            "&:hover": {
                background: theme.palette.success.main,
            },
        },
        conversationIcon: {
            color: "white",
        },
        operator: {
            color: theme.palette.primary.main,
        },
        conversationListItem: {
            display: "block",
            padding: theme.spacing(2.5),
            backgroundColor: "white",
        },
        backgroundColor: {
            backgroundColor: "#F2F9FF!important",
            borderLeft: "5px solid",
            borderLeftColor: theme.palette.primary.main,
        },
        lastMessage: {
            justifyContent: "space-between",
        },
        unreadMessage: {
            fontWeight: "bold",
        },
        conversationsLastMessageTimer: {
            marginTop: "6px",
            whiteSpace: "nowrap",
        },
        customBox: {
            display: "-webkit-box",
            boxOrient: "vertical",
            lineClamp: 2,
            wordBreak: "break-all",
            overflow: "hidden",
        },
        contactNameContainer: {
            marginLeft: theme.spacing(1),
        },
        contactName: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            paddingRight: "10px",
            [theme.breakpoints.down("xs")]: {
                maxWidth: "180px !important",
            },
            [theme.breakpoints.down("md")]: {
                maxWidth: "100%",
            },
            [theme.breakpoints.between("md", "lg")]: {
                maxWidth: "80px",
            },
            [theme.breakpoints.up("lg")]: {
                maxWidth: "150px",
            },
        },
        margin: {
            marginBottom: "20px",
            marginLeft: "4px",
        },

        fullWidth: {
            width: "100%",
        },
        conversationTypeWrapper: {
            marginTop: "-8px",
            fontWeight: 700,
        },
        maxHeight: {
            maxHeight: "40px",
        },
    })
);

const ConversationListItem = observer((props: { conversation: IConversation; history: any }) => {
    const { conversationStore, messageStore } = useStores();
    const classes = useStyles();
    const { conversation } = props;
    const selected = conversationStore.selectedConversation?._id === conversation._id;

    if (timeGapBetweenCurrentAndGivenDate(conversation.lastMessageTime).timeGapAsNumber < 3599999) {
        setInterval(() => {
            conversationStore.calculateLastMessageTime(conversation.lastMessageTime, conversation._id);
        }, 60000);
    } else if (timeGapBetweenCurrentAndGivenDate(conversation.lastMessageTime).timeGapAsNumber < 85399999) {
        setInterval(() => {
            conversationStore.calculateLastMessageTime(conversation.lastMessageTime, conversation._id);
        }, 3600000);
    }

    const onConversationClick = async (conversation: IConversation) => {
        if (conversation._id !== conversationStore.selectedConversation?._id) {
            conversationStore.setSelectedConversation(conversation);
            props.history.push(conversation._id);
            messageStore.setMessageToEdit(null);
        }
    };

    return (
        <ListItem
            button
            selected={selected}
            className={clsx(classes.conversationListItem, { [classes.backgroundColor]: selected })}
            component="div"
            onClick={() => onConversationClick(conversation)}
        >
            <Grid container direction="row">
                <Box display="flex" className={clsx(classes.margin, classes.fullWidth)}>
                    <Badge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        badgeContent={badgeProvider(conversation.provider)}
                        className={classes.maxHeight}
                    >
                        <Avatar src={conversation.picture} />
                    </Badge>
                    <Box className={classes.fullWidth}>
                        <Box display="flex" justifyContent="space-between" className={classes.fullWidth}>
                            <ListItemText
                                primary={
                                    <Typography component="span" variant="body2">
                                        <Box className={classes.contactName} fontWeight="fontWeightBold">
                                            {conversation.contactName}
                                        </Box>
                                    </Typography>
                                }
                                classes={{ root: classes.contactNameContainer }}
                                //we will get back to this when we have more operators
                                // secondary={
                                //     <Chip
                                //         size="small"
                                //         component="span"
                                //         icon={<HeadsetMicIcon className={classes.operator} />}
                                //         label={"conversation.assignedOperator"}
                                //     />
                                // }
                            />
                            <Grid item xs={2}>
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    className={classes.conversationsLastMessageTimer}
                                >
                                    {
                                        conversationStore.conversationsLastMessageTimer[conversation._id]
                                            .accurateLastMessageTime
                                    }
                                </Box>
                            </Grid>
                        </Box>
                        <Box className={classes.conversationTypeWrapper}>
                            <ConversationType type={conversation.type} />
                        </Box>
                    </Box>
                </Box>
                <Grid
                    container
                    className={clsx({ [classes.unreadMessage]: conversation.unreadMessages > 0 }, [
                        classes.lastMessage,
                    ])}
                >
                    {conversation.type === "REVIEW" && (
                        <div>
                            <RatingComponent />
                        </div>
                    )}
                    <Grid item xs={10}>
                        <div className={classes.customBox}>{conversation.lastMessage}</div>
                    </Grid>
                    <Box display="flex" justifyContent="flex-end" alignItems={"flex-end"}>
                        {conversationStatusIcon(conversation)}
                    </Box>
                </Grid>
            </Grid>
        </ListItem>
    );
});

export default ConversationListItem;
