import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import clsx from "clsx";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import HighlightOffIcon from "@material-ui/icons/HighlightOffOutlined";
import { relative } from "path";

const useStyles = makeStyles((theme) =>
    createStyles({
        closeIcon: {
            position: "absolute",
            right: 5,
            top: 5,
            color: "#a9a6a6",
            cursor: "pointer",
            width: "20px",
            height: "20px",
            "&:hover": {
                color: "gray",
            },
        },
        dialogContent: {
            padding: theme.spacing(3),
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(2),
            },
        },
        paper: {
            borderRadius: "20px",
            padding: theme.spacing(1),
        },
        actionBtn: {
            boxSizing: "border-box",
            borderRadius: "30px",
            fontWeight: 700,
            textTransform: "unset",
        },
        cancelBtn: {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
            "&:hover": {
                backgroundColor: "rgba(255, 87, 107, 0.1)",
            },
        },
        actionsWrapper: {
            padding: "0 24px 24px 24px",
        },
        closeIconWrapper: {
            position: "relative",
        },
    })
);

const Modal = (props: any) => {
    const classes = useStyles();

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            className={classes.paper}
            classes={{ paper: classes.paper }}
            maxWidth="lg"
            disableBackdropClick={props.disableBackdropClick}
        >
            {props.hideActions && (
                <div className={classes.closeIconWrapper}>
                    <HighlightOffIcon className={classes.closeIcon} onClick={() => props.onClose()} />
                </div>
            )}
            {/* <DialogTitle>{props.title || ""}</DialogTitle> */}
            <DialogContent className={classes.dialogContent}>{props.children}</DialogContent>
            <DialogActions
                classes={{ root: classes.actionsWrapper }}
                style={{ display: props.hideActions ? "none" : "" }}
            >
                <Button
                    onClick={props.onClose}
                    variant="outlined"
                    className={clsx(classes.actionBtn, classes.cancelBtn)}
                >
                    Cancel
                </Button>
                {!props.displaySaveButton && (
                    <Button
                        onClick={props.handleConfirm}
                        color="primary"
                        variant="outlined"
                        className={classes.actionBtn}
                    >
                        Save
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default Modal;
