import { notificationsAxiosInstance } from "../utils/axios.instance";

export const getUserNotifications = async (id: any, skip: number, pageSize: number, type: string) => {
    const response = await notificationsAxiosInstance({
        method: "GET",
        url: `${id}/${skip}/${pageSize}/${type}`,
    });
    return response.data;
};

export const removeUserNotifications = async (id: any) => {
    const response = await notificationsAxiosInstance({
        method: "DELETE",
        url: `${id}`,
    });
    return response.data;
};
