import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { observer } from "mobx-react-lite";

import Modal from "../../../common/Modal";
import AccountProvider from "../AccountProvider";
import { addAccount } from "../../../../services/account.service";
import ChatId from "./ChatId";
import { Guid } from "guid-typescript";
import { useStores } from "../../../../hooks";

const useStyles = makeStyles((theme) => ({
    backgroundColor: {
        background: "#FFF",
    },
    container: {
        minWidth: "320px",
        display: "grid",
        gap: "24px",
        [theme.breakpoints.down("sm")]: {
            minWidth: "250px",
        },
    },
    title: {
        color: "#6D6D6D",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2.5),
    },
    displayColumn: {
        display: "flex",
        flexDirection: "column",
    },
    input: {
        "& input": {
            background: theme.palette.background.default,
            border: "1px solid #fff",
            fontFamily: "SOURCE SANS PRO",
            color: "#3D3D3D",
            width: "100%",
            paddingLeft: theme.spacing(1),
        },
        "&: hover": {
            border: "none",
        },
        "& fieldset": {
            border: "none",
        },
    },
    placeholder: {
        fontSize: 14,
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(1),
    },
}));

const Chat = observer((props: { open: boolean; setOpen: Function; addNewAccount: Function }) => {
    const classes = useStyles();
    const { workspaceStore } = useStores();
    const [chatName, setChatName] = useState("");
    const [openChatDialog, setOpenChatDialog] = useState(false);
    const [chatId, setChatId] = useState("");
    const createChat = async () => {
        let workspace = workspaceStore.workspaces[0];
        const result = await addAccount({
            provider: "chat",
            name: chatName,
            workspaceId: workspace?._id,
            chatId: Guid.create().toString(),
        });
        if (result.status === "success") {
            props.addNewAccount(result.data);
            setChatId(result.data.chatId);
            setOpenChatDialog(true);
        }
    };
    return (
        <>
            <Modal
                open={props.open}
                onClose={() => props.setOpen(false)}
                handleConfirm={() => createChat()}
                title={"Chat Name"}
                disableBackdropClick={true}
            >
                <AccountProvider provider="chat" />
                <div className={classes.title}>Enter the domain name</div>

                <div className={classes.container}>
                    <div className={classes.displayColumn}>
                        <span className={classes.placeholder}>Custom chat key*</span>
                        <InputBase
                            id="outlined-basic"
                            className={classes.input}
                            required={true}
                            onChange={(event) => setChatName(event.target.value)}
                        />
                    </div>
                </div>
            </Modal>

            <ChatId open={openChatDialog} setOpen={setOpenChatDialog} chatId={chatId} />
        </>
    );
});

export default Chat;
