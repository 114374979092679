import { observable, action, runInAction, computed } from "mobx";
import { StoreClassInterface, RootStore } from "./RootStore";
import {
    deleteConversation,
    getConversationById,
    getConversations,
    updateConversation,
} from "../services/conversation.service";
import { timeGapBetweenCurrentAndGivenDate } from "../utils/helperFunctions";
import { UiStore } from "./UiStore";

export interface IConversation {
    accountId: string;
    assignedOperatorName: string;
    assignedOperatorPicture: string;
    contactId: string;
    contactUid: string;
    contactName: string;
    createdAt: Date;
    lastMessage: string;
    lastMessageTime: Date;
    picture: string;
    provider: string;
    read: boolean;
    resolved: boolean;
    updatedAt: Date;
    unreadMessages: number;
    _id: string;
    typing?: boolean;
    deleted: boolean;
    type?: string;
}

export interface IMessageTimer {
    accurateLastMessageTime: string;
}

export interface IConversationLastMessageTimer {
    [key: string]: IMessageTimer;
}
export class ConversationStore implements StoreClassInterface {
    @observable conversations: Array<IConversation> = [];
    @observable total: number = 0;
    @observable selectedConversation: IConversation | null = null;
    @observable conversationsLastMessageTimer: IConversationLastMessageTimer = {};
    @observable filter: string = "";
    @observable statusFilter: string = "All";
    @observable statusType: string = "All";
    @observable typing: boolean = false;
    @observable typingTimeout: any = 0;

    readonly name = "ConversationStore";
    readonly rootStore: RootStore;
    readonly pageSize = 20;
    readonly myStorage = window.localStorage;
    private readonly uiStore: UiStore;

    constructor(root: RootStore, uiStore: UiStore) {
        this.rootStore = root;
        this.uiStore = uiStore;
    }

    @action getSelectedConversation = async (conversationId: string) => {
        const result = await getConversationById(conversationId);
        runInAction(() => {
            this.selectedConversation = result.data;
            if (this.selectedConversation && this.selectedConversation.deleted) {
                this.updateConversation({ ...result.data, deleted: false });
                this.conversations.push({ ...result.data, deleted: false });
            }
            this.uiStore.setDisplayNavBar(true);
        });
    };

    @action getConversations = async () => {
        try {
            const result = await getConversations(
                this.conversations.length,
                this.pageSize,
                this.filter,
                this.statusFilter,
                this.statusType
            ).catch((err) => console.log(err, "error====>"));
            runInAction(() => {
                this.conversations = this.sortConversations([...this.conversations, ...result.data.conversations]);
                this.conversations.forEach((conversation) => {
                    this.calculateLastMessageTime(conversation.lastMessageTime, conversation._id);
                });
                this.total = result.data.total;
            });
        } catch (error) {
            console.log("error ===>", error);
        }
    };

    @action onChangeFilter = (filter: string) => {
        if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
        }
        this.filter = filter;
        this.typing = false;
        this.typingTimeout = setTimeout(() => {
            runInAction(() => {
                this.conversations = [];
                this.total = 0;
                this.getConversations();
            });
        }, 500);
    };

    @action.bound setStatusFilter(value: string) {
        this.statusFilter = value;
        runInAction(() => {
            this.conversations = [];
            this.total = 0;
            this.getConversations();
        });
    }
    @action.bound setTypeFilter(value: string) {
        this.statusType = value;
        runInAction(() => {
            this.conversations = [];
            this.total = 0;
            this.getConversations();
        });
    }

    @action setSelectedConversation = async (conversation: IConversation) => {
        this.selectedConversation = conversation;
        this.uiStore.setDisplayNavBar(true);
        if (conversation.unreadMessages > 0) {
            await updateConversation({ ...conversation, unreadMessages: 0 });
            this.updateConversation({ ...conversation, unreadMessages: 0 });
        }
    };

    @action solveConversation = async (conversation: any) => {
        await updateConversation({ ...conversation, resolved: !conversation.resolved });
        runInAction(() => {
            this.selectedConversation = { ...conversation, resolved: !conversation.resolved };
            this.updateConversation({ ...conversation, resolved: !conversation.resolved });
        });
    };

    @action markAsReadConversation = async (conversation: IConversation) => {
        await updateConversation({ ...conversation, unreadMessages: 0, resolved: false });
        runInAction(() => {
            this.selectedConversation = { ...conversation, unreadMessages: 0, resolved: false };
            this.updateConversation({ ...conversation, unreadMessages: 0, resolved: false });
        });
    };

    @action markAsUnreadConversation = async (conversation: IConversation | null) => {
        if (conversation && conversation.unreadMessages === 0) {
            await updateConversation({ ...conversation, unreadMessages: 1, resolved: false });
            runInAction(() => {
                this.selectedConversation = { ...conversation, unreadMessages: 1, resolved: false };
                this.updateConversation({ ...conversation, unreadMessages: 1, resolved: false });
            });
        }
    };

    @action deleteConv = async () => {
        if (this.selectedConversation) {
            await deleteConversation(this.selectedConversation._id);
            runInAction(() => {
                this.conversations = this.conversations.filter((conv) => conv._id !== this.selectedConversation?._id);
                this.selectedConversation = null;
                this.total = this.total - 1;
            });
        }
    };

    @action clearSelectedConversation = () => {
        this.selectedConversation = null;
        this.uiStore.setDisplayNavBar(false);
    };

    @action calculateLastMessageTime(lastMessageDate: Date, conversationId: string) {
        const timeGap = timeGapBetweenCurrentAndGivenDate(lastMessageDate);

        this.conversationsLastMessageTimer[conversationId] = { accurateLastMessageTime: timeGap.timeGapAsString };
    }

    @action addConversation = (conversation: IConversation) => {
        this.conversations = [conversation, ...this.conversations];
        this.conversations.forEach((conversation) => {
            this.calculateLastMessageTime(conversation.lastMessageTime, conversation._id);
        });
    };

    @action updateConversation = (conversation: IConversation) => {
        let index = this.conversations.findIndex((conv) => conv._id === conversation._id);
        console.log("update conversation ====>", conversation.unreadMessages);
        this.conversations[index] = conversation;
        this.conversations = this.sortConversations(this.conversations);
    };

    @action reset = () => {
        this.conversations = [];
        this.selectedConversation = null;
        this.total = 0;
    };

    @action setIsTyping = (data: any) => {
        let index = this.conversations.findIndex((conv) => conv._id === data.conversationId);
        if (index > -1) {
            this.conversations[index].typing = data.typing;
        }
        if (this.selectedConversation && this.selectedConversation._id === data.conversationId) {
            this.selectedConversation.typing = data.typing;
        }
    };

    sortConversations = (conversations: IConversation[]): IConversation[] => {
        return conversations.sort((conv1, conv2) => {
            if (conv1.unreadMessages > 0) {
                if (conv2.unreadMessages > 0)
                    return new Date(conv2.lastMessageTime).getTime() - new Date(conv1.lastMessageTime).getTime();
                return -1;
            }

            //sort resolved
            if (conv1.resolved) {
                if (conv2.resolved)
                    return new Date(conv2.lastMessageTime).getTime() - new Date(conv1.lastMessageTime).getTime();
                return 1;
            }
            //sort read
            if (conv1.unreadMessages === 0 && !conv1.resolved) {
                if (conv2.unreadMessages > 0) return 1;
                else if (!conv2.resolved)
                    return new Date(conv2.lastMessageTime).getTime() - new Date(conv1.lastMessageTime).getTime();
                return -1;
            }

            return new Date(conv2.lastMessageTime).getTime() - new Date(conv1.lastMessageTime).getTime();
        });
    };

    @action setSelectedCovnersationLastMessage = (message: string): void => {
        if (this.selectedConversation) {
            // this.selectedConversation = {...this.selectedConversation, lastMessage: message}
            this.updateConversation({
                ...this.selectedConversation,
                lastMessage: message,
                lastMessageTime: new Date(),
            });
            this.calculateLastMessageTime(new Date(), this.selectedConversation._id);
        }
    };
}
