import { useEffect } from "react";
import { getPages } from "../../../../services/facebook.service";
import { withRouter, useLocation } from "react-router";
import { observer } from "mobx-react-lite";

import { useStores } from "../../../../hooks";
import { error, success } from "../../../../services/toast.service";

const FacebookAccounts = observer((props: any) => {
    const query = new URLSearchParams(useLocation().search);
    const { workspaceStore } = useStores();
    const workspace = workspaceStore.workspaces[0];

    useEffect(() => {
        const getFacebookPages = async () => {
            const token = query.get("token") as string;
            const id = query.get("id") as string;
            const isEdited = query.get("isEdited") as string;
            const socialId = query.get("socialId") as string;
            try {
                const result = await getPages(id, token, isEdited, socialId);
                if (isEdited == "true") {
                    success("Account was updated successfully!");
                    props.history.push({
                        pathname: `workspace/${workspace?._id}/channels`,
                        state: null,
                    });
                } else {
                    props.history.push({
                        pathname: `workspace/${workspace?._id}/channels`,
                        state: result.data.map((account: any) => ({ ...account, picture: account.picture.data.url })),
                    });
                }
            } catch {
                error("There was an error during update of the account");
                props.history.push({
                    pathname: `workspace/${workspace?._id}/channels`,
                    state: null,
                });
            }
        };

        getFacebookPages();
    }, []);

    return null;
});

export default withRouter(FacebookAccounts);
