import React, { useCallback, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { observer } from "mobx-react-lite";
import { useDropzone } from "react-dropzone";
import CardMedia from "@material-ui/core/CardMedia";
import clsx from "clsx";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 150,
            height: 150,
            backgroundColor: "#E6E6E6",
            borderRadius: "100%",
            position: "relative",
        },
        imageWrapper: {
            width: 150,
            height: 150,
            borderRadius: "100%",
        },
        deleteIcon: {
            position: "absolute",
            top: "41%",
            left: "41%",
            cursor: "pointer",
        },
        loader: {
            position: "absolute",
            top: "36%",
            left: "36%",
        },
        photoIcon: {
            position: "absolute",
            right: "1%",
            cursor: "pointer",
            backgroundColor: "white",
            borderRadius: "50%",
            top: "11%",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            display: "flex",
        },
        dropzone: {
            outline: "none",
            display: "grid",
            justifyContent: "center",
            width: "min-content",
        },
        displayNone: {
            display: "none",
        },
    })
);

const ProfilePicture = observer((props: any) => {
    const classes = useStyles();
    const [onHover, setOnHover] = useState(false);
    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        if (acceptedFiles.length > 0) {
            props.uploadImage(acceptedFiles);
        }
        if (fileRejections && fileRejections.length > 0) {
            console.error("some files are not accepted");
        }
    }, []);
    const { acceptedFiles, getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
        onDrop,
        accept: "image/*",
    });
    return (
        <div {...getRootProps({ className: classes.dropzone })}>
            <div className={classes.root} onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
                {props.isFetching ? (
                    <CircularProgress className={classes.loader} />
                ) : (
                    <>
                        <CardMedia image={props.image} className={classes.imageWrapper} />
                        <div
                            className={clsx(classes.photoIcon, { [classes.displayNone]: !onHover })}
                            onClick={(e) => {
                                e.stopPropagation();
                                props.removeCurrentPicture();
                            }}
                        >
                            <HighlightOffIcon />
                        </div>
                        <div className={clsx(classes.deleteIcon)}>
                            <input {...getInputProps()} />
                            <PhotoCameraIcon />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
});
export default ProfilePicture;
