import { uploadAxiosInstance } from "../utils/axios.instance";
import { IImages } from "../stores/message/MessageStore";

export const uploadFiles = async (mediaToUpload: Array<IImages>) => {
    const imageFormData = new FormData();
    const videoFormData = new FormData();
    const fileFormData = new FormData();
    let hasImages,
        hasVideos,
        hasFiles = false;
    const promises: any[] = [];
    mediaToUpload.forEach((media) => {
        if (media.type.includes("IMAGE")) {
            hasImages = true;
            imageFormData.append(
                "images",
                media.file as Blob,
                (media.name + "_" + media.mediaId) as string | undefined
            );
        } else if (media.type.includes("VIDEO")) {
            hasVideos = true;
            videoFormData.append(
                "videos",
                media.file as Blob,
                (media.name + "_" + media.mediaId) as string | undefined
            );
        } else {
            hasFiles = true;
            fileFormData.append("files", media.file as Blob, (media.name + "_" + media.mediaId) as string | undefined);
        }
    });
    if (hasImages) {
        promises.push((await uploadImage(imageFormData)) as any);
    }
    if (hasVideos) {
        promises.push((await uploadVideo(videoFormData)) as any);
    }
    if (hasFiles) {
        promises.push((await uploadFile(fileFormData)) as any);
    }

    try {
        const res = await Promise.all(promises);

        if (res.length === 1) {
            return res[0].data;
        } else if (res.length === 2) {
            return [...res[0].data, ...res[1].data];
        } else if (res.length === 3) {
            return [...res[0].data, ...res[1].data, ...res[2].data];
        }
        return [];
    } catch (error) {
        console.log(error, "error ======>");
    }
};

export const uploadImage = async (formData: FormData) => {
    const response = await uploadAxiosInstance({
        url: "image",
        method: "POST",
        data: formData,
        headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
    return response.data;
};

export const uploadVideo = async (formData: FormData) => {
    const response = await uploadAxiosInstance({
        url: "video",
        method: "POST",
        data: formData,
        headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });

    return response.data;
};

export const uploadFile = async (formData: FormData) => {
    const response = await uploadAxiosInstance({
        url: "file",
        method: "POST",
        data: formData,
        headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": "multipart/form-data",
        },
    });

    return response.data;
};
