import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Email, Lock } from "@material-ui/icons";
import { TextField, InputAdornment, Checkbox, Button } from "@material-ui/core";
import momentTz from "moment-timezone";
import { observer } from "mobx-react-lite";
import clsx from "clsx";

import { setLocalStorage } from "../../../services/localStorageservice";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { useStores } from "../../../hooks";
import { logos, banners } from "../../../utils/contants";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: "100vh",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "3.5fr 5fr",
        [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "1fr",
        },
    },
    carousel: {
        position: "relative",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    formContainer: {
        display: "grid",
        gridTemplateRows: "1fr 4fr",
        gap: "3em",
        padding: "60px 100px",
        [theme.breakpoints.down("md")]: {
            justifyItems: "center",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "30px",
        },
    },
    logo: {
        backgroundImage: `url(${logos.kiboBlack})`,
        backgroundRepeat: "no-repeat",
        width: "200px",
        height: "80px",
        [theme.breakpoints.down("xs")]: {
            backgroundImage: `url(${logos.kiboBlackMobile})`,
            width: "120px",
            height: "45px",
        },
    },
    title: {
        fontSize: "28px",
        fontWeight: 700,
        lineheight: "150%",
        color: theme.palette.primary.main,
    },
    input: {
        background: theme.palette.background.default,
    },
    placeholder: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    field: {
        "& fieldset": {
            border: "none",
        },
        "& input": {
            fontFamily: "SOURCE SANS PRO",
        },
        "&: hover": {
            border: "none",
        },
        marginTop: theme.spacing(1),
    },
    loggedInWrapper: {
        color: "#575757",
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
    },
    signInBtn: {
        border: "1px solid",
        boxSizing: "border-box",
        borderRadius: "30px",
        fontWeight: 700,
        textTransform: "unset",
        width: "100%",
    },
    btnDefault: {
        display: "flex",
        color: "white",
        fontWeight: 700,
        padding: "5px",
        alignItems: "center",
        borderRadius: "5px",
        fontSize: "14px",
        lineHeight: "100%",
        cursor: "pointer",
    },
    googleBtn: {
        background: "#3367D6",
    },
    googleLogo: {
        background: "white",
        padding: "10px",
        borderRadius: "5px",
        display: "flex",
        marginRight: "10px",
    },
    appleBtn: {
        background: "black",
        padding: "10px",
    },
    appleLogo: {
        marginRight: "10px",
    },
    buttonContainer: {
        display: "grid",
        "grid-template-columns": "1fr 1fr",
        gap: "10px",
    },
    dividerContainer: {
        display: "grid",
        "grid-template-columns": "1fr 1fr 1fr",
        gap: "20px",
        alignItems: "center",
        fontWeight: "bold",
        fontSize: "14px",
        color: "#6D6D6D",
    },
    hrContainer: {
        width: "100%",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    spacing: {
        margin: "20px 0",
    },
    link: {
        textDecoration: "none",
        fontSize: "16px",
        color: theme.palette.primary.main,
        fontWeight: 700,
        paddingLeft: "10px",
    },
    margin10: {
        marginBottom: "10px",
    },
    illustration: {
        backgroundImage: `url(${banners.login})`,
        backgroundRepeat: "no-repeat",
        height: "644px",
        width: "829px",
        position: "absolute",
        right: 0,
        bottom: 0,
    },
    roundOvalTop: {
        backgroundImage: `url(${banners.roundOvalTop})`,
        backgroundRepeat: "no-repeat",
        height: "150px",
    },
}));

interface State {
    email: string;
    password: string;
    showPassword: boolean;
}

const SignIn = observer((props: any) => {
    const classes = useStyles();
    const { userStore, workspaceStore, tourStore } = useStores();
    const workspace = workspaceStore.workspaces[0];
    setLocalStorage("timezone", momentTz.tz.guess());

    const [values, setValues] = useState<State>({
        email: "",
        password: "",
        showPassword: false,
    });

    if (userStore.isAuthenticated) {
        return <Redirect to={{ pathname: "/workspace" }} />;
    }

    const signIn = async () => {
        await userStore.signIn(values.email, values.password);

        if (userStore.isAuthenticated) {
            if (workspace && workspace._id) {
                props.history.push(`workspace/${workspace._id}/inbox/`);
            } else {
                props.history.push(`/workspace`);
                if (tourStore.shouldOpenTour()) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1);
                }
            }
        }
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        signIn();
    };

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const loginWithGoogle = () => {
        setLocalStorage("userProfile", true);
        window.open("https://google.kibo.ai:4300/v1/google/authenticate", "_self");
    };

    return (
        <div className={classes.root}>
            <div className={classes.formContainer}>
                <div className={classes.logo} />
                <div>
                    <div className={clsx(classes.title, classes.spacing)}>Log in</div>
                    {/* <div className={clsx(classes.buttonContainer, classes.spacing)}>
                        <div onClick={loginWithGoogle} className={clsx(classes.googleBtn, classes.btnDefault)}>
                            <div className={classes.googleLogo}>
                                <img src={icons.google} />
                            </div>
                            <div>Sign in with Google</div>
                        </div>
                        <div className={clsx(classes.appleBtn, classes.btnDefault)}>
                            <div className={classes.appleLogo}>
                                <img src={icons.apple} />
                            </div>
                            <div>Sign in with Apple</div>
                        </div>
                    </div> */}
                    {/* <div className={clsx(classes.dividerContainer, classes.spacing)}>
                        <hr className={classes.hrContainer} />
                        <div>Or log in with email</div>
                        <hr className={classes.hrContainer} />
                    </div> */}
                    <form onSubmit={onFormSubmit}>
                        <div className={classes.spacing}>
                            <div className={classes.spacing}>
                                <span className={classes.placeholder}>Email</span>
                                <TextField
                                    className={classes.field}
                                    required={true}
                                    onChange={handleChange("email")}
                                    placeholder="name@domain.com"
                                    type="email"
                                    InputProps={{
                                        className: classes.input,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Email fontSize="small" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </div>
                            <div>
                                <span className={classes.placeholder}>Password</span>
                                <TextField
                                    className={classes.field}
                                    required={true}
                                    onChange={handleChange("password")}
                                    placeholder="At least 8 characters"
                                    type={values.showPassword ? "text" : "password"}
                                    InputProps={{
                                        className: classes.input,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Lock fontSize="small" onClick={handleClickShowPassword} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                    variant="outlined"
                                />
                            </div>
                            {/* <div className={clsx(classes.loggedInWrapper, classes.spacing)}>
                                {" "}
                                <Checkbox
                                    checked={false}
                                    // onChange={handleChange}
                                    name="checkedB"
                                    color="primary"
                                />{" "}
                                Keep me logged in
                            </div> */}
                            <div className={classes.spacing}>
                                <Button className={classes.signInBtn} variant="outlined" color="primary" type="submit">
                                    Log in
                                </Button>
                            </div>
                            {/* <div className={clsx(classes.container, classes.spacing)}>
                                <div className={classes.margin10}>
                                    {"Don't have an account?"}
                                    <Link to="sign-up" className={classes.link}>
                                        <span>{"Sign Up"}</span>
                                    </Link>
                                </div>
                                <Link className={classes.link} to="forgot-password">
                                    Forgot password?
                                </Link>
                            </div> */}
                        </div>
                    </form>
                </div>
            </div>
            <div className={classes.carousel}>
                <div className={classes.roundOvalTop} />
                <div className={classes.illustration} />
            </div>
        </div>
    );
});

export default withRouter(SignIn);
