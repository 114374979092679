import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { useTheme, Theme } from "@material-ui/core/styles";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";
import { getLocalStorage, setLocalStorage } from "../../../services/localStorageservice";

import ConversationList from "./conversations/ConversationList";
import UserProfile from "./messages/UserProfile";
import MessageList from "./messages/MessageList";
import { useStores } from "../../../hooks";
import { withRouter } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            overflow: "hidden",
        },
        conversationList: {
            overflow: "hidden",
            display: "flex",
            width: "24%",
            [theme.breakpoints.down("sm")]: {
                width: "100%",
            },
            borderTopLeftRadius: "15px",
        },
        display: {
            display: "none",
        },
        messageWrapper: {
            borderTopLeftRadius: 12,
            width: "76%",
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                borderTopLeftRadius: "unset",
            },
            [theme.breakpoints.up("md")]: {
                marginLeft: "5px",
                marginRight: theme.spacing(2.5),
            },
        },
        messageRoot: {
            marginRight: "5px",
        },
    })
);
const Chat = observer((props: any) => {
    const theme = useTheme();
    const classes = useStyles();
    const { conversationStore, messageStore } = useStores();
    const desktop = useMediaQuery(theme.breakpoints.up("md"));
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const selectedConversationId = props.match.params.conversationId;
        if (selectedConversationId) {
            conversationStore.getSelectedConversation(selectedConversationId);
        }
    }, []);

    const toggleUserProfile = () => {
        setOpen(!open);
        setLocalStorage("userProfile", !open);
    };

    useEffect(() => {
        let isOpen = getLocalStorage("userProfile");
        if (!desktop) return setOpen(false);
        if (isOpen === "true") {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [desktop]);

    return (
        <Box height="100%" display="flex" width={1} className={classes.root}>
            <Box display="flex" flexDirection="row" height="100%" width={1}>
                <Box
                    className={clsx(
                        { [classes.display]: conversationStore.selectedConversation?._id && !desktop },
                        classes.conversationList
                    )}
                >
                    <ConversationList />
                </Box>
                {conversationStore.selectedConversation?._id !== "" ? (
                    <Box
                        display="flex"
                        width={1}
                        className={clsx(
                            { [classes.display]: !conversationStore.selectedConversation?._id && !desktop },
                            classes.messageWrapper
                        )}
                    >
                        <Box
                            width={1}
                            className={clsx({ [classes.display]: open && !desktop, [classes.messageRoot]: desktop })}
                        >
                            <MessageList toggleUserProfile={toggleUserProfile} />
                        </Box>
                        <UserProfile open={open} toggleUserProfile={toggleUserProfile} />
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
});

export default withRouter(Chat);
