import React, { useRef, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import Divider from "@material-ui/core/Divider";
import { observer } from "mobx-react-lite";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import SettingsIcon from "@material-ui/icons/Settings";

import { useStores } from "../../hooks";
import { useCallbackOutsideElement } from "../../utils/helperFunctions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
        },
        profileIcon: {
            width: "20px",
            height: "20px",
        },
        icon: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            marginRight: theme.spacing(1),
        },
        iconBtn: {
            backgroundColor: "white",
            borderRadius: 5,
            padding: 6,
            "&:hover": {
                backgroundColor: "white",
            },
        },
        divider: {
            height: "2px",
            background: theme.palette.background.default,
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
        listWrapper: {
            position: "absolute",
            right: "2%",
            top: "5%",
            background: "#FFFFFF",
            padding: theme.spacing(2),
            zIndex: 999,
            boxShadow: "0px 9px 46px rgba(0, 0, 0, 0.12), 0px 24px 38px rgba(0, 0, 0, 0.14)",
            borderRadius: "5px",
        },

        listElement: {
            cursor: "pointer",
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            color: theme.palette.secondary.main,
        },
    })
);

declare global {
    interface Window {
        nolt: any;
    }
}

const Managment = observer((props: { history: any }) => {
    const { uiStore, userStore } = useStores();
    const classes = useStyles();
    const wrapperRef = useRef<HTMLHeadingElement>(null);

    //useCallbackOutsideElement(wrapperRef, () => uiStore.setDisplayAccountDropdown(false));
    return (
        <div className={classes.root}>
            <IconButton
                className={classes.iconBtn}
                onClick={() => uiStore.setDisplayAccountDropdown(!uiStore.displayAccountDropdown)}
            >
                <MenuIcon color="secondary" className={clsx(classes.profileIcon)} />
            </IconButton>
            <div ref={wrapperRef}>
                {uiStore.displayAccountDropdown && (
                    <div className={classes.listWrapper}>
                        <Link className={classes.listElement} to="/settings">
                            <SettingsIcon color="secondary" className={classes.icon} /> Settings
                        </Link>
                        <Divider className={classes.divider} />
                        <div
                            className={classes.listElement}
                            onClick={async () => {
                                await userStore.logoutUser();
                                props.history.push("/sign-in");
                            }}
                        >
                            <ExitToAppIcon className={classes.icon} /> Log out
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});

export default withRouter(Managment);
