import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";

import { IChannel } from "../../../../stores/ChannelStore";
import Modal from "../../../common/Modal";
import AccountProvider from "../AccountProvider";

const useStyles = makeStyles((theme) => ({
    backgroundColor: {
        background: "#FFF",
    },
    container: {
        minWidth: "320px",
        display: "grid",
        gap: "24px",
        [theme.breakpoints.down("sm")]: {
            minWidth: "250px",
        },
    },
    title: {
        color: "#6D6D6D",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2.5),
    },
    displayColumn: {
        display: "flex",
        flexDirection: "column",
    },
    input: {
        "& input": {
            background: theme.palette.background.default,
            border: "1px solid #fff",
            fontFamily: "SOURCE SANS PRO",
            color: "#3D3D3D",
            width: "100%",
            paddingLeft: theme.spacing(1),
        },
        "&: hover": {
            border: "none",
        },
        "& fieldset": {
            border: "none",
        },
    },
    placeholder: {
        fontSize: 14,
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(1),
    },
}));

const TwitterCredentials = (props: {
    open: boolean;
    setOpen: Function;
    isEdited: Boolean;
    selectedAccount: IChannel | null | undefined;
}) => {
    const classes = useStyles();
    const socialId = props.selectedAccount && props.selectedAccount.socialId;

    const [state, setState] = useState({
        consumerKey: "",
        consumerSecret: "",
        devEnvironment: "",
    });

    const handleChange = (name: string, value: string) => {
        setState({ ...state, [name]: value });
    };

    const createTwitterChannel = async () => {
        window.open(
            `${process.env.REACT_APP_TWITTER_SERVICE}/twitter/login/${state.consumerKey}/${state.consumerSecret}/${state.devEnvironment}/${props.isEdited}/${socialId}`,
            "_self"
        );
    };

    return (
        <Modal
            open={props.open}
            onClose={() => props.setOpen(false)}
            handleConfirm={() => createTwitterChannel()}
            title={"Twitter Credentials"}
            disableBackdropClick={true}
        >
            <AccountProvider provider="twitter" />
            <div className={classes.title}>Enter the credentials to add your Twitter account</div>
            <div className={classes.container}>
                <div className={classes.displayColumn}>
                    <span className={classes.placeholder}>Twitter Consumer Key*</span>
                    <InputBase
                        id="outlined-basic"
                        className={classes.input}
                        required={true}
                        onChange={(event) => handleChange("consumerKey", event.target.value)}
                    />
                </div>
                <div className={classes.displayColumn}>
                    <span className={classes.placeholder}>Twitter Consumer Secret*</span>
                    <InputBase
                        id="outlined-basic"
                        className={classes.input}
                        required={true}
                        onChange={(event) => handleChange("consumerSecret", event.target.value)}
                    />
                </div>
                <div className={classes.displayColumn}>
                    <span className={classes.placeholder}>Twitter Environment*</span>
                    <InputBase
                        id="outlined-basic"
                        className={classes.input}
                        required={true}
                        onChange={(event) => handleChange("devEnvironment", event.target.value)}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default TwitterCredentials;
