import { observable, action, runInAction, computed, reaction } from "mobx";
import { Guid } from "guid-typescript";

import { StoreClassInterface, RootStore } from "../RootStore";
import { IImages, IMessage, INewMessage } from "./MessageStore";
import { addNewMessage, getMessages } from "../../services/message.service";
import { uploadFiles } from "../../services/upload.service";

export interface INewReply {
    [key: string]: INewMessage;
}

export interface IComments {
    [key: string]: [IMessage];
}

export class CommentStore implements StoreClassInterface {
    @observable replies: INewReply = {};
    @observable selectedCommentId: string | null = null;
    @observable tempUploadingImages: IImages[] = [];
    @observable comments: IComments[] = [];
    @observable total: number = 0;
    @observable isFetching: boolean = false;
    readonly name = "CommentStore";
    readonly rootStore: RootStore;
    readonly pageSize = 20;

    constructor(root: RootStore) {
        this.rootStore = root;
    }

    @action setSelectedCommentId(id: string) {
        this.selectedCommentId = id;
    }

    @action getCommentsByParentId = async (skip = 0, parentId: string) => {
        const convId = this.rootStore.messageStore.currentConversationId;
        this.isFetching = true;
        if (convId) {
            let res = await getMessages(convId, this.pageSize, skip, parentId);
            runInAction(() => {
                this.total = res.data.total;
                if (this.comments[parentId]) {
                    if (skip === 0) return;
                    this.comments[parentId] = [...res.data.messages.reverse(), ...this.comments[parentId]];
                    return;
                }
                this.comments[parentId] = [...res.data.messages.reverse()];
                this.isFetching = false;
            });
        }
    };

    @computed get allComments(): IComments[] {
        return this.comments;
    }
    @action setReplyBody = (text: string, replyId: string) => {
        if (this.replies[replyId]) {
            this.replies[replyId].text = text;
        } else {
            this.replies[replyId] = {
                text: text.trim(),
                media: [],
                replyMessage: null,
            };
        }
    };

    @action setCommentMedia = (media: IImages[], replyId: string) => {
        if (this.replies[replyId]) {
            this.replies[replyId].media = media;
        } else {
            this.replies[replyId] = {
                text: "",
                media: [],
                replyMessage: null,
            };
        }
    };

    @action removeMediaFromMessage(currentMedia: IImages, replyId: string) {
        let index = this.replies[replyId].media.findIndex((media) => media.mediaId == currentMedia.mediaId);
        this.replies[replyId].media.splice(index, 1);
    }

    @action getReplyBody(replyId: string) {
        if (!this.replies[replyId]) return null;
        return this.replies[replyId].text.trim();
    }

    @action getReplyMedia(replyId: string) {
        if (!this.replies[replyId]) return [];
        return this.replies[replyId].media;
    }

    @action updateComments(data: IComments, commentId: string) {
        this.comments[commentId] = [...this.comments, data];
    }

    @action postComment = async (message: INewMessage, parentId: string) => {
        let res = await addNewMessage({ ...message, type: "COMMENT", parentId });
        runInAction(() => {
            this.clearComposer(parentId as string);
            this.updateComments(res.data, res.data._id);
        });
    };

    @action clearComposer = (replyId: string) => {
        this.setReplyBody("", replyId);
        this.setCommentMedia([], replyId);
    };

    @action uploadFiles = async (files: Array<File>, replyId: string) => {
        let imagesToUpload = files.map((file) => ({
            localUrl: window.URL.createObjectURL(file),
            file: file,
            loading: true,
            url: "",
            name: file.name,
            mediaId: Guid.create(),
            type:
                file.type.split("/")[0].toUpperCase() === "IMAGE" || file.type.split("/")[0].toUpperCase() === "VIDEO"
                    ? file.type.split("/")[0].toUpperCase()
                    : "FILE",
        }));
        this.tempUploadingImages = [...this.replies[replyId].media, ...imagesToUpload];
        this.setCommentMedia(this.tempUploadingImages, replyId);
        let response = await uploadFiles(imagesToUpload);
        runInAction(() => {
            response.forEach((res: any) => {
                let resMediaId = (res.metadata && res.metadata.mediaId) || res.transforms[0].metadata.mediaId;
                let index = this.replies[replyId].media.findIndex((image) => image.mediaId == resMediaId);

                if (index > -1) {
                    if (res.contentType && res.contentType.split("/")[0] === "video") {
                        this.replies[replyId].media[index].thumbnail = res.thumbnail.Location;
                        this.replies[replyId].media[index].url = res.location;
                    } else {
                        this.replies[replyId].media[index].url = res.location || res.transforms[0].location;
                    }
                    this.replies[replyId].media[index].loading = false;
                    this.replies[replyId].media[index].mediaId = resMediaId;
                }
            });
            this.tempUploadingImages = [];
        });
    };
}
