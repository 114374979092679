import React, { useEffect, useState } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { useStores } from "../../../../hooks";
import ContactDetails from "./Details";
import CompanyInfo from "./CompanyInfo";
import Data from "./Data";
import Conversations from "./Conversations";
import Notes from "./Notes";
import PersonalData from "./PersonalData";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        overflow: "auto",
        [theme.breakpoints.down("sm")]: {
            display: "grid !important",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    column: {
        display: "flex",
        flexDirection: "column",
        "&:nth-child(1)": {
            marginRight: "1em",
        },
        height: "fit-content",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginRight: "0 !important",
        },
    },
    actions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
}));

const Profile = observer((props: any) => {
    const contactId = props.match.params.id;
    const { contactStore } = useStores();
    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        contactStore.getContact(contactId);
    }, []);

    const goBack = () => {
        props.history.goBack();
    };

    return (
        <div className={classes.container}>
            {mobile && (
                <div className={classes.actions}>
                    <IconButton color="secondary" onClick={() => goBack()}>
                        <ArrowBackIcon />
                    </IconButton>
                    {/* <IconButton color="secondary">
                        <MoreVertIcon />
                    </IconButton> */}
                </div>
            )}
            <div className={classes.column}>
                <PersonalData />
                <ContactDetails />
                <CompanyInfo />
            </div>
            <div className={classes.column}>
                <Data />
                <Conversations />
                <Notes />
            </div>
        </div>
    );
});

export default withRouter(Profile);
