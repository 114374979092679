import React from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import { Typography, Box, Badge, IconButton } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withRouter } from "react-router-dom";

import { useStores } from "../../../../hooks";
import Avatar from "@material-ui/core/Avatar";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Card from "./Card";
import { badgeProvider } from "../../../../utils/helperFunctions";
import { warning } from "../../../../services/toast.service";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "center",
        },
    },
    contactPicture: {
        width: "100px",
        height: "100px",
    },
    contactNameContainer: {
        marginLeft: theme.spacing(2.5),
        marginRight: theme.spacing(2.5),
        display: "flex",
        alignItems: "baseline",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            alignItems: "center",
            margin: "16px 0 !important",
        },
    },
    contactName: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: 140,
    },
    actionButton: {
        borderRadius: "32px",
        border: "1px solid #1085F0",
        fontSize: "12px",
        fontWeight: 700,
    },
    iconButton: {
        padding: 0,
    },
    actionsContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
            justifyContent: "flex-end",
            maxWidth: 140,
        },
    },
    dataContainer: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
}));

const PersonalData = observer((props: any) => {
    const { contactStore } = useStores();
    const { selectedContact } = contactStore;
    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const sendMessage = (contact: any) => {
        const conversationId = contact.conversation ? contact.conversation._id : null;

        if (conversationId) {
            props.history.push(`/workspace/${contact.workspaceId}/inbox/${conversationId}`);
        } else {
            warning("There is no conversation with this contact!");
        }
    };

    return (
        <Card>
            <div className={classes.root}>
                <div className={classes.dataContainer}>
                    <Badge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        badgeContent={badgeProvider(selectedContact?.provider || "", true)}
                    >
                        <Avatar src={selectedContact?.picture} className={classes.contactPicture} />
                    </Badge>
                    <div className={classes.contactNameContainer}>
                        <Typography variant="h6" component="h6" className={classes.contactName}>
                            <span style={{ fontWeight: 700 }}>{selectedContact?.fullName}</span>
                        </Typography>
                        <Typography variant="subtitle1" className={classes.contactName}>
                            {selectedContact?.address ? selectedContact.address : ""}
                        </Typography>
                    </div>
                </div>
                <div className={classes.actionsContainer}>
                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                        {/* {!mobile && (
                            <IconButton color="secondary" className={classes.iconButton}>
                                <MoreVertIcon />
                            </IconButton>
                        )} */}
                    </Box>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.actionButton}
                        onClick={() => {
                            sendMessage(selectedContact);
                        }}
                    >
                        Message
                    </Button>
                </div>
            </div>
        </Card>
    );
});

export default withRouter(PersonalData);
