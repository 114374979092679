import { userAxiosInstance, emailAxiosInstance } from "../utils/axios.instance";
import { IUser } from "../stores/UserStore";

export const signUp = async (value: IUser) => {
    const response = await userAxiosInstance({
        method: "POST",
        url: "register",
        data: {
            fullName: value.fullName,
            email: value.email,
            password: value.password,
        },
    });

    return response.data;
};

export const signIn = async (email: string, password: string) => {
    const response = await userAxiosInstance({
        method: "post",
        url: "login",
        data: {
            email: email,
            password: password,
        },
    });
    return response.data;
};

export const isUserHuman = async (value: string | null) => {
    const response = await userAxiosInstance({
        method: "POST",
        url: "captcha",
        data: {
            siteKey: value,
        },
    });

    return response.data;
};

export const confirmEmail = async (token: string) => {
    const response = await userAxiosInstance({
        method: "POST",
        url: "confirm-email",
        data: {
            token: token,
        },
    });
    return response.data;
};

export const changePassword = async (password: string, resetToken: string) => {
    const response = await userAxiosInstance({
        method: "POST",
        url: "reset-password",
        data: {
            password: password,
            resetToken: resetToken,
        },
    });

    return response.data;
};

export const resetPasswordEmail = async (email: string) => {
    const response = await emailAxiosInstance({
        method: "POST",
        url: "change-password",
        data: {
            email: email,
        },
    });

    return response.data;
};

export const getUserByIdAndToken = async (id: string, token: string) => {
    const response = await userAxiosInstance({
        method: "GET",
        url: `${id}/${token}`,
    });

    return response.data;
};

export const updateUserProfile = async (user: IUser) => {
    const response = await userAxiosInstance({
        method: "PUT",
        url: `${user._id}`,
        data: user,
    });
    return response.data;
};

export const changeEmail = async (id: string, newEmail: string) => {
    const response = await userAxiosInstance({
        method: "POST",
        url: `/change-email/${id}`,
        data: {
            newEmail: newEmail,
        },
    });

    return response.data;
};

export const confirmNewEmail = async (token: string) => {
    const response = await userAxiosInstance({
        method: "POST",
        url: "/confirm-new-email",
        data: {
            token: token,
        },
    });

    return response.data;
};

export const logout = async (userId: string) => {
    const response = await userAxiosInstance({
        method: "POST",
        url: "/logout",
        data: {
            userId: userId,
        },
    });

    return response.data;
};

export const deleteUser = async (userId: string) => {
    const response = await userAxiosInstance({
        method: "DELETE",
        url: `/${userId}`,
    });

    return response.data;
};

export const updatePassword = async (userId: string, currentPassword: string, password: string) => {
    const response = await userAxiosInstance({
        method: "PUT",
        url: `/update-password/${userId}`,
        data: {
            currentPassword,
            password,
        },
    });

    return response.data;
};

export const createTemporaryUser = async (email: string) => {
    const response = await userAxiosInstance({
        method: "POST",
        url: "/temporary-user",
        data: {
            email,
        },
    });

    return response.data;
};

export const activateTemporaryUser = async (email: string) => {
    const response = await userAxiosInstance({
        method: "PUT",
        url: `/activate-temporary-user/${email}`,
        data: {},
    });

    return response.data;
};
