import { useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";

import { confirmNewEmail } from "../../../services/user.service";
import { deleteLocalStorage } from "../../../services/localStorageservice";

const ConfirmNewEmail = (props: any) => {
    const query = new URLSearchParams(useLocation().search);
    const confirmToken = query.get("confirm-token") as string;

    const sendConfirmationEmail = async () => {
        const result = await confirmNewEmail(confirmToken);
        if (result.status === "success") {
            deleteLocalStorage("user");
            deleteLocalStorage("token");
            props.history.push("/sign-in");
        } else {
            props.history.push("/sign-up");
        }
    };

    useEffect(() => {
        sendConfirmationEmail();
    });

    return null;
};

export default withRouter(ConfirmNewEmail);
