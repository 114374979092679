import { observable, action, runInAction, computed, reaction } from "mobx";
import { Guid } from "guid-typescript";

import { StoreClassInterface, RootStore } from "../RootStore";
import { addNewMessage, getMessages } from "../../services/message.service";
import { uploadFiles } from "../../services/upload.service";
import { markAsRead } from "../../services/message.service";
import { getLocalStorage } from "../../services/localStorageservice";
import { setIsTyping } from "../../services/conversation.service";

export interface IMessage {
    conversationId?: string;
    createdAt?: Date;
    received?: boolean;
    senderId?: string;
    text?: string;
    type: string;
    updatedAt?: Date;
    _id?: string;
    files?: Array<File>;
    url: Array<string>;
    errorText?: string;
    thumbnail?: string;
    replyMessageId?: null | string;
    replyMessage?: null | IMessage;
    errorMessage?: string;
    referenceId?: string;
    provider?: string;
    read?: boolean;
    isTyping?: boolean;
    isEdited?: boolean;
    userId: string;
    childrenSize?: number;
}

export interface IImages {
    url: string;
    loading: boolean;
    localUrl: string;
    type: string;
    name: string;
    thumbnail?: string;
    mediaId?: Guid;
    file?: File;
}

export interface INewMessage {
    text: string;
    media: IImages[];
    replyMessage: null | IMessage;
}

export interface IConversationMessages {
    [key: string]: INewMessage;
}

export enum MessagePosition {
    FIRST = "FIRST",
    MIDDLE = "MIDDLE",
    LAST = "LAST",
    NONE = "NONE",
}

export class MessageStore implements StoreClassInterface {
    @observable messages: IMessage[] = [];
    @observable total: number = 0;
    @observable tempUploadingImages: IImages[] = [];
    @observable newMessages: IConversationMessages = {};
    @observable imagesToPreview: { images: IImages[]; index: number } = { images: [], index: -1 };
    @observable deliveredMessage: number = this.messages.length - 1;
    @observable messageToEditId: string | null = null;
    @observable isTyping: boolean = false;
    @observable isFetchingMessages: boolean = false;
    readonly name = "MessageStore";
    readonly rootStore: RootStore;
    readonly pageSize = 20;

    constructor(root: RootStore) {
        this.rootStore = root;
        reaction(
            () => this.rootStore.conversationStore.selectedConversation?._id,
            () => {
                this.reset();
                this.getMessagesByConversationId();
            }
        );
    }

    @action getMessagesByConversationId = async (skip = 0) => {
        if (this.currentConversationId) {
            this.isFetchingMessages = true;
            let res = await getMessages(this.currentConversationId, this.pageSize, skip, null);
            runInAction(() => {
                this.messages = [...this.messages, ...res.data.messages];
                this.total = res.data.total;
                this.isFetchingMessages = false;
            });
        }
        this.setMessagesAsRead();
    };

    @action.bound handleTyping(value: boolean) {
        this.isTyping = value;
        setIsTyping(this.rootStore.messageStore.currentConversationId, this.isTyping);
        this.rootStore.messageStore.setMessagesAsRead();
    }

    @action async setMessagesAsRead() {
        let unreadMessages = this.getUnreadMessages();
        if (unreadMessages.length > 0) {
            await markAsRead(this.getUnreadMessages(), this.rootStore.conversationStore.selectedConversation?._id);
            this.messages.forEach((msg, i) => {
                if (unreadMessages.includes(msg._id)) {
                    this.messages[i].read = true;
                }
            });
        }
    }
    @action getUnreadMessages = (): (string | undefined)[] => {
        let unreadMessages = this.messages.filter((msg) => !msg.read && msg.received);
        let unreadMsgIds = unreadMessages.map((message) => message._id);
        return unreadMsgIds;
    };

    @action addMessage = (message: IMessage) => {
        let index = -1;
        if (message.referenceId && message._id && !message.isEdited) {
            index = this.messages.findIndex((msg) => msg.referenceId === message.referenceId);
        } else {
            index = this.messages.findIndex((msg) => msg._id === message._id);
        }
        if (index > -1) {
            this.messages[index] = message;
        } else {
            this.messages = [message, ...this.messages];
        }
    };

    @action setReplyMessage = (message: null | IMessage) => {
        this.newMessages[this.currentConversationId].replyMessage = message;
    };

    @action removeDeletedMessage = (messageId: string): void => {
        this.messages = this.messages.filter((msg) => msg._id !== messageId);
        this.total = this.total - 1;
    };
    @action uploadFiles = async (files: Array<File>) => {
        const conversationId = this.currentConversationId;
        let imagesToUpload = files.map((file) => ({
            localUrl: window.URL.createObjectURL(file),
            file: file,
            loading: true,
            url: "",
            name: file.name,
            mediaId: Guid.create(),
            type:
                file.type.split("/")[0].toUpperCase() === "IMAGE" || file.type.split("/")[0].toUpperCase() === "VIDEO"
                    ? file.type.split("/")[0].toUpperCase()
                    : "FILE",
        }));
        this.tempUploadingImages = [...this.newMessages[conversationId].media, ...imagesToUpload];
        this.setMessageMedia(this.tempUploadingImages, conversationId);
        let response = await uploadFiles(imagesToUpload);
        runInAction(() => {
            response.forEach((res: any) => {
                let resMediaId = (res.metadata && res.metadata.mediaId) || res.transforms[0].metadata.mediaId;
                let index = this.newMessages[this.currentConversationId].media.findIndex(
                    (image) => image.mediaId == resMediaId
                );

                if (index > -1) {
                    if (res.contentType && res.contentType.split("/")[0] === "video") {
                        this.newMessages[conversationId].media[index].thumbnail = res.thumbnail.Location;
                        this.newMessages[conversationId].media[index].url = res.location;
                    } else {
                        this.newMessages[conversationId].media[index].url = res.location || res.transforms[0].location;
                    }
                    this.newMessages[conversationId].media[index].loading = false;
                    this.newMessages[conversationId].media[index].mediaId = resMediaId;
                }
            });
            this.tempUploadingImages = [];
        });
    };

    @action setMessageToEdit(value: string | null) {
        this.messageToEditId = value;
    }

    @action sendMessage = async (
        conversationId: string,
        text: string,
        files: Array<IImages> | any,
        replyMessage: null | undefined | IMessage,
        provider: string,
        _id: string | null | undefined = null
    ) => {
        try {
            const user = JSON.parse(getLocalStorage("user") as string);
            if (text.trim()) {
                let newMessage: IMessage = {
                    conversationId: conversationId,
                    text: text,
                    replyMessageId: (replyMessage && replyMessage._id) || null,
                    replyMessage: replyMessage,
                    received: false,
                    type: "TEXT",
                    url: [""],
                    referenceId: Date.now().toString(),
                    provider: provider,
                    userId: user._id,
                    // _id: _id
                };
                if (this.messageToEditId) {
                    newMessage = { ...newMessage, _id: this.messageToEditId, isEdited: true };
                }
                this.rootStore.conversationStore.setSelectedCovnersationLastMessage(text);
                this.setMessageToEdit(null);
                await this.sendMessageToChat(newMessage);
            }
            if (files.length) {
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    const newMessage = {
                        conversationId: conversationId,
                        type: file.type,
                        url: [file.url],
                        replyMessageId: (replyMessage && replyMessage._id) || null,
                        replyMessage: replyMessage,
                        thumbnail: file.thumbnail,
                        provider: provider,
                        received: false,
                        text: file.name,
                        referenceId: Date.now().toString(),
                        userId: user._id,
                        // _id: _id
                    };
                    await this.sendMessageToChat(newMessage);
                }
            }
        } catch (err) {
            if (err.response.data) {
                this.clearComposer();
                runInAction(() => {
                    this.updateMessage(err.response.data.data);
                });
            }
        }
    };

    clearComposer = () => {
        this.setMessageBody("");
        this.setMessageMedia([]);
        this.setReplyMessage(null);
    };

    sendMessageToChat = async (message: IMessage) => {
        this.clearComposer();
        let res = await addNewMessage(message);
        runInAction(() => {
            this.updateMessage(res.data);
        });
    };

    @action updateMessage = (msg: IMessage) => {
        let index = this.messages.findIndex(
            (message) => message.referenceId === msg.referenceId || message._id === msg._id
        );
        this.messages[index] = msg;
        const selectedConversation = this.rootStore.conversationStore.selectedConversation;
        if (selectedConversation && selectedConversation._id === this.messages[index].conversationId) {
            this.rootStore.conversationStore.markAsReadConversation(selectedConversation);
        }
    };

    @action setDeliveredMessage = (index: number) => {
        this.deliveredMessage = index;
    };

    @action reset = () => {
        this.messages = [];
        this.total = 0;
    };

    @action.bound setMessageBody = (text: string) => {
        if (this.currentConversationId) {
            if (this.newMessages[this.currentConversationId]) {
                this.newMessages[this.currentConversationId].text = text;
            } else {
                this.newMessages[this.currentConversationId] = {
                    text: text.trim(),
                    media: [],
                    replyMessage: null,
                };
            }
        }
    };

    @action setErrorText = (newMessage: IMessage) => {
        let message = this.messages.find((msg) => msg._id === newMessage._id);
        if (message) {
            message.errorText = "Message was not delivered";
        }
    };

    @action currentMessagePosition(message: IMessage, index: number): MessagePosition {
        const previousMessage: IMessage = this.messages[index - 1];
        const nextMessage: IMessage = this.messages[index + 1];
        if (previousMessage?.received !== message.received && nextMessage?.received === message.received)
            return MessagePosition.FIRST;
        if (previousMessage?.received === message.received && nextMessage?.received === message.received)
            return MessagePosition.MIDDLE;
        if (nextMessage?.received !== message.received && previousMessage?.received !== message.received)
            return MessagePosition.FIRST;
        if (nextMessage?.received !== message.received) return MessagePosition.LAST;

        return MessagePosition.LAST;
    }

    @computed get currentConversationId(): string {
        let selectedConversationId = this.rootStore.conversationStore.selectedConversation?._id;
        return selectedConversationId || "";
    }

    getNewMessageBody = () => {
        if (!this.newMessages[this.currentConversationId]) return null;
        return this.newMessages[this.currentConversationId].text.trim();
    };

    @action setMessageMedia = (media: IImages[], conversationId: string = this.currentConversationId) => {
        if (this.newMessages[conversationId]) {
            this.newMessages[conversationId].media = media;
        } else {
            this.newMessages[conversationId] = {
                text: "",
                media: [],
                replyMessage: null,
            };
        }
    };

    getNewMessageMedia = (): IImages[] => {
        if (!this.newMessages[this.currentConversationId]) return [];
        return this.newMessages[this.currentConversationId].media;
    };

    @action removeMediaFromMessage(currentMedia: IImages) {
        let index = this.newMessages[this.currentConversationId].media.findIndex(
            (media) => media.mediaId == currentMedia.mediaId
        );
        this.newMessages[this.currentConversationId].media.splice(index, 1);
    }

    @action.bound setImagesToPreview(image: { imageFrom: string; imageUrl: string }): void {
        if (image.imageFrom === "messageInput") {
            let index = this.newMessages[this.currentConversationId].media.findIndex((media) =>
                media.url ? media.url : media.localUrl === image.imageUrl
            );
            this.imagesToPreview.index = index;
            this.newMessages[this.currentConversationId].media.forEach((image) => {
                this.imagesToPreview.images.push(image);
            });
        } else if (image.imageFrom === "messageList") {
            this.messages.forEach((message) => {
                if (message.type === "IMAGE" || message.type === "VIDEO") {
                    this.imagesToPreview.images.push({
                        url: message.url[0],
                        loading: false,
                        localUrl: "",
                        type: message.type,
                        name: message.text || "",
                    });
                }
            });
            let index = this.imagesToPreview.images.findIndex((images) => images.url === image.imageUrl);
            this.imagesToPreview.index = index;
        } else {
            this.imagesToPreview = { images: [], index: -1 };
        }
    }
    @action previewLeftImage(): void {
        this.imagesToPreview.index -= 1;
    }
    @action previewRightImage(): void {
        this.imagesToPreview.index += 1;
    }
}
