import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { AccountCircle, Email, Lock } from "@material-ui/icons";
import { TextField, InputAdornment, Button } from "@material-ui/core";
import { Link, Redirect, useLocation, withRouter } from "react-router-dom";
import clsx from "clsx";
import ReCAPTCHA from "react-google-recaptcha";
import { observer } from "mobx-react-lite";
import momentTz from "moment-timezone";
import { setLocalStorage } from "../../../services/localStorageservice";

import { useStores } from "../../../hooks";
import { IUser } from "../../../stores/UserStore";
import Auth from "../../../services/auth.service";
import { error } from "../../../services/toast.service";
import { logos, banners } from "../../../utils/contants";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: "100vh",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "3.5fr 5fr",
        [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "1fr",
        },
    },
    carousel: {
        position: "relative",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    formContainer: {
        display: "grid",
        gridTemplateRows: "1fr 6fr",
        padding: "60px 100px",
        [theme.breakpoints.down("md")]: {
            justifyItems: "center",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "30px",
        },
    },
    logo: {
        backgroundImage: `url(${logos.kiboBlack})`,
        backgroundRepeat: "no-repeat",
        width: "200px",
        height: "80px",
        [theme.breakpoints.down("xs")]: {
            backgroundImage: `url(${logos.kiboBlackMobile})`,
            width: "120px",
            height: "45px",
        },
    },
    title: {
        fontSize: "28px",
        fontWeight: 700,
        lineheight: "150%",
        color: theme.palette.primary.main,
    },
    input: {
        background: theme.palette.background.default,
    },
    placeholder: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    field: {
        "& fieldset": {
            border: "none",
        },
        "& input": {
            fontFamily: "SOURCE SANS PRO",
        },
        marginTop: theme.spacing(1),
    },
    loggedInWrapper: {
        color: "#575757",
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
    },
    signInBtn: {
        border: "1px solid",
        boxSizing: "border-box",
        borderRadius: "30px",
        fontWeight: 700,
        textTransform: "unset",
        width: "100%",
    },
    btnDefault: {
        display: "flex",
        color: "white",
        fontWeight: 700,
        padding: "5px",
        alignItems: "center",
        borderRadius: "5px",
        fontSize: "14px",
        lineHeight: "100%",
        cursor: "pointer",
    },
    googleBtn: {
        background: "#3367D6",
    },
    googleLogo: {
        background: "white",
        padding: "10px",
        borderRadius: "5px",
        display: "flex",
        marginRight: "10px",
    },
    appleBtn: {
        background: "black",
        padding: "10px",
    },
    appleLogo: {
        marginRight: "10px",
    },
    buttonContainer: {
        display: "grid",
        "grid-template-columns": "1fr 1fr",
        gap: "10px",
    },
    dividerContainer: {
        display: "grid",
        "grid-template-columns": "1fr 1fr 1fr",
        gap: "20px",
        alignItems: "center",
        fontWeight: "bold",
        fontSize: "14px",
        color: "#6D6D6D",
    },
    hrContainer: {
        width: "100%",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    spacing: {
        margin: "20px 0",
    },
    link: {
        textDecoration: "none",
        fontSize: "16px",
        color: theme.palette.primary.main,
        fontWeight: 700,
        paddingLeft: "10px",
    },
    margin10: {
        marginBottom: "10px",
    },
    illustration: {
        backgroundImage: `url(${banners.signup})`,
        backgroundRepeat: "no-repeat",
        height: "932px",
        width: "852px",
        position: "absolute",
        right: 0,
        bottom: 0,
    },
}));

const SignUp = observer((props: any) => {
    const classes = useStyles();
    const { userStore } = useStores();

    const query = new URLSearchParams(useLocation().search);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        const email = query.get("email") as string;
        if (email) {
            userStore.setUser({ ...userStore.user, email: email });
        }
    }, []);

    if (Auth.isAuthenticated()) {
        return <Redirect to={{ pathname: "workspace" }} />;
    }

    const handleChange = (prop: keyof IUser) => (event: React.ChangeEvent<HTMLInputElement>) => {
        userStore.setUser({ ...userStore.user, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const loginWithGoogle = () => {
        setLocalStorage("userProfile", true);
        window.open("https://google.kibo.ai:4300/v1/google/authenticate", "_self");
    };

    const handleSignUp = async () => {
        try {
            if (userStore.validateUser()) {
                setLocalStorage("userProfile", true);
                const res = await userStore.signUp({ ...userStore.user, timezone: momentTz.tz.guess() });
                setLocalStorage("user", JSON.stringify({ ...userStore.user, _id: res.data.user._id }));
                setLocalStorage("token", res.data.user.accessToken);
                props.history.push(`workspace`);
            }
        } catch (err) {
            if (err.response.data) error(err.response.data.data.email);
        }
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        handleSignUp();
    };

    return (
        <div className={classes.root}>
            <div className={classes.formContainer}>
                <div className={classes.logo} />
                {query.get("email") ? (
                    <div>
                        <div className={clsx(classes.title, classes.spacing)}>Sign Up</div>
                        {/* <div className={clsx(classes.buttonContainer, classes.spacing)}>
                            <div className={clsx(classes.googleBtn, classes.btnDefault)} onClick={loginWithGoogle}>
                                <div className={classes.googleLogo}>
                                    <img src={icons.google} />
                                </div>
                                <div>Sign up with Google</div>
                            </div>
                            <div className={clsx(classes.appleBtn, classes.btnDefault)}>
                                <div className={classes.appleLogo}>
                                    <img src={icons.apple} />
                                </div>
                                <div>Sign up with Apple</div>
                            </div>
                        </div> */}
                        {/* <div className={clsx(classes.dividerContainer, classes.spacing)}>
                            <hr className={classes.hrContainer} />
                            <div>Or sign up with email</div>
                            <hr className={classes.hrContainer} />
                        </div> */}
                        <div>
                            <form onSubmit={onFormSubmit}>
                                <div className={classes.spacing}>
                                    <span className={classes.placeholder}>Full name</span>
                                    <TextField
                                        className={classes.field}
                                        required={true}
                                        onChange={handleChange("fullName")}
                                        placeholder="Name"
                                        InputProps={{
                                            className: classes.input,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountCircle />
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                        variant="outlined"
                                    />
                                </div>
                                <div className={classes.spacing}>
                                    <span className={classes.placeholder}>Email</span>
                                    <TextField
                                        className={classes.field}
                                        required={true}
                                        placeholder="name@domain.com"
                                        onChange={handleChange("email")}
                                        value={userStore.user?.email}
                                        disabled
                                        InputProps={{
                                            className: classes.input,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Email fontSize="small" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                        variant="outlined"
                                    />
                                </div>
                                <div className={classes.spacing}>
                                    <span className={classes.placeholder}>Password</span>
                                    <TextField
                                        className={classes.field}
                                        required={true}
                                        placeholder="At least 8 characters"
                                        onChange={handleChange("password")}
                                        type={showPassword ? "text" : "password"}
                                        InputProps={{
                                            className: classes.input,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Lock fontSize="small" onClick={handleClickShowPassword} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                        variant="outlined"
                                    />
                                </div>
                                <div className={classes.spacing}>
                                    <span className={classes.placeholder}>Confirm Password</span>
                                    <TextField
                                        className={classes.field}
                                        required={true}
                                        onChange={handleChange("confirmPassword")}
                                        placeholder="Passwords must match"
                                        type={showPassword ? "text" : "password"}
                                        InputProps={{
                                            className: classes.input,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Lock fontSize="small" onClick={handleClickShowPassword} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        error={userStore.userValidConfimPassword}
                                        helperText={userStore.userError?.confirmPassword}
                                    />
                                </div>
                                <div>
                                    <ReCAPTCHA
                                        sitekey="6LfZnNcZAAAAAOm2gQKxp_9oJU04Qmebackmkpo1"
                                        onChange={(ev) => userStore.isUserHuman(ev)}
                                    />
                                </div>
                                <div className={classes.spacing}>
                                    <Button
                                        className={classes.signInBtn}
                                        variant="outlined"
                                        color="primary"
                                        type="submit"
                                    >
                                        Create account
                                    </Button>
                                </div>
                                <div className={clsx(classes.container, classes.spacing)}>
                                    <div className={classes.margin10}>
                                        {"Already have an account?"}
                                        <Link to="sign-in" className={classes.link}>
                                            <span>{"Log in"}</span>
                                        </Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : (
                    <div className={clsx(classes.title, classes.spacing, classes.container)}>
                        We are currently in close beta so we are not accepting new users.
                    </div>
                )}
            </div>
            <div className={classes.carousel}>
                <div className={classes.illustration} />
            </div>
        </div>
    );
});

export default withRouter(SignUp);
