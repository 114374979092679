import React, { useRef, useState } from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import CardMedia from "@material-ui/core/CardMedia";
import DescriptionIcon from "@material-ui/icons/Description";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import ReplyIcon from "@material-ui/icons/Reply";
import ErrorIcon from "@material-ui/icons/Error";

import { useStores } from "../../../../hooks";
import { IMessage } from "../../../../stores/message/MessageStore";
import ReplyMessageComposer from "./input/ReplyMessageComposer";
import { useCallbackOutsideElement } from "../../../../utils/helperFunctions";
import MessageItemDropdown from "./MessageItemDropdown";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        messageRoot: {
            borderRadius: "20px",
            height: "fit-content",
            marginTop: theme.spacing(1),
            backgroundColor: "white",
        },
        message: {
            maxWidth: "300px",
            padding: 2,
        },
        messageTextContainer: {
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        firstOfGroup: {
            marginLeft: theme.spacing(7),
        },
        lastOfGroup: {
            borderBottomLeftRadius: "2px",
            backgroundColor: "white",
        },
        sendedMessage: {
            marginLeft: theme.spacing(8),
        },
        firstOfGroupReceiver: {
            marginRight: theme.spacing(7),
        },
        lastOfGroupReceiver: {
            borderBottomRightRadius: "2px",
        },
        recivedMessage: {
            marginRight: theme.spacing(8),
        },
        avatarReciver: {
            placeSelf: "flex-end",
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(1),
        },
        avatarSender: {
            placeSelf: "flex-end",
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(2),
        },
        messageContainer: {
            width: "100%",
            display: "inline-flex",
        },
        receiver: {
            justifyContent: "flex-end",
        },
        hideItem: {
            display: "none",
        },
        receiverMessage: {
            backgroundColor: theme.palette.primary.main,
            color: "white",
        },
        root: {
            display: "flex",
            flexDirection: "row-reverse",
        },
        media: {
            height: "200px",
            width: "200px",
            borderRadius: "16px",
        },
        firstMedia: {
            marginBottom: theme.spacing(1),
        },
        mediaMargin: {
            marginTop: theme.spacing(1),
        },
        messageTextWrapper: {
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
        },
        videoIcon: {
            position: "absolute",
            color: "white",
        },
        customBox: {
            display: "-webkit-box",
            boxOrient: "vertical",
            lineClamp: 2,
            wordBreak: "break-all",
            overflow: "hidden",
        },
        messageIcons: {
            display: "flex",
            alignSelf: "center",
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
            color: "lightgray",
            cursor: "pointer",
            "&:hover": {
                color: theme.palette.secondary.main,
            },
        },
        lastMessage: {
            marginBottom: theme.spacing(2),
        },
        errorMessage: {
            fontSize: "14px",
            marginRight: theme.spacing(8),
            flexDirection: "row-reverse",
            color: theme.palette.error.main,
            display: "flex",
            alignItems: "end",
        },
        errorIcon: {
            margin: theme.spacing(0.5),
            width: "14px",
            height: "14px",
            marginBottom: "-3px",
        },
        retry: {
            fontWeight: 700,
            marginLeft: theme.spacing(0.5),
            "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
            },
        },
        typingIcon: {
            width: "40px",
            height: "65px",
            marginTop: "-36px",
        },
        typingWrapper: {
            width: "60px",
            height: "38px",
            padding: "0 10px",
        },
        messageWrapper: {
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
            alignItems: "flex-end",
        },
        editedMessage: {
            color: "gray",
            fontSize: "12px",
        },
        menuIcon: {
            marginBottom: "-9px",
        },
        flex: {
            display: "flex",
            paddingTop: "8px",
        },
        textEnd: {
            textAlign: "end",
            marginBottom: "2px",
        },
    })
);

const MessageItem = (props: {
    message: IMessage;
    isFirst: boolean;
    isMiddle: boolean;
    isLast: boolean;
    isLastMessage: boolean;
    contactPicture: string;
    userPicture: string;
    index: number;
}) => {
    const classes = useStyles();
    const messageRef = useRef<HTMLHeadingElement>(null);
    const { messageStore, uiStore, conversationStore } = useStores();
    const [showSendedReplyIcon, setShowSendedReplyIcon] = useState(false);
    const [showRecivedReplyIcon, setShowRecivedReplyIcon] = useState(false);
    const { selectedConversation } = conversationStore;
    const conversationId = selectedConversation?._id;

    const { message, isFirst, isMiddle, isLast, isLastMessage, index } = props;
    const handleMessageHover = (value: boolean) => {
        message.received ? setShowRecivedReplyIcon(value) : setShowSendedReplyIcon(value);
    };

    const addNewMessage = (retryMessage: IMessage) => {
        if (selectedConversation && conversationId) {
            messageStore.sendMessage(
                conversationId,
                retryMessage.text || "",
                {
                    name: retryMessage.text,
                    url: retryMessage.url,
                    thumbnail: retryMessage.thumbnail,
                    type: retryMessage.type,
                },
                null,
                retryMessage.provider || "",
                retryMessage._id
            );
        }
    };
    useCallbackOutsideElement(messageRef, () => uiStore.setChatFiltersDropdown(false));
    return (
        <div className={clsx({ [classes.messageWrapper]: !message.received })}>
            <Box
                className={clsx({ [classes.receiver]: !message.received }, classes.messageContainer)}
                onMouseEnter={() => handleMessageHover(true)}
                onMouseLeave={() => handleMessageHover(false)}
            >
                <Avatar
                    src={props.contactPicture}
                    className={clsx({
                        [classes.hideItem]:
                            isLast ||
                            isMiddle ||
                            !message.received ||
                            (selectedConversation && selectedConversation.typing),
                        [classes.avatarSender]: message.received,
                    })}
                />

                {showSendedReplyIcon && (
                    <div className={classes.flex}>
                        <div
                            ref={messageRef}
                            className={clsx(
                                {
                                    [classes.hideItem]: message.received,
                                },
                                [classes.messageIcons, classes.menuIcon]
                            )}
                        >
                            <MessageItemDropdown
                                message={message}
                                selectedConv={conversationStore.selectedConversation}
                            />
                        </div>
                        <ReplyIcon
                            className={clsx(
                                {
                                    [classes.hideItem]: message.received,
                                },
                                [classes.messageIcons]
                            )}
                            onClick={() => messageStore.setReplyMessage(message)}
                        />
                    </div>
                )}
                <Box
                    className={clsx(
                        {
                            [classes.lastOfGroup]:
                                isFirst && message.received && !(selectedConversation && selectedConversation.typing),
                            [classes.recivedMessage]: !isFirst && !message.received,
                            [classes.sendedMessage]:
                                (isMiddle && message.received) ||
                                (isLast && message.received) ||
                                (selectedConversation && selectedConversation.typing),
                            [classes.lastOfGroupReceiver]: isFirst && !message.received,
                            [classes.receiverMessage]:
                                !message.received && (message.type === "TEXT" || message.replyMessageId),
                            [classes.mediaMargin]: message.type !== "TEXT" && !message.replyMessageId,
                            [classes.media]: message.type !== "TEXT" && !message.replyMessageId,
                        },
                        [classes.messageRoot]
                    )}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="#DFDFDF"
                    flexDirection="column"
                >
                    {message.type === "TEXT" && (
                        <Box className={clsx(classes.messageTextWrapper, classes.message)}>
                            <ReplyMessageComposer
                                isChatMessage={true}
                                recived={!message.received}
                                replyMessage={message.replyMessage}
                            />

                            <div className={classes.messageTextContainer}>{message.text}</div>
                        </Box>
                    )}

                    {message.type === "IMAGE" && (
                        <Box className={clsx(classes.messageTextWrapper, classes.message)}>
                            <ReplyMessageComposer
                                isChatMessage={true}
                                replyMessage={message.replyMessage}
                                recived={!message.received}
                            />
                            <Box
                                onClick={() =>
                                    messageStore.setImagesToPreview({
                                        imageFrom: "messageList",
                                        imageUrl: message.url[0],
                                    })
                                }
                            >
                                <CardMedia className={classes.media} image={message.url[0]} />
                            </Box>
                        </Box>
                    )}

                    {message.type === "FILE" && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                            pr={"10px"}
                            pl={"10px"}
                        >
                            <ReplyMessageComposer
                                isChatMessage={true}
                                replyMessage={message.replyMessage}
                                recived={!message.received}
                            />
                            <DescriptionIcon color="primary" />
                            <Box color="primary" component="div" className={classes.customBox}>
                                <a href={message.url[0]} target="_blank">
                                    {message.text}
                                </a>
                            </Box>
                        </Box>
                    )}
                    {
                        //refactor to ReplyMessageTypeDispacher component
                    }
                    {message.type === "VIDEO" && (
                        <Box>
                            <ReplyMessageComposer
                                isChatMessage={true}
                                replyMessage={message.replyMessage}
                                recived={!message.received}
                            />
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                position="relative"
                                onClick={() =>
                                    messageStore.setImagesToPreview({
                                        imageFrom: "messageList",
                                        imageUrl: message.url[0],
                                    })
                                }
                            >
                                <Box className={classes.videoIcon}>
                                    <PlayCircleFilledIcon fontSize="large" />
                                </Box>
                                <CardMedia className={classes.media} image={message.thumbnail} />
                            </Box>
                        </Box>
                    )}
                </Box>

                <Avatar
                    src={props.userPicture}
                    className={clsx({
                        [classes.hideItem]: isLast || isMiddle || message.received,
                        [classes.avatarReciver]: !message.received,
                    })}
                />
                {showRecivedReplyIcon && (
                    <div className={classes.flex}>
                        <ReplyIcon
                            className={clsx(
                                {
                                    [classes.hideItem]: !message.received,
                                },
                                [classes.messageIcons]
                            )}
                            onClick={() => messageStore.setReplyMessage(message)}
                        />
                        <div
                            ref={messageRef}
                            className={clsx(
                                {
                                    [classes.hideItem]: !message.received,
                                },
                                [classes.messageIcons, classes.menuIcon]
                            )}
                        >
                            <MessageItemDropdown
                                message={message}
                                selectedConv={conversationStore.selectedConversation}
                            />
                        </div>
                    </div>
                )}
            </Box>

            {message.errorMessage ? (
                <Box component={"div"} className={clsx({ [classes.lastMessage]: isLastMessage }, classes.errorMessage)}>
                    <div className={clsx(classes.textEnd)}>
                        {message.errorMessage}
                        <a className={classes.retry} onClick={() => addNewMessage(message)}>
                            <span>Retry</span>
                            <ErrorIcon className={classes.errorIcon} />
                        </a>
                    </div>
                </Box>
            ) : null}
            {message.isEdited && (
                <div
                    className={clsx(
                        { [classes.recivedMessage]: !message.received, [classes.sendedMessage]: message.received },
                        classes.editedMessage
                    )}
                >
                    Edited.
                </div>
            )}
        </div>
    );
};

export default MessageItem;
