import React, { useState, useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import WebAssetIcon from "@material-ui/icons/WebAsset";
import NotificationsIcon from "@material-ui/icons/Notifications";
import TodayIcon from "@material-ui/icons/Today";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import { useStores } from "../../../../hooks";
import ContactCard from "./Card";
import Dropdown, { IDropdownData } from "../../../common/Dropdown";
import { timeGapBetweenCurrentAndGivenDate } from "../../../../utils/helperFunctions";
import { IContact } from "../../../../stores/ContactStore";
import Row from "./Row";
import { useCallbackOutsideElement } from "../../../../utils/helperFunctions";
import { icons } from "../../../../utils/contants";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "grid",
        "grid-template-columns": "1fr",
        gridGap: theme.spacing(1),
        alignItems: "center",
        justifyItems: "flex-start",
        [theme.breakpoints.down("xs")]: {
            gridGap: "1em",
        },
    },
    container: {
        display: "grid",
        "grid-template-columns": "2fr 2fr",
        gridGap: theme.spacing(1),
        alignItems: "center",
        justifyItems: "flex-start",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
            "grid-template-columns": "1fr",
        },
    },
    input: {
        borderRadius: 4,
        border: "1px solid #fff",
        "&:hover": {
            borderColor: theme.palette.secondary.main,
            backgroundcolor: theme.palette.background.default,
        },
        "&:focus-within": {
            borderColor: theme.palette.secondary.main,
            backgroundcolor: theme.palette.background.default,
        },
        paddingLeft: theme.spacing(1),
        color: "#3D3D3D",
        width: "100%",
    },
    detailTagContainer: {
        display: "flex",
        alignItems: "center",
        fontWeight: 600,
        size: 16,
        fontSize: "16px",
    },
    detailTag: {
        marginLeft: theme.spacing(1),
    },
    dropdownContainer: {
        width: 160,
        color: theme.palette.secondary.main,
        display: "flex",
        padding: "6px 0 8px 8px",
        [theme.breakpoints.down("xs")]: {
            marginLeft: "24px",
        },
    },
}));

const Details = observer(() => {
    const { contactStore } = useStores();
    const { selectedContact } = contactStore;
    const classes = useStyles();
    const [genderDropdown, setGenderDropdown] = useState(false);
    const [notifcationsDropdown, setNotificationsDropdown] = useState(false);
    const [tempContact, setTempContact] = useState<IContact | null>(Object.assign({}, selectedContact));
    const genderRef = useRef<HTMLHeadingElement>(null);
    const notificationRef = useRef<HTMLHeadingElement>(null);

    const onFocus = (key: keyof IContact, value: boolean) => {
        if (!value && tempContact?.[key] !== selectedContact?.[key]) {
            contactStore.saveUpdatedContact();
            setTempContact(selectedContact);
        }
    };
    useCallbackOutsideElement(genderRef, () => setGenderDropdown(false));
    useCallbackOutsideElement(notificationRef, () => setNotificationsDropdown(false));

    const genderData: IDropdownData[] = ["Male", "Female", "Other"].map((element) => ({
        value: element,
        handleClick: () => {
            contactStore.updateSelectedContact("gender", element);
            onFocus("gender", false);
            setGenderDropdown(false);
        },
    }));

    const notificationsData: IDropdownData[] = ["Enabled", "Disabled"].map((element) => ({
        value: element,
        handleClick: () => {
            contactStore.updateSelectedContact("notifications", element);
            onFocus("notifications", false);
            setNotificationsDropdown(false);
        },
    }));
    const rowData: Array<{ key: keyof IContact; tag: string; icon: JSX.Element }> = [
        { key: "email", tag: "E-mail", icon: <MailIcon color="primary" /> },
        { key: "phone", tag: "Phone", icon: <PhoneIcon color="primary" /> },
        { key: "address", tag: "Address", icon: <RoomIcon color="primary" /> },
        { key: "website", tag: "Website", icon: <WebAssetIcon color="primary" /> },
    ];

    return (
        <ContactCard title={"Details"}>
            <div className={classes.root}>
                {rowData.map((element) => {
                    return (
                        <Row
                            key={element.key}
                            contactKey={element.key}
                            value={selectedContact?.[element.key as keyof IContact] as string}
                            handleOnChange={contactStore.updateSelectedContact}
                            handleFocus={onFocus}
                            tag={element.tag}
                            icon={element.icon}
                        />
                    );
                })}
                <div className={classes.container}>
                    <div className={classes.detailTagContainer}>
                        <img src={icons.gender} style={{ width: "27px", height: "27px" }} />
                        <span className={classes.detailTag}>Gender</span>
                    </div>
                    <div className={classes.dropdownContainer} ref={genderRef}>
                        <Dropdown
                            type="string"
                            hasInputField={false}
                            value={selectedContact?.gender ? selectedContact.gender : "Select a gender"}
                            dropdownData={genderData}
                            isDropdownOpen={genderDropdown}
                            toggleDropdown={(value: boolean) => {
                                setGenderDropdown(value);
                            }}
                            moreIcon={<ArrowDropDownIcon color="secondary" />}
                            lessIcon={<ArrowDropUpIcon color="secondary" />}
                        />
                    </div>
                </div>
                <div className={classes.container}>
                    <div className={classes.detailTagContainer}>
                        <TodayIcon color="primary" />
                        <span className={classes.detailTag}>Last seen</span>
                    </div>
                    {selectedContact?.conversations[0] && (
                        <div className={classes.dropdownContainer}>
                            {
                                timeGapBetweenCurrentAndGivenDate(selectedContact?.conversations[0].lastMessageTime)
                                    .timeGapAsString
                            }
                        </div>
                    )}
                </div>
                <div className={classes.container}>
                    <div className={classes.detailTagContainer}>
                        <NotificationsIcon color="primary" />

                        <span className={classes.detailTag}>Notifications</span>
                    </div>
                    <div className={classes.dropdownContainer} ref={notificationRef}>
                        <Dropdown
                            type="string"
                            hasInputField={false}
                            value={selectedContact?.notifications ? "Enabled" : "Disabled"}
                            dropdownData={notificationsData}
                            isDropdownOpen={notifcationsDropdown}
                            toggleDropdown={(value: boolean) => {
                                setNotificationsDropdown(value);
                            }}
                            moreIcon={<ArrowDropDownIcon color="secondary" />}
                            lessIcon={<ArrowDropUpIcon color="secondary" />}
                        />
                    </div>
                </div>
                <div className={classes.container}>
                    <div className={classes.detailTagContainer}>
                        <img src={icons.language} style={{ width: "27px", height: "27px" }} />

                        <span className={classes.detailTag}>Languages</span>
                    </div>
                    <div className={classes.dropdownContainer}>{selectedContact?.language}</div>
                </div>
            </div>
        </ContactCard>
    );
});

export default Details;
