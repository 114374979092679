import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/Index";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "@material-ui/core";
import theme from "./utils/uiCustomTheme";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
    dsn: "https://790948ee76eb4bf1afd3b801b480c570@o153073.ingest.sentry.io/5856635",
    integrations: [new Integrations.BrowserTracing()],
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <App />
    </ThemeProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
