import { useEffect } from "react";
import { observer } from "mobx-react-lite";

import { getProfile } from "../../../../services/twitter.service";
import { withRouter, useLocation } from "react-router";
import { error, success } from "../../../../services/toast.service";
import { useStores } from "../../../../hooks";

const TwitterAccount = observer((props: any) => {
    const { workspaceStore } = useStores();
    const query = new URLSearchParams(useLocation().search);
    const id = query.get("id") as string;
    const consumerKey = query.get("consumerKey") as string;
    const consumerSecret = query.get("consumerSecret") as string;
    const accessToken = query.get("token") as string;
    const tokenSecret = query.get("secret") as string;
    const devEnvironment = query.get("devEnvironment") as string;
    const successState = query.get("success") as string;
    const workspace = workspaceStore.workspaces[0];

    useEffect(() => {
        if (successState == "true") {
            success("Account was updated!");
            props.history.push({
                pathname: `workspace/${workspace._id}/channels`,
            });
        } else if (successState == "false") {
            error("There was an error updating the account!");
            props.history.push({
                pathname: `workspace/${workspace._id}/channels`,
            });
        } else {
            const getTwitterProfile = async () => {
                const result = await getProfile(
                    id,
                    consumerKey,
                    consumerSecret,
                    accessToken,
                    tokenSecret,
                    devEnvironment
                );
                if (result.status === "success") {
                    props.history.push({
                        pathname: `workspace/${workspace._id}/channels`,
                        state: result.data ? [{ ...result.data, picture: result.data.profile_image_url_https }] : null,
                    });
                }
            };

            getTwitterProfile();
        }
    }, []);
    return null;
});

export default withRouter(TwitterAccount);
