import React, { useRef } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import InputAdornment from "@material-ui/core/InputAdornment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "relative",
        },
        field: {
            background: theme.palette.background.default,
            "& fieldset": {
                border: "none",
            },
            borderRadius: "5px",
            width: "100%",
        },
        listWrapper: {
            position: "absolute",
            padding: theme.spacing(1),
            cursor: "pointer",
            width: "100%",
            boxShadow: "0px 9px 46px rgba(0, 0, 0, 0.12), 0px 24px 38px rgba(0, 0, 0, 0.14)",
            borderRadius: "5px",
            zIndex: 999,
            background: "#FFFFFF",
            overflow: "auto",
            maxHeight: "200px",
            " &::-webkit-scrollbar": {
                width: "12px",
                height: "10px",
                marginRight: "5px",
            },
            "&::-webkit-scrollbar-track": {
                background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
                background: "#A0A0A0",
                borderRadius: "7px",
                backgroundClip: "padding-box",
                border: "3px solid transparent",
                minHeight: "50px",
                "&:hover": {
                    background: "#A0A0A0",
                    backgroundClip: "padding-box",
                },
            },
        },
        listPosition: {
            right: "22%",
            width: "max-content !important",
        },
        listElement: {
            display: "flex",
            padding: theme.spacing(1),
            cursor: "pointer",
            "&:hover": {
                background: "#F2F2F2",
                backgroundClip: "padding-box",
            },
            borderRadius: "7px",
        },
        textContainer: {
            display: "flex",
            cursor: "pointer",
        },
        detailTag: {
            marginLeft: theme.spacing(1),
        },
        startAdornament: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        divider: {
            height: "2px",
            background: theme.palette.background.default,
        },
        colorDanger: {
            color: theme.palette.error.main,
        },
    })
);

export interface IDropdownData {
    value: string;
    handleClick: Function;
    divider?: boolean;
    colorDanger?: boolean;
    color?: string;
    icon?: JSX.Element;
}

const Dropdown = observer(
    (props: {
        type: string;
        value: string | null;
        label?: string;
        onChange?: any;
        dropdownData: IDropdownData[];
        isDropdownOpen: boolean;
        toggleDropdown: any;
        hasInputField: boolean;
        lessIcon?: JSX.Element;
        moreIcon?: JSX.Element;
        startAdornmentIcon?: JSX.Element;
        color?: string;
        icon?: JSX.Element;
    }): JSX.Element => {
        const classes = useStyles(props);
        const {
            type,
            value,
            label,
            onChange,
            dropdownData,
            isDropdownOpen,
            toggleDropdown,
            hasInputField,
            lessIcon,
            moreIcon,
            startAdornmentIcon,
            color,
        } = props;

        return (
            <div className={classes.root}>
                {hasInputField ? (
                    <TextField
                        type={type}
                        value={value}
                        label={label}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(e.target.value);
                        }}
                        variant="outlined"
                        className={clsx(classes.field)}
                        size="small"
                        InputProps={{
                            endAdornment: isDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />,
                            startAdornment: <InputAdornment position="start">{startAdornmentIcon}</InputAdornment>,
                        }}
                        onFocus={(e) => {
                            e.stopPropagation();
                            toggleDropdown(true);
                        }}
                    />
                ) : (
                    <div
                        className={classes.textContainer}
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleDropdown(!isDropdownOpen);
                        }}
                    >
                        <span>{value}</span>
                        {startAdornmentIcon && startAdornmentIcon}
                        {isDropdownOpen ? lessIcon : moreIcon}
                    </div>
                )}
                {isDropdownOpen && (
                    <div className={clsx({ [classes.listPosition]: !hasInputField }, classes.listWrapper)}>
                        {dropdownData.map((data: IDropdownData) => (
                            <>
                                <div
                                    className={clsx(classes.listElement, { [classes.colorDanger]: data.colorDanger })}
                                    onClick={() => {
                                        data.handleClick(data.value);
                                    }}
                                >
                                    {data.icon && data.icon}
                                    {data.value}
                                </div>
                                {data.divider && <Divider className={classes.divider} />}
                            </>
                        ))}
                    </div>
                )}
            </div>
        );
    }
);

export default Dropdown;
