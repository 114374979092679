import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import clsx from "clsx";
import PhotoIcon from "@material-ui/icons/Photo";
import VideocamIcon from "@material-ui/icons/Videocam";
import Box from "@material-ui/core/Box";
import DescriptionIcon from "@material-ui/icons/Description";

import { useStores } from "../../../../../hooks";
import { observer } from "mobx-react-lite";
import CardMedia from "@material-ui/core/CardMedia";
import { IMessage } from "../../../../../stores/message/MessageStore";

const useStyles = makeStyles((theme: Theme) => ({
    replyCard: {
        padding: theme.spacing(1.2),
        position: "relative",
        backgroundColor: "white",
        borderRadius: "20px",
        display: "flex",
    },
    chatCard: {
        border: "none",
        borderRadius: 20,
        padding: theme.spacing(1.2),
        display: "flex",
        minWidth: "200px",
    },
    recivedColor: {
        color: "#FFFFF",
    },
    sendedColor: {
        color: "#737373",
    },
    recivedBackground: {
        backgroundColor: "#0C6CC6",
    },
    sendedBackgorund: {
        backgroundColor: theme.palette.background.default,
    },
    contactName: {
        marginBottom: theme.spacing(1),
        fontWeight: 700,
    },

    closeIcon: {
        position: "absolute",
        cursor: "pointer",
        width: "18px",
        height: "18px",
        top: "8%",
        right: "2%",
        color: "gray",
        "&:hover": {
            color: "black",
        },
        zIndex: 999,
    },
    customBox: {
        display: "-webkit-box",
        boxOrient: "vertical",
        lineClamp: 2,
        wordBreak: "break-all",
        overflow: "hidden",
    },
    media: {
        height: "45px",
        width: "45px",
    },
    videoIcon: {
        position: "absolute",
        color: "white",
    },
    devider: {
        width: 5,
        borderRadius: 9,
        marginRight: 9,
    },
    recivedDevider: {
        background: "rgba(255, 255, 255, 0.75)",
    },
    sendedDevider: {
        background: "#737373",
    },
    replyDevider: {
        background: "#DFDFDF",
    },
    mediaContainer: {
        width: 250,
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
    },
    chatContainer: {
        maxWidth: 250,
    },
    replyMediaContainer: {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
    },
    replyTextContainer: {
        width: "90%",
    },
}));

const ReplyMessageComposer = observer(
    (props: { isChatMessage: boolean; recived?: boolean; replyMessage: null | IMessage | undefined }) => {
        const classes = useStyles();

        const { messageStore, conversationStore } = useStores();
        const { selectedConversation } = conversationStore;
        const { replyMessage, isChatMessage, recived } = props;
        const mediaReply = replyMessage?.type === "IMAGE" || replyMessage?.type === "VIDEO";

        if (replyMessage && selectedConversation) {
            return (
                <div
                    className={clsx({
                        [classes.chatCard]: isChatMessage,
                        [classes.recivedBackground]: recived && isChatMessage,
                        [classes.sendedBackgorund]: !recived && isChatMessage,
                        [classes.replyCard]: !isChatMessage,
                    })}
                >
                    <div
                        className={clsx(classes.devider, {
                            [classes.recivedDevider]: isChatMessage && recived,
                            [classes.sendedDevider]: isChatMessage && !recived,
                            [classes.replyDevider]: !isChatMessage,
                        })}
                    />

                    <div
                        className={clsx({
                            [classes.mediaContainer]: mediaReply && isChatMessage,
                            [classes.chatContainer]: isChatMessage,
                            [classes.replyMediaContainer]: !isChatMessage && mediaReply,
                            [classes.replyTextContainer]: !isChatMessage,
                        })}
                    >
                        <Box display="flex" flexDirection="column">
                            <Typography variant="body2" component="p" className={clsx([classes.contactName])}>
                                {replyMessage.received ? selectedConversation.contactName : "You"}
                            </Typography>
                            {replyMessage.type === "IMAGE" && (
                                <Box display="flex" alignItems="center">
                                    <PhotoIcon
                                        className={clsx({
                                            [classes.recivedColor]: recived,
                                            [classes.sendedColor]: !recived,
                                        })}
                                    />
                                    <span
                                        className={clsx({
                                            [classes.recivedColor]: recived,
                                            [classes.sendedColor]: !recived,
                                        })}
                                    >
                                        Image
                                    </span>
                                </Box>
                            )}
                            {replyMessage.type === "VIDEO" && (
                                <Box display="flex" alignItems="center">
                                    <VideocamIcon
                                        className={clsx({
                                            [classes.recivedColor]: recived,
                                            [classes.sendedColor]: !recived,
                                        })}
                                    />
                                    <span
                                        className={clsx({
                                            [classes.recivedColor]: recived,
                                            [classes.sendedColor]: !recived,
                                        })}
                                    >
                                        Video
                                    </span>
                                </Box>
                            )}
                        </Box>
                        {replyMessage.type === "TEXT" ? (
                            <Typography
                                variant="body2"
                                component="p"
                                className={clsx(
                                    { [classes.recivedColor]: recived, [classes.sendedColor]: !recived },
                                    classes.customBox
                                )}
                            >
                                {replyMessage.text}
                            </Typography>
                        ) : replyMessage.type === "IMAGE" ? (
                            <CardMedia className={classes.media} image={replyMessage.url[0]} />
                        ) : replyMessage.type === "VIDEO" ? (
                            <CardMedia className={classes.media} image={replyMessage.thumbnail} />
                        ) : (
                            <Box display="flex" justifyContent="center" flexDirection="column" pr={"10px"} pl={"10px"}>
                                <DescriptionIcon color="primary" />
                                <Box color="primary" component="div" className={classes.customBox}>
                                    <a href={replyMessage.url[0]}>{replyMessage.text}</a>
                                </Box>
                            </Box>
                        )}
                    </div>
                    {isChatMessage ? null : (
                        <HighlightOffIcon
                            className={classes.closeIcon}
                            onClick={() => messageStore.setReplyMessage(null)}
                        />
                    )}
                </div>
            );
        }
        return null;
    }
);

export default ReplyMessageComposer;
