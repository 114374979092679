import { IConversation } from "../stores/ConversationStore";
import { conversationAxiosInstance, workspaceAxiosInstance } from "../utils/axios.instance";
import { getLocalStorage } from "./localStorageservice";

export const getConversations = async (
    skip: number,
    pageSize: number,
    filter: string,
    statusFilter: string,
    type: string
) => {
    const workspace = JSON.parse(getLocalStorage("workspace"));
    try {
        const response = await conversationAxiosInstance({
            url: "/",
            method: "POST",
            data: {
                pageSize: pageSize,
                skip: skip,
                filter: filter,
                statusFilter: statusFilter,
                workspaceId: workspace._id,
                type: type,
            },
        });
        return response.data;
    } catch (error) {
        console.log(error, "=======>");
    }
};

export const updateConversation = async (conversation: IConversation) => {
    const response = await conversationAxiosInstance({
        url: `/${conversation._id}`,
        method: "PUT",
        data: conversation,
    });

    return response.data;
};

export const getConversationById = async (conversationId: string) => {
    const response = await conversationAxiosInstance({
        url: `/${conversationId}`,
        method: "GET",
    });

    return response.data;
};

export const setIsTyping = async (conversationId: string, typing: boolean) => {
    const response = await conversationAxiosInstance({
        url: `/typing`,
        method: "POST",
        data: {
            conversationId,
            typing,
        },
    });
    return response;
};

export const deleteConversation = async (conversationId: string) => {
    const response = await conversationAxiosInstance({
        url: `/${conversationId}`,
        method: "DELETE",
    });

    return response;
};
