import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { icons, logos } from "../../../utils/contants";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {},
        provider: {
            marginLeft: theme.spacing(0.5),
            fontWeight: 700,
        },
        iconWrapper: {
            borderRadius: "50%",
            display: "flex",
            padding: "5px",
        },
        facebook: {
            backgroundColor: "#1085F0",
            color: "#1085F0",
        },
        twitter: {
            backgroundColor: "#55ACEE",
            color: "#55ACEE",
        },
        twitterColor: {
            color: "#55ACEE",
        },
        whatsapp: {
            color: "#25D366",
        },
        displayFlex: {
            display: "flex",
        },
        liveChat: {
            color: theme.palette.primary.main,
        },
        email: {
            color: "#FF8863",
        },
    })
);

const AccountProvider = (props: { provider: string }) => {
    const classes = useStyles();
    const { provider } = props;

    switch (provider) {
        case "facebook":
            return (
                <div className={classes.displayFlex}>
                    <div className={clsx(classes.facebook, classes.iconWrapper)}>
                        <img src={logos.messenger} />
                    </div>
                    <div className={classes.provider}>Messenger</div>
                </div>
            );
        case "twitter":
            return (
                <div className={clsx(classes.displayFlex, classes.twitterColor)}>
                    <div className={clsx(classes.iconWrapper, classes.twitter)}>
                        <img src={logos.twitter} />
                    </div>
                    <div className={classes.provider}>Twitter</div>
                </div>
            );
        case "whatsapp":
            return (
                <div className={clsx(classes.whatsapp, classes.displayFlex)}>
                    <img src={logos.whatsapp} />
                    <div className={classes.provider}>WhatsApp</div>
                </div>
            );
        case "email":
            return (
                <div className={clsx(classes.email, classes.displayFlex)}>
                    <img src={icons.email} />
                    <div className={classes.provider}>E-Mail</div>
                </div>
            );
        default:
            return (
                <div className={clsx(classes.liveChat, classes.displayFlex)}>
                    <img src={logos.chat} />
                    <div className={classes.provider}>Chat</div>
                </div>
            );
    }
};

export default AccountProvider;
