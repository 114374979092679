import { TextField } from "@material-ui/core";
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Modal from "../../../common/Modal";
import AccountProvider from "../AccountProvider";
import { copyToClipboard } from "../../../../utils/helperFunctions";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(3),
        minWidth: "350px",
        [theme.breakpoints.down("sm")]: {
            minWidth: "250px",
        },
    },
    input: {
        background: theme.palette.background.default,
        borderRadius: 4,
        border: "1px solid #fff",
        paddingLeft: theme.spacing(1),
        color: "#3D3D3D",
        width: "100%",
        height: 40,
        marginTop: theme.spacing(0.5),
    },
    placeholder: {
        fontSize: 14,
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(1),
    },
    copyWrapper: {
        cursor: "pointer",
        margin: theme.spacing(1),
    },
}));
const ForwardEmailDialog = (props: { open: boolean; setOpen: Function; forwardEmail: string }) => {
    const classes = useStyles();

    return (
        <Modal open={props.open} onClose={() => props.setOpen(false)} hideActions={true} title={"Forward Email"}>
            <AccountProvider provider="email" />

            <div className={classes.container}>
                <span className={classes.placeholder}>Chat Id*</span>
                <InputBase
                    id="outlined-basic"
                    disabled
                    className={classes.input}
                    value={props.forwardEmail}
                    required={true}
                    onClick={() => copyToClipboard(props.forwardEmail)}
                    endAdornment={
                        <div>
                            <FileCopyIcon fontSize="small" className={classes.copyWrapper} />
                        </div>
                    }
                />
            </div>
        </Modal>
    );
};

export default ForwardEmailDialog;
