import React, { useRef } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useTheme, Theme } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CheckIcon from "@material-ui/icons/Check";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Tooltip from "@material-ui/core/Tooltip";

import Dropdown, { IDropdownData } from "../../../common/Dropdown";
import { useStores } from "../../../../hooks";
import { useCallbackOutsideElement } from "../../../../utils/helperFunctions";
import clsx from "clsx";
import { icons } from "../../../../utils/contants";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: "white",
        },
        sectionWrapper: {
            display: "flex",
            alignItems: "center",
            minHeight: "60px",
            [theme.breakpoints.down("sm")]: {
                backgroundColor: "transparent",
            },
        },
        firstSection: {
            display: "grid",
            "grid-template-columns": "repeat(auto-fit, minmax(60px, 1fr))",
        },

        conversationDivider: {
            height: "5px",
            background: theme.palette.background.default,
            marginTop: "-5px",
        },

        searchBar: {
            border: "none",
            background: "transparent",
            width: "100%",
            outline: "none",
            padding: 0,
        },
        icons: {
            color: theme.palette.secondary.main,
        },
        paddingx: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        searchItem: {
            padding: theme.spacing(2.1),
            borderBottom: "5px solid #F2F2F2",
            cursor: "pointer",
            "&:hover": {
                background: "#F2F9FF",
            },
            fontWeight: 700,
            display: "flex",
            alignItems: "center",
            fontSize: "17px",
            width: "100%",
            justifyContent: "center",
        },
        selectedItem: {
            background: "#F2F9FF",
            borderBottom: "5px solid #1085F0",
            color: theme.palette.primary.main,
        },
        searchItemGroup: {
            display: " flex",
            alignItems: "center",
            width: "100%",
        },
    })
);

const Search = observer((props: any) => {
    const classes = useStyles();
    const theme = useTheme();
    const wrapperRef = useRef<HTMLHeadingElement>(null);
    const { conversationStore, uiStore } = useStores();
    useCallbackOutsideElement(wrapperRef, () => uiStore.setChatFiltersDropdown(false));

    const dropdownData: IDropdownData[] = [
        { value: "All", handleClick: conversationStore.setStatusFilter, color: "secondary" },
        {
            value: "Unread",
            handleClick: conversationStore.setStatusFilter,
            color: "secondary",
            icon: (
                <div
                    style={{
                        borderRadius: "50%",
                        display: "flex",
                        backgroundColor: "transparent",
                        border: "2.5px solid #1085F0",
                        width: "21px",
                        height: "21px",
                        marginRight: "8px",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <ArrowForwardIosIcon color="primary" style={{ fontSize: 12 }} />
                </div>
            ),
        },
        {
            value: "Resolved",
            handleClick: conversationStore.setStatusFilter,
            color: "primary",
            icon: (
                <div
                    style={{
                        borderRadius: "50%",
                        display: "flex",
                        padding: "3px",
                        marginRight: "8px",
                        backgroundColor: "#4FDA6E",
                    }}
                >
                    <CheckIcon htmlColor="#FFFFFF" style={{ fontSize: 15 }} />
                </div>
            ),
        },
        {
            value: "Unresolved",
            handleClick: conversationStore.setStatusFilter,
            color: "success",
            icon: (
                <div
                    style={{
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        border: "2.5px solid #3D3133",
                        width: "21px",
                        height: "21px",
                        marginRight: "8px",
                        backgroundColor: "transparent",
                    }}
                >
                    <CheckIcon color="secondary" style={{ fontSize: 15 }} />
                </div>
            ),
        },
    ];
    const searchByTypeData: any[] = [
        { value: "All", label: "All messages", handleClick: (value) => searchByType(value) },

        {
            value: "Messages",
            handleClick: (value) => searchByType(value),
            icon: icons.messages,
        },
        {
            value: "Favourites",
            handleClick: (value) => searchByType(value),
            icon: icons.favourites,
        },
        {
            value: "Mentions",
            handleClick: conversationStore.setStatusFilter,
            icon: icons.mentions,
        },
        {
            value: "Comments",
            handleClick: conversationStore.setStatusFilter,
            icon: icons.comments,
        },
    ];
    const searchByType = (value: string) => {
        conversationStore.setTypeFilter(value);
    };
    return (
        <div className={classes.root}>
            {/* <Box className={clsx(classes.sectionWrapper, classes.firstSection)} display="flex">
                {searchByTypeData.map((el, index) => {
                    if (index < 5) {
                        return (
                            <Tooltip title={el.lablel ? el.label : el.value}>
                                <div
                                    onClick={() => searchByType(el.value)}
                                    className={clsx(classes.searchItem, {
                                        [classes.selectedItem]: conversationStore.statusType === el.value,
                                    })}
                                >
                                    {el.icon ? <img src={el.icon} /> : el.value}
                                </div>
                            </Tooltip>
                        );
                    }
                })}
            </Box> */}

            <div className={classes.conversationDivider} />
            <Box className={clsx(classes.sectionWrapper, classes.paddingx)} display="flex">
                <Box display="flex" alignItems="center" mr={theme.spacing(0.2)}>
                    <SearchIcon />
                </Box>
                <input
                    type="text"
                    placeholder="Search"
                    className={classes.searchBar}
                    onChange={(event) => conversationStore.onChangeFilter(event.target.value)}
                />
                <div ref={wrapperRef}>
                    <Dropdown
                        type="string"
                        hasInputField={false}
                        value={conversationStore.statusFilter}
                        dropdownData={dropdownData}
                        isDropdownOpen={uiStore.displayChatFiltersDropdown}
                        toggleDropdown={uiStore.setChatFiltersDropdown}
                        moreIcon={<ExpandMoreIcon color="secondary" />}
                        lessIcon={<ExpandLessIcon color="secondary" />}
                    />
                </div>
            </Box>
        </div>
    );
});

export default Search;
