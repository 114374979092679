import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { TextField, Button, InputAdornment, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router";
import { observer } from "mobx-react-lite";

import { addWorkspace } from "../../../services/workspace.service";
import { setLocalStorage } from "../../../services/localStorageservice";
import clsx from "clsx";
import CreateIcon from "@material-ui/icons/Create";
import ProfilePicture from "../../common/ProfilePicture";
import { banners, logos } from "../../../utils/contants";
import { useStores } from "../../../hooks";
import { IWorkspace } from "../../../stores/WorkspaceStore";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: "100vh",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "3.5fr 5fr",
        [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "1fr",
        },
    },
    carusel: {
        position: "relative",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    formContainer: {
        display: "grid",
        gridTemplateRows: "2fr 10fr",
        padding: "60px 100px",
        [theme.breakpoints.down("md")]: {
            justifyItems: "center",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "30px",
        },
    },
    logo: {
        backgroundImage: `url(${logos.kiboBlack})`,
        backgroundRepeat: "no-repeat",
        width: "200px",
        height: "80px",
        [theme.breakpoints.down("xs")]: {
            backgroundImage: `url(${logos.kiboBlackMobile})`,
            width: "120px",
            height: "45px",
        },
    },
    title: {
        fontSize: "28px",
        fontWeight: 700,
        lineheight: "150%",
        color: theme.palette.primary.main,
    },
    input: {
        background: theme.palette.background.default,
    },
    placeholder: {
        fontSize: 14,
        color: theme.palette.primary.main,
    },
    field: {
        "& fieldset": {
            border: "none",
        },
        marginTop: theme.spacing(1),
    },
    loggedInWrapper: {
        color: "#575757",
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
    },
    btn: {
        border: "1px solid",
        boxSizing: "border-box",
        borderRadius: "30px",
        fontWeight: 700,
        textTransform: "unset",
        width: "100%",
    },

    spacing: {
        margin: "20px 0",
    },
    link: {
        textDecoration: "none",
        fontSize: "16px",
        color: theme.palette.primary.main,
        fontWeight: 700,
        paddingLeft: "10px",
    },
    margin10: {
        marginBottom: "10px",
    },
    alignVertical: {
        display: "flex",
        flexDirection: "column",
    },
    pictureCenter: {
        display: "flex",
        justifyContent: "center",
    },
    roundOvalTop: {
        backgroundImage: `url(${banners.roundOvalTop})`,
        backgroundRepeat: "no-repeat",
        height: "150px",
    },
    workspaceTop: {
        backgroundImage: `url(${banners.workspaceTop})`,
        backgroundRepeat: "no-repeat",
        height: "311px",
        width: "461px",
        position: "absolute",
        right: 0,
        top: 0,
    },
    workspaceBottom: {
        backgroundImage: `url(${banners.workspaceBottom})`,
        backgroundRepeat: "no-repeat",
        height: "682px",
        width: "854px",
        position: "absolute",
        right: 0,
        bottom: 0,
    },
    loading: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",
        margin: theme.spacing(1),
    },
    loaderWrapper: {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

const Workspace = observer((props: any) => {
    const classes = useStyles();
    const { userStore, workspaceStore, tourStore } = useStores();
    const [files, setFiles] = useState<Array<any>>([] as Array<File>);
    const [name, setName] = useState<string>("");
    let workspace: IWorkspace = workspaceStore.workspaces[0] || null;

    const addFile = (images: Array<File>) => {
        const addedFiles = images.map((image) =>
            Object.assign(image, {
                preview: URL.createObjectURL(image),
            })
        );
        setFiles(addedFiles);
    };

    const setWorkspaceName = (value: string) => {
        setName(value);
    };
    useEffect(() => {
        getWorkspaces();
    }, []);
    const getWorkspaces = () => {
        if (userStore.isAuthenticated) {
            if (workspace && workspace._id) {
                props.history.push(`workspace/${workspace._id}/inbox/`);
            } else {
                props.history.push(`/workspace`);
            }
        } else {
            props.history.push(`/sign-in`);
        }
    };

    const createWorkspace = async () => {
        if (name) {
            const result = await addWorkspace(files[0], name);
            const workspace = result.data;
            if (result.status === "success") {
                setLocalStorage("workspace", JSON.stringify(workspace));
                props.history.push(`workspace/${workspace?._id}/inbox`);
                if (tourStore.shouldOpenTour()) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1);
                }
            }
        }
    };
    if (userStore.isAuthenticated && workspace && workspace._id)
        return (
            <div className={classes.loaderWrapper}>
                <CircularProgress className={classes.loading} />
            </div>
        );

    const handleSubmit = (e) => {
        e.preventDefault();
        createWorkspace();
    };
    return (
        <div>
            {/* <NavBar /> */}
            <div className={classes.root}>
                <div className={classes.formContainer}>
                    <div className={classes.logo} />
                    <div className={classes.alignVertical} id="first-step">
                        <div className={clsx(classes.title)}>Create workspace</div>
                        <div className={classes.spacing} style={{ color: "#3D3D3D" }}>
                            Add a profile picture and a name for the workspace
                        </div>
                        <div className={clsx(classes.spacing, classes.alignVertical)}>
                            <div className={classes.spacing} id="workspace-picture">
                                <div className={clsx(classes.pictureCenter, classes.spacing)}>
                                    <ProfilePicture
                                        uploadImage={addFile}
                                        image={files[0]?.preview || ""}
                                        removeCurrentPicture={() => setFiles([])}
                                    />
                                </div>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className={classes.spacing} id="workspace-name">
                                    <TextField
                                        className={`${classes.field} mask-test`}
                                        required={true}
                                        placeholder="Name"
                                        onChange={(ev) => setWorkspaceName(ev.target.value)}
                                        autoFocus
                                        InputProps={{
                                            className: classes.input,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CreateIcon fontSize="small" />
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        value={name}
                                    />
                                </div>
                                <div id="workspace-create">
                                    <Button
                                        className={classes.btn}
                                        //onClick={() => createWorkspace()}
                                        variant="outlined"
                                        color="primary"
                                        type="submit"
                                    >
                                        Create Workspace
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className={classes.carusel}>
                    <div className={classes.roundOvalTop} />
                    <div className={classes.workspaceTop} />
                    <div className={classes.workspaceBottom} />
                </div>
            </div>
        </div>
    );
});

export default withRouter(Workspace);
