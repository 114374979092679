import React from "react";
import { withStyles, createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import clsx from "clsx";
import TableCell from "@material-ui/core/TableCell";

import SettingsNavigation from "./SettingsNavigation";

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            "justify-content": "flex-start",
        },
        row: {
            display: "grid",
            "grid-template-columns": "1fr",
            gridGap: "1.3em",
        },
        section: {
            display: "grid",
            "grid-template-columns": "1fr",
            gridGap: "0.3em",
        },
        card: {
            borderRadius: "0px 0px 20px 20px",
            padding: theme.spacing(2),
            backgroundColor: "white",
        },
        button: {
            border: "1.5px solid #1085F0 !important",
            color: theme.palette.primary.main,
            fontSize: 13,
            borderRadius: "20px",
        },
        plan: {
            display: "flex",
            justifyContent: "space-between",
        },
        planIcon: {
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            backgroundColor: "#c4c4c4",
            marginRight: theme.spacing(1),
        },
        flex: {
            display: "flex",
            alignItems: "center",
        },
        fontDefault: {
            color: theme.palette.secondary.main,
            fontSize: "16px",
        },
        fontBig: {
            fontSize: "20px",
        },
        fontBold: {
            fontWeight: 700,
        },
        status: {
            color: theme.palette.success.main,
            paddingLeft: theme.spacing(0.5),
        },
        buttonPause: {
            border: "1.5px solid !important",
            borderColor: theme.palette.warning.main,
            color: theme.palette.warning.main,
            fontSize: 13,
            borderRadius: "20px",
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(2),
        },
        buttonCancel: {
            border: "1.5px solid !important",
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main,
            fontSize: 13,
            borderRadius: "20px",
        },
        actionsContainer: {
            display: "flex",
        },
        billingContainer: {
            background: "#FFFFFF",
            borderRadius: "20px",
            padding: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        tableRow: {
            display: "grid",
            "grid-template-columns": "1fr 1fr 1fr 1fr",
            padding: theme.spacing(1),
        },
        tableHead: {
            display: "grid",
            "grid-template-columns": "1fr 1fr 1fr 1fr",
            padding: theme.spacing(0.5),
        },
        tableBackground: {
            background: theme.palette.background.default,
        },
    })
);

const Billing = (): JSX.Element => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div>
                <SettingsNavigation />
                <div className={clsx(classes.row, classes.card)}>
                    <span className={classes.fontDefault}>Plan Details</span>
                    <div className={classes.plan}>
                        <div className={classes.flex}>
                            <div className={classes.planIcon} />
                            <div className={clsx(classes.fontDefault, classes.fontBig, classes.fontBold)}>
                                Mitto Silver
                            </div>
                        </div>
                        <div>
                            <Button variant="outlined" color="primary" className={classes.button}>
                                Change Plan
                            </Button>
                        </div>
                    </div>
                    <div className={classes.section}>
                        <div className={classes.flex}>
                            <span className={clsx(classes.fontDefault, classes.fontBold)}>
                                Status:<span className={classes.status}>active</span>
                            </span>
                        </div>
                        <span className={classes.fontDefault}>3/5 Social Accounts</span>
                        <span className={classes.fontDefault}>0/0 Members</span>
                    </div>
                </div>
                <div className={clsx(classes.row, classes.billingContainer)}>
                    <div className={classes.section}>
                        <span className={classes.fontDefault}>Billing</span>
                        <span className={classes.fontDefault}>
                            Your next billing is:<span className={classes.fontBold}>1 April 2021</span>
                        </span>
                    </div>
                    <div className={classes.section}>
                        <span className={classes.fontDefault}>Actions</span>
                        <div className={classes.actionsContainer}>
                            <div>
                                <Button variant="outlined" color="primary" className={classes.button}>
                                    Update Payment
                                </Button>
                            </div>
                            <div>
                                <Button variant="outlined" color="primary" className={classes.buttonPause}>
                                    Pause subscription
                                </Button>
                            </div>
                            <div>
                                <Button variant="outlined" color="primary" className={classes.buttonCancel}>
                                    Cancel subscription
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={clsx(classes.section)}>
                        <span className={classes.fontDefault}>Order History</span>
                        <div>
                            <div className={clsx(classes.tableHead, classes.tableBackground)}>
                                <div className={classes.fontDefault}>Date</div>
                                <div className={classes.fontDefault}>Type</div>
                                <div className={classes.fontDefault}>Amount</div>
                                <div className={classes.fontDefault}>Receipt</div>
                            </div>
                            <div className={classes.tableRow}>
                                <div className={classes.fontDefault}>20/02/2020</div>
                                <div className={classes.fontDefault}>Silver Plan</div>
                                <div className={classes.fontDefault}>$39.99</div>
                                <div className={classes.fontDefault}>button</div>
                            </div>
                            <div className={clsx(classes.tableRow, classes.tableBackground)}>
                                <div className={classes.fontDefault}>20/02/2020</div>
                                <div className={classes.fontDefault}>Silver Plan</div>
                                <div className={classes.fontDefault}>$39.99</div>
                                <div className={classes.fontDefault}>button</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Billing;
