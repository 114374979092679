import { IContact } from "../stores/ContactStore";
import { contactAxiosInstance } from "../utils/axios.instance";
import { getLocalStorage } from "./localStorageservice";

export const searchContacts = async (pageSize: number, skip: number, search: string) => {
    const workspace = JSON.parse(getLocalStorage("workspace"));
    const response = await contactAxiosInstance({
        url: `/search`,
        method: "POST",
        data: {
            pageSize: pageSize,
            skip: skip,
            search: search,
            workspaceId: workspace._id,
        },
    });

    return response.data;
};

export const getContactProfile = async (contactId: string) => {
    const response = await contactAxiosInstance({
        url: `/${contactId}`,
        method: "GET",
    });

    return response.data;
};

export const updateContact = async (contact: IContact) => {
    const response = await contactAxiosInstance({
        url: `/${contact._id}`,
        method: "PUT",
        data: contact,
    });

    return response.data;
};
