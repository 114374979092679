import { observable, action, computed } from "mobx";
import { StoreClassInterface, RootStore } from "./RootStore";

export interface IUserError {
    fullName: string;
    email: string;
    password: string;
    confirmPassword: string;
}

export class ErrorStore implements StoreClassInterface {
    @observable userError: IUserError | null = null;
    readonly name = "ErrorStore";
    readonly rootStore: RootStore;

    constructor(root: RootStore) {
        this.rootStore = root;
    }

    @action setUserError = (error: IUserError): void => {
        this.userError = error;
    };

    @computed get userValidName(): boolean {
        return this.userError?.fullName ? true : false;
    }
}
