export const logos = {
    publer: "https://cdn.publer.io/brand/logos/logo.svg",
    kiboBlack: "https://cdn.kibo.ai/logos/kibo-black.svg",
    kiboBlackMobile: "https://cdn.kibo.ai/logos/kibo-black-mobile.svg",
    kiboMinified: "https://cdn.kibo.ai/logos/kibo-minified.svg",
    messenger: "https://cdn.kibo.ai/logos/messenger.svg",
    twitter: "https://cdn.kibo.ai/logos/twitter.svg",
    whatsapp: "https://cdn.kibo.ai/logos/whatsapp.svg",
    google: "https://cdn.kibo.ai/logos/google.svg",
    apple: "https://cdn.kibo.ai/logos/apple.svg",
    chat: "https://cdn.kibo.ai/logos/chat.svg",
    chatBig: "https://cdn.kibo.ai/logos/chat-big.svg",
    messengerBig: "https://cdn.kibo.ai/logos/messenger-big.svg",
    twitterBig: "https://cdn.kibo.ai/logos/twitter-big.svg",
    whatsappBig: "https://cdn.kibo.ai/logos/whatsapp-big.svg",
    telegramBig: "https://cdn.kibo.ai/logos/telegram-big.svg",
    instagramBig: "https://cdn.kibo.ai/logos/instagram-big.svg",
    gmbBig: "https://cdn.kibo.ai/logos/gmb-big.svg",
    capterra: "https://cdn.kibo.ai/logos/capterra.svg",
    crozdesk: "https://cdn.kibo.ai/logos/crozdesk.svg",
    googleBig: "https://cdn.kibo.ai/logos/google-big.svg",
    facebook: "https://cdn.kibo.ai/logos/facebook.svg",
    linkedin: "https://cdn.kibo.ai/logos/linkedin.svg",
};

export const icons = {
    avatar: "https://cdn.kibo.ai/icons/avatar.svg",
    timezone: "https://cdn.kibo.ai/icons/timezone.svg",
    leftArrowCircle: "https://cdn.kibo.ai/icons/left-arrow-circle.svg",
    rightArrowCircle: "https://cdn.kibo.ai/icons/right-arrow-circle.svg",
    cancelCircle: "https://cdn.kibo.ai/icons/cancel-circle.svg",
    solveMobile: "https://cdn.kibo.ai/icons/solve-mobile.svg",
    solvedMobile: "https://cdn.kibo.ai/icons/solved-mobile.svg",
    gender: "https://cdn.kibo.ai/icons/gender.svg",
    language: "https://cdn.kibo.ai/icons/language.svg",
    article: "https://cdn.kibo.ai/icons/article.svg",
    delete: "https://cdn.kibo.ai/icons/delete.svg",
    typing: "https://cdn.kibo.ai/icons/typing.svg",
    email: "https://cdn.kibo.ai/icons/email.svg",
    emailBig: "https://cdn.kibo.ai/icons/email-big.svg",
    comment: "https://kibo-assets.s3.eu-central-1.amazonaws.com/icons/comment-icon.svg",
    mention: "https://kibo-assets.s3.eu-central-1.amazonaws.com/icons/mention-icon.svg",
    starRounded: "https://kibo-assets.s3.eu-central-1.amazonaws.com/icons/star-rounded-icon.svg",
    comments: "https://kibo-assets.s3.eu-central-1.amazonaws.com/icons/comments-icon.svg",
    favourites: "https://kibo-assets.s3.eu-central-1.amazonaws.com/icons/favourites-icon.svg",
    mentions: "https://kibo-assets.s3.eu-central-1.amazonaws.com/icons/mentions-icon.svg",
    messages: "https://kibo-assets.s3.eu-central-1.amazonaws.com/icons/messages-icon.svg",
    emptyStar: "https://kibo-assets.s3.eu-central-1.amazonaws.com/icons/star-empty-icon.svg",
    fullStar: "https://kibo-assets.s3.eu-central-1.amazonaws.com/icons/star-filled-icon.svg",
};

export const banners = {
    site: "https://cdn.kibo.ai/banners/site.png",
    roundOvalTop: "https://cdn.kibo.ai/banners/round-oval-top.svg",
    workspaceTop: "https://cdn.kibo.ai/banners/workspace-top.svg",
    workspaceBottom: "https://cdn.kibo.ai/banners/workspace-bottom.svg",
    login: "https://cdn.kibo.ai/banners/login.svg",
    signup: "https://cdn.kibo.ai/banners/signup.svg",
    landingTop: "https://cdn.kibo.ai/banners/landing-top.svg",
    landingTopMobile: "https://cdn.kibo.ai/banners/landing-top-mobile.svg",
    landingBottom: "https://cdn.kibo.ai/banners/landing-bottom.svg",
    landingBottomMobile: "https://cdn.kibo.ai/banners/landing-bottom-mobile.svg",
    snooze: "https://cdn.kibo.ai/banners/snooze.svg",
};

export const sounds = {
    notification: "https://cdn.kibo.ai/sounds/notification-sound.mp3",
};

export let emojis = ["🙂", "😀", "😂", "😉", "😊", "😍", "🤔", "😐", "😕", "😥", "😭", "❤️", "🎉", "👌🏻", "👍", "🙏🏼"];
