import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { observer } from "mobx-react-lite";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";

import { useStores } from "../../../../hooks";
import { IMessage } from "../../../../stores/message/MessageStore";
import { deleteMessage } from "../../../../services/message.service";
import { copyToClipboard } from "../../../../utils/helperFunctions";
import { IConversation } from "../../../../stores/ConversationStore";
import ConfirmDialog from "../../../common/ConfirmDialog";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hide: {
            display: "none",
        },
    })
);

const ITEM_HEIGHT = 48;
const LongMenu = observer((props: { message: IMessage; selectedConv: IConversation | null }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { editorStore, messageStore, uiStore } = useStores();
    const classes = useStyles();
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMessageEdit = () => {
        if (!props.message.received) {
            editorStore.startWithContent(props.message.text || "");
            messageStore.setMessageToEdit(props.message._id || null);
            handleClose();
        }
    };
    const handleCopyMessage = () => {
        if (props.message.text) {
            copyToClipboard(props.message.text);
        }
        handleClose();
    };

    const removeMessage = async () => {
        if (!props.message.received) {
            await deleteMessage(props.message);
            messageStore.removeDeletedMessage(props.message._id || "");
            handleClose();
            uiStore.setDisplayConfimDialog(false);
        }
    };
    return (
        <div>
            <MoreVertIcon onClick={handleClick} />
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                    },
                }}
            >
                <MenuItem
                    className={clsx({
                        [classes.hide]: props.message.received || props.selectedConv?.provider !== "chat",
                    })}
                    key={"edit1"}
                    onClick={handleMessageEdit}
                >
                    Edit
                </MenuItem>
                <MenuItem key={"copy1"} onClick={handleCopyMessage}>
                    Copy
                </MenuItem>
                <MenuItem
                    key={"delete1"}
                    className={clsx({
                        [classes.hide]: props.message.received || props.selectedConv?.provider !== "chat",
                    })}
                    onClick={() => uiStore.setDisplayConfimDialog(true)}
                >
                    Delete
                </MenuItem>
            </Menu>
            <ConfirmDialog
                title={"Delete Message ?"}
                isFetching={false}
                body={"Are you sure?"}
                handleOnConfrim={() => {
                    removeMessage();
                }}
            />
        </div>
    );
});

export default LongMenu;
