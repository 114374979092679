import React, { useEffect } from "react";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import PowerOffIcon from "@material-ui/icons/PowerOff";
import FlagIcon from "@material-ui/icons/Flag";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import BuildIcon from "@material-ui/icons/Build";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CheckIcon from "@material-ui/icons/Check";

import { IConversation } from "../stores/ConversationStore";
import { logos } from "./contants";
import { TimeFormat } from "../stores/UserStore";

export const timeGapBetweenCurrentAndGivenDate = (lastMessageDate: any) => {
    const dateTomillisec = new Date(lastMessageDate).getTime();
    const timeGapAsNumber = Math.abs(Date.now() - dateTomillisec);
    let timeGapAsString = "";
    //less then 1minute
    if (timeGapAsNumber < 60000) {
        timeGapAsString = "now";
    }
    //less then 59minutes
    else if (timeGapAsNumber < 3599999) {
        timeGapAsString = Math.floor((timeGapAsNumber / (1000 * 60)) % 60).toString() + "min";
    }
    //less then 23hours and 59minutes
    else if (timeGapAsNumber < 85399999) {
        timeGapAsString = Math.floor((timeGapAsNumber / (1000 * 60 * 60)) % 60).toString() + "h";
    }
    //grater then 24hours and less then 47hours and 59minutes
    else if (timeGapAsNumber < 171799999 && timeGapAsNumber > 86400001) {
        timeGapAsString = "yesterday";
    }
    //greater then 48hours
    else {
        timeGapAsString = new Date(lastMessageDate?.toString().split("T")[0]).toLocaleString("default", {
            day: "numeric",
            month: "short",
        });
    }

    return {
        timeGapAsString,
        timeGapAsNumber,
    };
};

export const getNotifcationIconFromType = (notificationType: string) => {
    switch (notificationType) {
        case "subscription_started":
            return (
                <div style={{ borderRadius: "50%", display: "flex", padding: "3px", backgroundColor: "#1085F0" }}>
                    <FavoriteBorderOutlinedIcon htmlColor="#FFFFFF" style={{ fontSize: 15 }} />
                </div>
            );
        case "subscription_cancelled":
            return (
                <div style={{ borderRadius: "50%", display: "flex", padding: "3px", backgroundColor: "#FF576B" }}>
                    <PowerOffIcon htmlColor="#FFFFFF" style={{ fontSize: 15 }} />
                </div>
            );
        case "subscription_ended":
            return (
                <div style={{ borderRadius: "50%", display: "flex", padding: "3px", backgroundColor: "#FF8863" }}>
                    <FlagIcon htmlColor="#FFFFFF" style={{ fontSize: 15 }} />
                </div>
            );
        case "subscription_paused":
            return (
                <div style={{ borderRadius: "50%", display: "flex", padding: "3px", backgroundColor: "#FF576B" }}>
                    <PauseCircleFilledIcon htmlColor="#FFFFFF" style={{ fontSize: 15 }} />
                </div>
            );
        case "reauth":
            return (
                <div style={{ borderRadius: "50%", display: "flex", padding: "3px", backgroundColor: "#FF8863" }}>
                    <BuildIcon htmlColor="#FFFFFF" style={{ fontSize: 15 }} />
                </div>
            );
        case "bonus_declined":
            return (
                <div style={{ borderRadius: "50%", display: "flex", padding: "3px", backgroundColor: "#FF576B" }}>
                    <NotInterestedIcon htmlColor="#FFFFFF" style={{ fontSize: 15 }} />
                </div>
            );
        case "payment_fail":
            return (
                <div style={{ borderRadius: "50%", display: "flex", padding: "3px", backgroundColor: "#FF576B" }}>
                    <CreditCardIcon htmlColor="#FFFFFF" style={{ fontSize: 15 }} />
                </div>
            );
        case "product_promotion":
            return (
                <div style={{ borderRadius: "50%", display: "flex", padding: "3px", backgroundColor: "#1085F0" }}>
                    <LocalOfferIcon htmlColor="#FFFFFF" style={{ fontSize: 15 }} />
                </div>
            );
        case "product_idea":
            return (
                <div style={{ borderRadius: "50%", display: "flex", padding: "3px", backgroundColor: "#1085F0" }}>
                    <EmojiObjectsIcon htmlColor="#FFFFFF" style={{ fontSize: 15 }} />
                </div>
            );
        case "product_update":
            return (
                <div style={{ borderRadius: "50%", display: "flex", padding: "3px", backgroundColor: "#1085F0" }}>
                    <EmojiPeopleIcon htmlColor="#FFFFFF" style={{ fontSize: 15 }} />
                </div>
            );
        default:
            break;
    }
};

export const useCallbackOutsideElement = (ref: any, callback: Function) => {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
};

export const badgeProvider = (conversationProvider: string, large?: boolean) => {
    let size = { width: "16px", height: "16px" };
    if (large) {
        size.width = "25px";
        size.height = "25px";
    }
    switch (conversationProvider) {
        case "facebook":
            return (
                <div
                    style={{
                        borderRadius: "50%",
                        display: "flex",
                        padding: "3px",
                        backgroundColor: "#1085F0",
                    }}
                >
                    <img src={logos.messenger} style={{ width: size.width, height: size.height }} />
                </div>
            );
        case "twitter":
            return (
                <div
                    style={{
                        borderRadius: "50%",
                        display: "flex",
                        padding: "3px",
                        backgroundColor: "#55ACEE",
                    }}
                >
                    <img src={logos.twitter} style={{ width: size.width, height: size.height }} />
                </div>
            );
        case "whatsapp":
            return (
                <div>
                    <img src={logos.whatsapp} style={{ width: size.width, height: size.height }} />
                </div>
            );
        case "chat":
            return (
                <div>
                    <img src={logos.chat} style={{ width: size.width, height: size.height }} />
                </div>
            );
        default:
            break;
    }
};

export const conversationStatusIcon = (conversation: IConversation) => {
    if (conversation.unreadMessages > 0) {
        return (
            <div
                style={{
                    borderRadius: "50%",
                    display: "flex",
                    padding: "3px",
                    backgroundColor: "#1085F0",
                    width: "21px",
                    height: "21px",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                }}
            >
                {conversation.unreadMessages}
            </div>
        );
    } else if (conversation.unreadMessages === 0 && !conversation.resolved) {
        return (
            <div
                style={{
                    borderRadius: "50%",
                    display: "flex",
                    backgroundColor: "transparent",
                    border: "2.5px solid #1085F0",
                    width: "21px",
                    height: "21px",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <ArrowForwardIosIcon htmlColor="#1085F0" style={{ fontSize: 12 }} />
            </div>
        );
    } else if (conversation.unreadMessages === 0 && conversation.resolved) {
        return (
            <div style={{ borderRadius: "50%", display: "flex", padding: "3px", backgroundColor: "#4FDA6E" }}>
                <CheckIcon htmlColor="#FFFFFF" style={{ fontSize: 15 }} />
            </div>
        );
    }
};

const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export function timeFormat(time: Date, format: string) {
    let parsedTime = new Date(time);
    var hour = parsedTime.getHours();
    var minutes = parsedTime.getMinutes();
    var month = parsedTime.getMonth();
    var date = parsedTime.getDate();
    var suffix = "";
    if (format !== TimeFormat.Military) {
        suffix = hour >= 12 ? " pm" : " am";
        hour = ((hour + 11) % 12) + 1;
        return monthNames[month] + " " + date + " at " + ("0" + hour).substr(-2) + ":" + minutes + suffix;
    }
    return monthNames[month] + " " + date + " at " + hour + ":" + minutes;
}

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text);
}
