import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import { useStores } from "../../hooks";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import CardMedia from "@material-ui/core/CardMedia";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import DescriptionIcon from "@material-ui/icons/Description";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "row-reverse",
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(2.5),
            position: "relative",
        },
        media: {
            height: "100px",
            width: "100px",
            borderRadius: "16px",
        },
        videoIcon: {
            position: "absolute",
            color: "white",
        },
        progresIcon: {
            marginLeft: theme.spacing(1),
        },
        fileDescriptionWrapper: {
            maxWidth: 80,
        },
        customBox: {
            display: "-webkit-box",
            boxOrient: "vertical",
            lineClamp: 2,
            wordBreak: "break-all",
            overflow: "hidden",
        },
    })
);

const FilePreview = observer(
    (props: {
        selectedConversationId: string;
        index: number;
        currentMedia: any;
        previewUploadedMedia: any;
        removeUploadedMedia: any;
    }) => {
        const classes = useStyles();
        const { selectedConversationId, index, currentMedia, removeUploadedMedia, previewUploadedMedia } = props;
        const { messageStore } = useStores();
        //const currentMedia = media;
        const mediaPath =
            currentMedia.url ||
            (currentMedia.type === "VIDEO"
                ? "https://cdn.kibo.ai/banners/image-placeholder.png"
                : currentMedia.localUrl);
        return (
            <Box className={classes.root}>
                <Box
                    className={clsx({
                        [classes.progresIcon]: currentMedia.loading,
                    })}
                >
                    {currentMedia.loading ? (
                        <CircularProgress color="primary" size={20} />
                    ) : (
                        <HighlightOffIcon
                            fontSize="small"
                            onClick={() => removeUploadedMedia(currentMedia)}
                            cursor="pointer"
                        />
                    )}
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => previewUploadedMedia({ imageFrom: "messageInput", imageUrl: mediaPath })}
                >
                    {currentMedia.type === "VIDEO" && (
                        <Box className={classes.videoIcon}>
                            <PlayCircleFilledIcon fontSize="large" />
                        </Box>
                    )}
                    {currentMedia.type === "FILE" ? (
                        <Box
                            className={classes.media}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            bgcolor="#DFDFDF"
                            flexDirection="column"
                        >
                            <DescriptionIcon color="primary" />
                            <Box component="div" className={classes.customBox}>
                                <div className={classes.fileDescriptionWrapper}>{currentMedia.name}</div>
                            </Box>
                        </Box>
                    ) : (
                        <CardMedia
                            className={classes.media}
                            image={currentMedia.thumbnail ? currentMedia.thumbnail : mediaPath}
                            title={currentMedia.name}
                        />
                    )}
                </Box>
            </Box>
        );
    }
);

export default FilePreview;
