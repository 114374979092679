import React, { useState } from "react";
import { createStyles, makeStyles, Theme, ListItemAvatar, Badge, Avatar } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FacebookIcon from "@material-ui/icons/Facebook";
import { observer } from "mobx-react-lite";

import { useStores } from "../../hooks";
import { INotification } from "../../stores/NotificationsStore";
import { timeGapBetweenCurrentAndGivenDate, getNotifcationIconFromType } from "../../utils/helperFunctions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            justifyContent: "space-between",
            padding: theme.spacing(2),
            "&:hover": {
                backgroundColor: "#f5f5f5",
            },
            cursor: "pointer",
        },
        bodyWrapper: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: "250px",
        },
        deleteWrapper: {
            width: "60px",
            display: "flex",
            justifyContent: "flex-end",
        },
        avatarWrapper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        titleWrapper: {
            color: "#737373",
            fontWeight: 700,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        descriptionWrapper: {
            fontSize: "13px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        timeWrapper: {
            fontSize: "10px",
            color: "#A0A0A0",
            fontStyle: "italic",
            fontWeight: "bold",
        },
    })
);

const Row = observer((props: { notification: INotification }) => {
    const classes = useStyles();
    const [toggleHover, setToggleHover] = useState(false);
    const { notification } = props;
    const { notificationsStore } = useStores();

    const handleNotificationClick = (path: string) => {
        if (path !== "") {
            window.location.href = path;
        }
    };

    return (
        <div
            className={classes.root}
            onMouseLeave={() => setToggleHover(false)}
            onMouseEnter={() => setToggleHover(true)}
            onClick={() => {
                handleNotificationClick(notification.url);
            }}
        >
            <div className={classes.avatarWrapper}>
                <ListItemAvatar>
                    <Badge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        badgeContent={getNotifcationIconFromType(notification.type)}
                    >
                        <Avatar src={notification.thumbnail} />
                    </Badge>
                </ListItemAvatar>
            </div>
            <div className={classes.bodyWrapper}>
                <span className={classes.titleWrapper}>{notification.title}</span>
                <span className={classes.descriptionWrapper}>{notification.description}</span>
                <span className={classes.timeWrapper}>
                    {timeGapBetweenCurrentAndGivenDate(notification.updatedAt).timeGapAsString}
                </span>
            </div>
            <div className={classes.deleteWrapper}>
                {toggleHover && (
                    <HighlightOffIcon
                        onClick={(e) => {
                            e.stopPropagation();
                            notificationsStore.removeNotifications(notification._id);
                        }}
                    />
                )}
            </div>
        </div>
    );
});

export default Row;
