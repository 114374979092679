import { userAxiosInstance } from "../utils/axios.instance";
import { getLocalStorage } from "./localStorageservice";

export const getFeedbackToken = async () => {
    const user = JSON.parse(getLocalStorage("user"));
    const response = await userAxiosInstance({
        url: `/nolt-token`,
        method: "POST",
        data: user,
    });
    return response.data;
};
