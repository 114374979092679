import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

import { icons } from "../../../../utils/contants";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(0.5),
        },
        flex: {
            display: "flex",
        },
        text: {
            paddingLeft: theme.spacing(0.5),
        },
    })
);

const ConversationType = (props) => {
    const { type } = props;
    const classes = useStyles();

    const renderSwitch = (param) => {
        switch (param) {
            case "REVIEW":
                return (
                    <div className={classes.flex}>
                        <div className={classes.text}>
                            <img src={icons.starRounded} />
                        </div>{" "}
                        <div className={classes.text}>gave you a review</div>
                    </div>
                );
            case "COMMENT":
                return (
                    <div className={classes.flex}>
                        <div>
                            <img src={icons.comment} />
                        </div>
                        <div className={classes.text}>wrote a new comment</div>
                    </div>
                );
            case "MENTION":
                return (
                    <div className={classes.flex}>
                        <div className={classes.text}>
                            <img src={icons.mention} />
                        </div>
                        <div className={classes.text}>mention you on a thread</div>
                    </div>
                );
            default:
                return <></>;
        }
    };
    return <div className={classes.root}>{renderSwitch(type)}</div>;
};

export default ConversationType;
