import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress, useMediaQuery, useTheme } from "@material-ui/core";

import SearchBar from "./SearchBar";
import ChannelDialog from "./ChannelDialog";
import Channels from "./Channels";
import ForwardEmailDialog from "./email/ForwardEmailDialog";
import DeleteChannel from "./DeleteChannel";
import { withRouter } from "react-router-dom";
import { error } from "../../../services/toast.service";
import { useStores } from "../../../hooks";
import { IChannel } from "../../../stores/ChannelStore";
import { observer } from "mobx-react-lite";
import WhatsappKey from "./whatsapp/WhatsappKey";
import ChatId from "./chat/ChatId";
import TwitterCredentials from "./twitter/TwitterCredentials";
import AccountCard from "./AccountCard";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: "100%",
            marginRight: theme.spacing(4),
            overflowY: "hidden",
            height: "100%",
        },
        headerContainer: {
            marginBottom: "5px",
            [theme.breakpoints.up("md")]: {
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                marginRight: theme.spacing(2),
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "white",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            maxHeight: "64px",
        },
        addBtn: {
            border: "1px solid #000000",
            boxSizing: "border-box",
            borderRadius: "30px",
            fontWeight: 700,
            textTransform: "unset",
        },
        accountsWrapper: {
            display: "grid",
            "grid-template-columns": "1fr 1fr 1fr 1fr 1fr",
            width: "100%",
            [theme.breakpoints.down("md")]: {
                "grid-template-columns": "1fr 1fr 1fr",
            },
            [theme.breakpoints.down("sm")]: {
                "grid-template-columns": "1fr 1fr",
                paddingBottom: "58px",
            },
            [theme.breakpoints.down("xs")]: {
                "grid-template-columns": "1fr",
            },
        },
        scrollBar: {
            overflowY: "auto",
            display: "flex",
            height: "calc(100% - 64px)",
            marginRight: "-4px",
            marginLeft: "-8px",
            width: "100%",
            [theme.breakpoints.down("sm")]: {
                marginRight: "0",
                marginLeft: "0",
            },
            "&::-webkit-scrollbar": {
                width: "12px",
                height: "10px",
                marginRight: "5px",
            },
            "&::-webkit-scrollbar-track": {
                background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
                background: "#A0A0A0",
                borderRadius: "7px",
                backgroundClip: "padding-box",
                border: "3px solid transparent",
                minHeight: "50px",
                "&:hover": {
                    background: "#A0A0A0",
                    backgroundClip: "padding-box",
                },
            },
            "& .infinite-scroll-component__outerdiv": {
                width: "100%",
            },
        },
        loading: {
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            margin: theme.spacing(1),
        },
        test: {
            ".infinite-scroll-component__outerdiv": {
                width: "100%",
            },
        },
    })
);

const ChannelList = observer((props: any) => {
    const addedAccounts = props.location.state;
    const classes = useStyles();
    const theme = useTheme();
    const { channelStore, tourStore } = useStores();
    const { accounts, newAccounts, selectedAccount } = channelStore;
    const desktop = useMediaQuery(theme.breakpoints.up("md"));

    const [openChannelDialog, setOpenChannelDialog] = useState<boolean>(false);
    const [openForwardEmail, setOpenForwardEmailDialog] = useState<boolean>(false);
    const [openChatDialog, setOpenChatDialog] = useState<boolean>(false);
    const [whatsappDialogOpen, setWhatsappDialogOpen] = useState<boolean>(false);
    const [openAccountsDialog, setOpenAccountsDialog] = useState<boolean>(false);
    const [openDeleteChannelDialog, setOpenDeleteChannelDialog] = useState<boolean>(false);
    const [openTwitterCredentials, setOpenTwitterCredentials] = useState<boolean>(false);

    const addAccounts = () => {
        if (addedAccounts) {
            if (addedAccounts.length > 0) {
                channelStore.addAccounts(addedAccounts);
                setOpenAccountsDialog(true);
                window.history.replaceState(null, "");
            } else {
                setOpenAccountsDialog(true);
                window.history.replaceState(null, "");
            }
        }
    };
    const openChannel = () => {
        setOpenChannelDialog(true);
        if (tourStore.isTourOpen) {
            setTimeout(() => {
                tourStore.nextStep();
            }, 10);
        }
    };
    useEffect(() => {
        channelStore.loadAccountsByWorkspaceId();
        addAccounts();
    }, []);

    const addNewAccount = (account: IChannel) => {
        channelStore.setAccounts(channelStore.accounts.concat(account));
        setOpenAccountsDialog(false);
        setOpenChannelDialog(false);
    };

    const reauthenticate = (account: IChannel) => {
        channelStore.setSelectedAccount(account);
        switch (account.provider) {
            case "email":
                setOpenForwardEmailDialog(true);
                break;
            case "whatsapp":
                setWhatsappDialogOpen(true);
                break;
            case "chat":
                setOpenChatDialog(true);
                break;
            case "twitter":
                setOpenTwitterCredentials(true);
                break;
            case "facebook":
                window.open(
                    `${process.env.REACT_APP_FACEBOOK_SERVICE}/facebook/login/true/${account.socialId}`,
                    "_self"
                );
                break;
        }
    };

    const updateWhatsappAccount = (newAccount: IChannel) => {
        const newAccounts = channelStore.accounts.map((account: IChannel) => {
            if (account._id === newAccount._id) {
                return newAccount;
            }
            return account;
        });
        channelStore.setAccounts(newAccounts);
        setWhatsappDialogOpen(false);
    };

    const openDialog = (value: boolean): void => {
        setOpenDeleteChannelDialog(value);
    };
    return (
        <div className={classes.root} id="scrollableDiv">
            <div className={classes.headerContainer}>
                <SearchBar />
                <Button
                    className={classes.addBtn}
                    onClick={() => openChannel()}
                    startIcon={<AddCircleOutlineRoundedIcon />}
                    variant="outlined"
                    id="add-channel"
                >
                    Add {desktop && "channel"}
                </Button>
            </div>
            <div className={classes.scrollBar} id="scrollableAccountsDiv">
                <InfiniteScroll
                    dataLength={accounts.length}
                    next={() => channelStore.loadAccountsByWorkspaceId(accounts.length)}
                    hasMore={accounts.length < channelStore.total}
                    loader={<CircularProgress className={classes.loading} />}
                    scrollableTarget="scrollableAccountsDiv"
                    style={{ display: "flex", flexDirection: "column", overflow: "hidden", width: "100%" }}
                    className={classes.test}
                >
                    <div className={classes.accountsWrapper}>
                        {accounts.map((account: IChannel, index: number) => (
                            <React.Fragment key={account._id}>
                                <AccountCard
                                    account={account}
                                    openDialog={openDialog}
                                    reauthenticate={reauthenticate}
                                />
                            </React.Fragment>
                        ))}
                    </div>
                </InfiniteScroll>
            </div>
            <ChannelDialog open={openChannelDialog} setOpen={setOpenChannelDialog} addNewAccount={addNewAccount} />
            <Channels
                open={openAccountsDialog}
                setOpen={setOpenAccountsDialog}
                accounts={newAccounts}
                addNewAccount={addNewAccount}
            />

            <DeleteChannel
                open={openDeleteChannelDialog}
                setOpen={setOpenDeleteChannelDialog}
                account={selectedAccount as IChannel}
            />
            <ForwardEmailDialog
                open={openForwardEmail}
                setOpen={setOpenForwardEmailDialog}
                forwardEmail={(selectedAccount && selectedAccount.forwardEmail) || ""}
            />
            <ChatId
                open={openChatDialog}
                setOpen={setOpenChatDialog}
                chatId={(selectedAccount && selectedAccount.chatId) || ""}
            />
            <WhatsappKey
                open={whatsappDialogOpen}
                setOpen={setWhatsappDialogOpen}
                isEdited={true}
                addNewAccount={(whatsappAccount: IChannel) => updateWhatsappAccount(whatsappAccount)}
            />
            <TwitterCredentials
                open={openTwitterCredentials}
                setOpen={setOpenTwitterCredentials}
                isEdited={true}
                selectedAccount={selectedAccount}
            />
        </div>
    );
});

export default withRouter(ChannelList);
