import React, { useEffect, useRef } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import InfiniteScroll from "react-infinite-scroll-component";

import { useStores } from "../../hooks";
import Row from "./Row";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: "400px",
            width: "100%",
            [theme.breakpoints.down("sm")]: {
                right: "2%",
            },
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: "5%",
            right: "7%",
            backgroundColor: "white",
            boxShadow: " 0px 0px 12px rgba(0, 0, 0, 0.25)",
            zIndex: 999,
            borderRadius: theme.spacing(1),
            minHeight: 100,
        },
        actionsWrapper: {
            display: "flex",
            justifyContent: "space-between",
            padding: theme.spacing(2),
        },
        alignHorizontally: {
            display: "flex",
        },
        actionWrapper: {
            marginRight: theme.spacing(2),
            cursor: "pointer",
        },
        selectedAction: {
            fontSize: "15px",
            fontWeight: 600,
        },
        loaderWrapper: {
            display: "flex",
            justifyContent: "center",
            marginBottom: theme.spacing(2),
        },
        bodyWrapper: {
            maxHeight: 350,
            overflowY: "auto",
            width: "100%",
        },
        clearAllWrapper: {
            cursor: "pointer",
        },
        emptyNotificationsWrapper: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: theme.spacing(2),
            alignItems: "center",
        },
    })
);

const Notifications = observer(() => {
    const classes = useStyles();
    const { notificationsStore } = useStores();

    useEffect(() => {
        notificationsStore.getNotifications(0, "all");
    }, []);
    return (
        <div className={classes.root}>
            <div className={classes.actionsWrapper}>
                <div className={classes.alignHorizontally}>
                    <div
                        className={clsx(classes.actionWrapper, {
                            [classes.selectedAction]: notificationsStore.notificationsType === "all",
                        })}
                        onClick={() => notificationsStore.switchNotificationsType("all")}
                    >
                        Recent
                    </div>
                    <div
                        className={clsx(classes.actionWrapper, {
                            [classes.selectedAction]: notificationsStore.notificationsType !== "all",
                        })}
                        onClick={() => notificationsStore.switchNotificationsType("product_update")}
                    >
                        Updates({notificationsStore.updatedTotal})
                    </div>
                </div>
                <div className={classes.clearAllWrapper} onClick={() => notificationsStore.removeNotifications()}>
                    Clear All
                </div>
            </div>
            <Box id="scrollableNotificationsDiv" className={classes.bodyWrapper} display="unset">
                <InfiniteScroll
                    dataLength={notificationsStore.notifications.length}
                    next={() =>
                        notificationsStore.getNotifications(
                            notificationsStore.notifications.length,
                            notificationsStore.notificationsType
                        )
                    }
                    hasMore={notificationsStore.total > notificationsStore.notifications.length}
                    loader={
                        <div className={classes.loaderWrapper}>
                            <CircularProgress />
                        </div>
                    }
                    style={{ display: "flex", flexDirection: "column", width: "100%", overflow: "hidden" }}
                    inverse={false}
                    scrollableTarget="scrollableNotificationsDiv"
                >
                    {notificationsStore.notifications.length === 0 && notificationsStore.isFetching ? (
                        <div className={classes.loaderWrapper}>
                            <CircularProgress />
                        </div>
                    ) : (
                        notificationsStore.notifications.map((element: any) => <Row notification={element} />)
                    )}
                    {notificationsStore.notifications.length === 0 && !notificationsStore.isFetching && (
                        <div className={classes.emptyNotificationsWrapper}>
                            <CheckCircleOutlineIcon />
                            <span>All caught up!</span>
                        </div>
                    )}
                </InfiniteScroll>
            </Box>
        </div>
    );
});

export default Notifications;
