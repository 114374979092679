import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import Modal from "../../common/Modal";
import TwitterCredentials from "./twitter/TwitterCredentials";
import Email from "./email/Email";
import WhatsappKey from "./whatsapp/WhatsappKey";
import { IChannel } from "../../../stores/ChannelStore";
import Chat from "./chat/Chat";
import { icons, logos } from "../../../utils/contants";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        channel: {
            padding: "20px 30px",
            backgroundColor: theme.palette.background.default,
            display: "flex",
            flexDirection: "column",
            borderRadius: "20px",
            fontWeight: 700,
            alignItems: "center",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "#F2F9FF",
                color: theme.palette.primary.main,
            },
            [theme.breakpoints.down("sm")]: {
                padding: "10px 20px",
            },
        },
        iconWrapper: {
            padding: theme.spacing(2),
            borderRadius: "20px",
            width: "69px",
            height: "69px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.background.paper,
            filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1)), drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.07)), drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.06))",
        },
        container: {
            display: "grid",
            gap: "1em",
            [theme.breakpoints.up("md")]: {
                "grid-template-columns": "1fr 1fr 1fr 1fr",
            },
            [theme.breakpoints.down("sm")]: {
                "grid-template-columns": "1fr 1fr 1fr",
            },
            [theme.breakpoints.down("xs")]: {
                "grid-template-columns": "1fr 1fr",
            },
        },
        name: {
            paddingTop: "16px",
        },
        channelHover: {
            backgroundColor: "#F2F9FF",
            color: theme.palette.primary.main,
        },
    })
);

const ChannelDialog = (props: { open: boolean; setOpen: Function; addNewAccount: Function }) => {
    const classes = useStyles();

    const channels = [
        {
            icon: <img src={logos.chatBig} />,
            name: "Chat",
            onClick: () => setChatDialogOpen(true),
        },
        {
            icon: <img src={logos.messengerBig} />,
            name: "Facebook",
            onClick: () => createFacebookChannel(),
        },
        {
            icon: <img src={logos.twitterBig} />,
            name: "Twitter",
            onClick: () => setOpen(true),
        },
        {
            icon: <img src={icons.emailBig} />,
            name: "Email",
            onClick: () => setEmailDialogOpen(true),
        },
        {
            icon: <img src={logos.whatsappBig} />,
            name: "WhatsApp",
            onClick: () => setWhatsappDialogOpen(true),
        },
        {
            icon: <img src={logos.telegramBig} />,
            name: "Coming Soon",
            onClick: () => {},
        },
        {
            icon: <img src={logos.instagramBig} />,
            name: "Coming Soon",
            onClick: () => {},
        },
        {
            icon: <img src={logos.gmbBig} />,
            name: "Coming Soon",
            onClick: () => {},
        },
    ];

    const [open, setOpen] = useState(false);
    const [emailDialogOpen, setEmailDialogOpen] = useState(false);
    const [chatDialogOpen, setChatDialogOpen] = useState(false);
    const [whatsappDialogOpen, setWhatsappDialogOpen] = useState(false);

    const createFacebookChannel = () => {
        window.open(`${process.env.REACT_APP_FACEBOOK_SERVICE}/facebook/login/false/null`, "_self");
    };

    const addEmail = (email: string) => {
        props.addNewAccount(email);
        setEmailDialogOpen(false);
    };

    const addWhatsappAccount = (whatsappAccount: IChannel) => {
        props.addNewAccount(whatsappAccount);
        setWhatsappDialogOpen(false);
    };

    const addChatAccount = (chatAccount: IChannel) => {
        props.addNewAccount(chatAccount);
        setChatDialogOpen(false);
    };

    return (
        <div>
            <Modal open={props.open} onClose={() => props.setOpen(false)} hideActions={true}>
                <div className={classes.container} id="end-tour">
                    {channels.map((channel, index) => {
                        return (
                            <div key={index} onClick={channel.onClick} className={classes.channel}>
                                <div className={classes.iconWrapper}>{channel.icon}</div>
                                <div className={classes.name}>{channel.name}</div>
                            </div>
                        );
                    })}
                </div>
            </Modal>

            <TwitterCredentials open={open} setOpen={setOpen} isEdited={false} selectedAccount={null} />

            <Email
                open={emailDialogOpen}
                setOpen={setEmailDialogOpen}
                addNewAccount={(email: string) => addEmail(email)}
            />
            <WhatsappKey
                open={whatsappDialogOpen}
                setOpen={setWhatsappDialogOpen}
                isEdited={false}
                addNewAccount={(whatsappAccount: IChannel) => addWhatsappAccount(whatsappAccount)}
            />
            <Chat
                open={chatDialogOpen}
                setOpen={setChatDialogOpen}
                addNewAccount={(chatAccount: IChannel) => addChatAccount(chatAccount)}
            />
        </div>
    );
};

export default ChannelDialog;
