import axios from "axios";

export const userAxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_WORKSPACE_SERVICE}/users`,
    withCredentials: true,
});

export const emailAxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_EMAIL_SERVICE}/email/`,
    withCredentials: true,
});

export const googleAxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_GOOGLE_SERVICE}/google/`,
    withCredentials: true,
});

export const workspaceAxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_WORKSPACE_SERVICE}/workspaces/`,
    withCredentials: true,
});

export const userWorkspaceAxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_WORKSPACE_SERVICE}/user-workspace/`,
    withCredentials: true,
});

export const accountAxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_WORKSPACE_SERVICE}/accounts/`,
    withCredentials: true,
});

export const twitterAxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_TWITTER_SERVICE}/twitter/`,
    withCredentials: true,
});

export const facebookAxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_FACEBOOK_SERVICE}/facebook/`,
    withCredentials: true,
});

export const conversationAxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_WORKSPACE_SERVICE}/conversations/`,
    withCredentials: true,
});

export const messageAxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_WORKSPACE_SERVICE}/messages/`,
    withCredentials: true,
});

export const uploadAxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_UPLOAD_SERVICE}/uploads/`,
    withCredentials: true,
});

export const contactAxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_WORKSPACE_SERVICE}/contacts/`,
    withCredentials: true,
});

export const notificationsAxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_WORKSPACE_SERVICE}/notifications/`,
    withCredentials: true,
});

export const whatsappAxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_WHATSAPP_SERVICE}/whatsapp/`,
    withCredentials: true,
});
