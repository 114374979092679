import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import Typography from "@material-ui/core/Typography";

import NavBar from "../common/NavBar";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.default,
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        display: "flex",
        justifyContent: "center",
        " &::-webkit-scrollbar": {
            width: "12px",
            height: "10px",
            marginRight: "5px",
        },
        "&::-webkit-scrollbar-track": {
            background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#A0A0A0",
            borderRadius: "7px",
            backgroundClip: "padding-box",
            border: "3px solid transparent",
            minHeight: "50px",
            "&:hover": {
                background: "#A0A0A0",
                backgroundClip: "padding-box",
            },
        },
    },
    container: {
        display: "grid",
        gridTemplateColumns: "1fr",
        height: "100%",
        gap: "3em",
        padding: "40px",
        maxWidth: "1000px",
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "7px",
        marginLeft: "30px",
        marginRight: "30px",
    },
}));

const Terms = (props: any) => {
    const classes = useStyles();

    return (
        <div>
            <div>
                <NavBar />
            </div>
            <div className={classes.root}>
                <div className={classes.container}>
                    <div>
                        <Typography variant="h4" gutterBottom>
                            Terms of Service
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Revised: 22/07/2021
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            1. What does this Terms of Service cover?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Kibo ("the Service" or "the Website") is provided by Kalemi Code ("we" or "us" or "our").
                            The following terms and conditions govern all use of the Kibo website. The Website is
                            offered subject to your acceptance without modification of all of the terms and conditions
                            contained herein and all other operating rules, policies (including, without limitation,
                            Kibo's <a href="/privacy">Privacy Policy</a>) and procedures that may be published from time
                            to time on this Site by Us (collectively, the "Agreement"). Please read this Agreement
                            carefully before accessing or using the Website. By accessing or using any part of the web
                            site, you agree to become bound by the terms and conditions of this agreement. If you do not
                            agree to all the terms and conditions of this agreement, then you may not access the Website
                            or use any services. The Website is available only to individuals who are at least 13 years
                            old.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            2. Access to your Kibo Account
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Subject to the terms and conditions of this Agreement, the Service is solely for User's
                            personal use. As used herein, "User" includes also Our paying customers ("Customers"). This
                            Agreement sets forth certain terms and conditions that apply to Customers, but not other
                            Users. In the event any Customer-specific terms or conditions conflict with any of the terms
                            or conditions that apply to Users generally, the Customer-specific terms/conditions shall
                            control. We may change, suspend or discontinue the Services at any time, including the
                            availability of any feature, database, or content. We may also impose limits on certain
                            features and services or restrict User's access to parts or all of the Services without
                            notice or liability. User certifies to us that if User is an individual (i.e., not a
                            corporation) User is at least 13 years of age. User also certifies that it is legally
                            permitted to use the Service, and takes full responsibility for the selection and use of the
                            Service. This Agreement is void where prohibited by law, and the right to access the Service
                            is revoked in such jurisdictions. User shall be responsible for obtaining and maintaining
                            any equipment or ancillary services needed to connect to, access the Service, including,
                            without limitation, modems, hardware and software. User shall be responsible for ensuring
                            that such equipment or ancillary services are compatible with the Service.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            3. Restrictions
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            User shall not, nor permit anyone else to, directly or indirectly: (i) reverse engineer,
                            disassemble, decompile or otherwise attempt to discover the source code or underlying
                            algorithms of all or any part of the Service (except that this restriction shall not apply
                            to the limited extent restrictions on reverse engineering are prohibited by applicable local
                            law); (ii) modify or create derivatives of any part of the Service; (iii) rent, lease, or
                            use the Service for timesharing or service bureau purposes; or (iv) remove or obscure any
                            proprietary notices on the Service. As between the parties, Company shall own all title,
                            ownership rights, and intellectual property rights in and to the Service, and any copies or
                            portions thereof. User shall not use any "deep-link", "page-scrape", "robot", "spider" or
                            other automatic device, program, algorithm or methodology, or any similar or equivalent
                            manual process, to access, acquire, copy or monitor any portion of the Service or any
                            Content, or in any way reproduce or circumvent the navigational structure or presentation of
                            the Service or any Content, to obtain or attempt to obtain any materials, documents or
                            information through any means not purposely made available through the Service. We reserve
                            the right to bar any such activity. User shall not attempt to gain unauthorized access to
                            any portion or feature of the Service, or any other systems or networks connected to the
                            Service or to any Kibo server, or to any of the services offered on or through the Service,
                            by hacking, password "mining", or any other illegitimate means. User shall not probe, scan
                            or test the vulnerability of the Service or any network connected to the Service, nor breach
                            the security or authentication measures on the Service or any network connected to the
                            Service. User shall not take any action that imposes an unreasonable or disproportionately
                            large load on the infrastructure of the Service or Kibo's systems or networks, or any
                            systems or networks connected to the Service or to Kibo. User shall not use any device,
                            software or routine to interfere or attempt to interfere with the proper working of the
                            Service or any transaction being conducted on the Service, or with any other person's use of
                            the Service. User shall not use the Service or any Content for any purpose that is unlawful
                            or prohibited by this Agreement.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            4. Fees and Payment
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Optional paid services are available on the Website. Our order process is conducted by our
                            online resellers{" "}
                            <a href="https://payproglobal.com/" target="_blank">
                                PayProGlobal.com
                            </a>{" "}
                            or{" "}
                            <a href="https://paddle.com/" target="_blank">
                                Paddle.com
                            </a>{" "}
                            depending on your location.{" "}
                            <a href="https://payproglobal.com/" target="_blank">
                                PayPro Global
                            </a>{" "}
                            and{" "}
                            <a href="https://paddle.com/" target="_blank">
                                Paddle
                            </a>{" "}
                            are the Merchant of Record for all our orders. They provide all customer service inquiries
                            and handle returns.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            By selecting a paid service you agree to pay US the subscription fees indicated for that
                            service. Payments will be charged on the day you sign up for a paid service and will cover
                            the use of that service for a monthly period. Paid service fees may be fully or partially
                            refundable in specific scenarios and it is US who determine which Customer is eligible.
                            Prices for new customers, due to cost increases or other factors, may change at anytime
                            without prior notice. For existing customers, prices will never change for the whole
                            duration of the subscription. Prices do not include VAT.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            5. Registration; Security
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            As a condition to using certain products and services of the Service, User may be required
                            to register with Kibo using a social profile or with an email address and password. We
                            reserve the right to refuse registration of, or cancel a Kibo User in its discretion. User
                            shall be responsible for maintaining the confidentiality of User's Kibo account information.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            6. Termination
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We may terminate your access to all or any part of the Website at any time, with or without
                            cause, with or without notice, effective immediately. If you wish to terminate this
                            Agreement or your Kibo account (if you have one), you may simply discontinue using the
                            Website or delete your Kibo account. Notwithstanding the foregoing, if you have a paid
                            account, such account can only be terminated by us if you materially breach this Agreement
                            and fail to cure such breach within seven (7) days from Our notice to you thereof; provided
                            that, We can terminate the Website immediately as part of a general shut down of our
                            service. All provisions of this Agreement which by their nature should survive termination
                            shall survive termination, including, without limitation, ownership provisions, warranty
                            disclaimers, indemnity and limitations of liability.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            7. Content
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            User agrees that all content and materials (collectively, "Content") delivered via the
                            Service or otherwise made available by Us at the Site are protected by copyrights,
                            trademarks, service marks, patents, trade secrets or other proprietary rights and laws. We
                            does not support the distribution of pornographic content. Except as expressly authorized by
                            Us in writing, User agrees not to sell, license, rent, modify, distribute, copy, reproduce,
                            transmit, publicly display, publicly perform, publish, adapt, edit or create derivative
                            works from such materials or content.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            8. Changes
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We reserve the right, at its sole discretion, to modify or replace any part of this
                            Agreement. It is your responsibility to check this Agreement periodically for changes. Your
                            continued use of or access to the Website following the posting of any changes to this
                            Agreement constitutes acceptance of those changes. We may also, in the future, offer new
                            services and/or features through the Website (including, the release of new tools and
                            resources). Such new features and/or services shall be subject to the terms and conditions
                            of this Agreement. We may also change prices of the Paid plans at any time without notice.
                            Changed prices will only affect new Customers. Current Customers will keep paying the
                            monthly fee they were asked to at the beginning of the subscription, despite the possible
                            change of prices or features provided in their plan.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            9. Warranties
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            If User is a Customer, We make the following warranty to Customer: We shall use commercially
                            reasonable efforts consistent with prevailing industry standards to provide the services in
                            a professional and workmanlike manner that is free of defects. Customer's sole remedy, and
                            our exclusive liability, for defects in the Service shall be for us to use commercially
                            reasonable efforts to promptly correct such defects. Customer represents and warrants that:
                            (i) with respect to all information it provides to us (such as, Recipient Information),
                            Customer has the full right and authority to make such provision and to allow us to use such
                            information to provide the Service (including, without limitation, for us to provide such
                            information to its data providers), (ii) none of the content (e.g. emails) transmitted,
                            uploaded or otherwise distributed by it (or its partners or any third party) through use of
                            the Service will infringe or otherwise conflict with the rights of any third party.
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" gutterBottom>
                            10. Warranty Disclaimer
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            The Website is provided "as is". Kibo and its suppliers and licensors hereby disclaim all
                            warranties of any kind, express or implied, including, without limitation, the warranties of
                            merchantability, fitness for a particular purpose and non-infringement. Neither Kibo nor its
                            suppliers and licensors, makes any warranty that the Website will be error free or that
                            access thereto will be continuous or uninterrupted. You understand that you download from,
                            or otherwise obtain content or services through, the Website at your own discretion and
                            risk.
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Terms);
