import { observable, action, runInAction } from "mobx";
import { getAccountsByWorkspaceId } from "../services/account.service";
import { StoreClassInterface, RootStore } from "./RootStore";

export interface IChannel {
    provider: string;
    name: string;
    socialId?: string;
    picture?: string;
    workspaceId: string;
    accessToken?: string;
    tokenSecret?: string;
    consumerKey?: string;
    consumerSecret?: string;
    forwardEmail?: string;
    chatId?: string;
    key?: string;
    _id?: string;
    email?: string;
}

export class ChannelStore implements StoreClassInterface {
    @observable accounts: Array<IChannel> = [];
    @observable selectedAccount: IChannel | null = null;
    @observable newAccounts: Array<IChannel> = [];
    @observable search: string = "";
    @observable total: number = 0;

    readonly name = "ChannelStore";
    readonly rootStore: RootStore;
    readonly pageSize = 20;

    constructor(root: RootStore) {
        this.rootStore = root;
    }

    @action loadAccountsByWorkspaceId = async (skip = 0) => {
        const result = await getAccountsByWorkspaceId(this.pageSize, skip, this.search);
        if (result.status === "success") {
            runInAction(() => {
                this.accounts = skip === 0 ? result.data.accounts : [...this.accounts, ...result.data.accounts];
                this.total = result.data.total;
            });
        }
    };

    @action addAccounts = (addedAccounts: Array<IChannel>) => {
        runInAction(() => {
            this.newAccounts = addedAccounts;
        });
    };

    @action setAccounts = (accounts: Array<IChannel>) => {
        runInAction(() => {
            this.accounts = accounts;
        });
    };

    @action setSelectedAccount = (selectedAccount: IChannel | null) => {
        runInAction(() => {
            this.selectedAccount = selectedAccount;
        });
    };

    @action onChangeFilter = (search: string) => {
        this.search = search;
        setTimeout(() => {
            this.loadAccountsByWorkspaceId();
        }, 500);
    };

    @action deleteAccount = (id: string) => {
        this.accounts = this.accounts.filter((account) => account._id !== id);
        this.total = this.total - 1;
    };
}
