import React, { useState, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import BusinessIcon from "@material-ui/icons/Business";
import WorkIcon from "@material-ui/icons/Work";
import LanguageIcon from "@material-ui/icons/Language";
import HomeWorkIcon from "@material-ui/icons/HomeWork";

import ContactCard from "./Card";
import { useStores } from "../../../../hooks";
import Row from "./Row";
import { IContact } from "../../../../stores/ContactStore";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "grid",
        "grid-template-columns": "1fr",
        gridGap: theme.spacing(1),
        alignItems: "center",
        justifyItems: "flex-start",
        [theme.breakpoints.down("xs")]: {
            gridGap: "2em",
        },
    },
}));

const CompanyInfo = observer(() => {
    const classes = useStyles();
    const { contactStore } = useStores();
    const { selectedContact } = contactStore;

    const rowData: Array<{ key: keyof IContact; tag: string; icon: JSX.Element }> = [
        { key: "company", tag: "Company", icon: <BusinessIcon color="primary" /> },
        { key: "jobTitle", tag: "Job title", icon: <WorkIcon color="primary" /> },
        { key: "employees", tag: "Employees", icon: <BusinessIcon color="primary" /> },
        { key: "companyWebsite", tag: "Website", icon: <LanguageIcon color="primary" /> },
        { key: "companyAddress", tag: "Address", icon: <HomeWorkIcon color="primary" /> },
    ];

    const [tempContact, setTempContact] = useState<IContact | null>(Object.assign({}, selectedContact));

    const onFocus = (key: keyof IContact, value: boolean) => {
        if (!value && tempContact?.[key] !== selectedContact?.[key]) {
            contactStore.saveUpdatedContact();
            setTempContact(selectedContact);
        }
    };
    return (
        <ContactCard title={"Company Details"}>
            <div className={classes.root}>
                {rowData.map((element) => {
                    return (
                        <Row
                            key={element.key}
                            contactKey={element.key}
                            value={selectedContact?.[element.key as keyof IContact] as string}
                            handleOnChange={contactStore.updateSelectedContact}
                            handleFocus={onFocus}
                            tag={element.tag}
                            icon={element.icon}
                        />
                    );
                })}
            </div>
        </ContactCard>
    );
});

export default CompanyInfo;
