import { workspaceAxiosInstance, userWorkspaceAxiosInstance } from "../utils/axios.instance";
import { getLocalStorage } from "./localStorageservice";

export const getWorkspacesByUser = async () => {
    const userJson = getLocalStorage("user");
    if (userJson) {
        const user = JSON.parse(userJson);
        const response = await userWorkspaceAxiosInstance({
            method: "GET",
            url: `/${user._id}`,
        });

        return response.data;
    }
};

export const addWorkspace = async (file: File, name: string) => {
    const userJson = getLocalStorage("user") as any;

    if (userJson) {
        const user = JSON.parse(userJson);
        const response = await workspaceAxiosInstance({
            method: "POST",
            url: "",
            data: {
                picture: "",
                name: name,
                userId: user._id,
            },
        });

        return response.data;
    }
};

export const hasWorkspace = () => {
    return getLocalStorage("workspace");
};
