import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import Dialog from "@material-ui/core/Dialog";
import ButtonBase from "@material-ui/core/ButtonBase";
import ReactPlayer from "react-player";
import { icons } from "../../utils/contants";
import { useCallbackOutsideElement } from "../../utils/helperFunctions";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: "absolute",
        top: " 50%",
        left: "50%",
        marginTop: " -50px",
        marginLeft: "-50px",
        width: "100%",
        height: "100vh",
    },
    media: {
        height: "400px",
        width: "400px",
    },
    controllers: {
        display: "flex",
        justifyContent: "space-around",
    },
    arrowWrapper: {
        padding: "5px",
    },
}));
const MediaPreview = observer(
    (props: {
        mediaType: string;
        currentMediaIndex: number;
        mediaArrayLength: any;
        mediaUrl: string;
        previewLeftImage: any;
        previewRightImage: any;
        closeDialog: any;
        openDialog: boolean;
    }): JSX.Element => {
        const classes = useStyles();
        const {
            mediaType,
            currentMediaIndex,
            mediaArrayLength,
            mediaUrl,
            previewLeftImage,
            previewRightImage,
            closeDialog,
            openDialog,
        } = props;
        const wrapperRef = useRef<HTMLHeadingElement>(null);

        const handleKeyPress = (e: any) => {
            if (e.keyCode === 39 && currentMediaIndex !== mediaArrayLength - 1) {
                previewRightImage();
            } else if (e.keyCode === 37 && currentMediaIndex !== 0) {
                previewLeftImage();
            } else if (e.keyCode === 27) {
                closeDialog();
            }
        };
        useCallbackOutsideElement(wrapperRef, () => closeDialog());
        return (
            <Dialog aria-labelledby="simple-dialog-title" open={openDialog} onKeyDown={(e) => handleKeyPress(e)}>
                <div ref={wrapperRef}>
                    {mediaType === "VIDEO" && <ReactPlayer volume={0} playing={true} url={mediaUrl} />}
                    {mediaType === "IMAGE" && <CardMedia className={classes.media} image={mediaUrl} title={mediaUrl} />}
                    <div className={classes.controllers}>
                        {currentMediaIndex !== 0 && (
                            <ButtonBase autoFocus onClick={() => previewLeftImage()} color="primary">
                                <img src={icons.leftArrowCircle} />
                            </ButtonBase>
                        )}
                        <ButtonBase autoFocus onClick={() => closeDialog()} color="primary">
                            <img src={icons.cancelCircle} />
                        </ButtonBase>
                        {currentMediaIndex !== mediaArrayLength - 1 && (
                            <ButtonBase autoFocus onClick={() => previewRightImage()} color="primary">
                                <img src={icons.rightArrowCircle} />
                            </ButtonBase>
                        )}
                    </div>
                </div>
            </Dialog>
        );
    }
);

export default MediaPreview;
