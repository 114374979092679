import { observable, action, runInAction } from "mobx";
import { getUserNotifications, removeUserNotifications } from "../services/notifcations.service";

import { StoreClassInterface, RootStore } from "./RootStore";

export interface INotification {
    thumbnail: string;
    description: string;
    title: string;
    type: string;
    updatedAt: Date;
    userId: string;
    workspaceId: string;
    _id: string;
    url: string;
}

export class NotificationsStore implements StoreClassInterface {
    @observable notifications: INotification[] = [];
    @observable total: number = 0;
    @observable updatedTotal: number = 0;
    @observable notificationsType: string = "all";
    @observable isFetching: boolean = false;

    readonly name = "SettingsStore";
    readonly rootStore: RootStore;
    readonly pageSize = 20;

    constructor(root: RootStore) {
        this.rootStore = root;
    }

    @action async addNotification(notification: INotification) {
        this.notifications = [notification, ...this.notifications];
    }

    @action async getNotifications(skip: number, type: string) {
        this.isFetching = true;
        const userId = this.rootStore.userStore.user?._id || null;
        let res: any = await getUserNotifications(userId, skip, this.pageSize, type);
        runInAction(() => {
            this.notifications = [...this.notifications, ...res.data.notifications];
            this.isFetching = false;
            this.total = this.notificationsType === "all" ? res.data.total : res.data.updatedTotal;
            this.updatedTotal = res.data.updatedTotal;
        });
    }

    @action.bound switchNotificationsType(value: string) {
        this.notificationsType = value;
        this.notifications = [];
        this.total = 0;
        this.getNotifications(this.notifications.length, this.notificationsType);
    }

    @action.bound async removeNotifications(id: string = "") {
        const res = await removeUserNotifications(id);

        runInAction(() => {
            if (res.status === "success") {
                if (!id) {
                    this.total = 0;
                    this.notifications = [];
                } else {
                    this.total = this.total - 1;
                    this.notifications = this.notifications.filter((notification) => notification._id !== id);
                }
            }
        });
    }
}
