import React from "react";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles, Theme } from "@material-ui/core/styles";

import { IContact } from "../../../../stores/ContactStore";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "grid",
        "grid-template-columns": "2fr 2fr",
        gridGap: theme.spacing(1),
        alignItems: "center",
        justifyItems: "flex-start",
        width: "100%",
        [theme.breakpoints.down("xs")]: {
            gridGap: 0,
            "grid-template-columns": "1fr",
        },
    },
    detailTagContainer: {
        display: "flex",
        alignItems: "center",
        fontWeight: 600,
        size: 16,
        fontSize: "16px",
    },
    detailTag: {
        marginLeft: theme.spacing(1),
    },
    input: {
        borderRadius: 4,
        border: "1px solid #fff",
        "&:hover": {
            borderColor: theme.palette.secondary.main,
            backgroundcolor: theme.palette.background.default,
        },
        "&:focus-within": {
            borderColor: theme.palette.secondary.main,
            backgroundcolor: theme.palette.background.default,
        },
        paddingLeft: theme.spacing(1),
        color: "#3D3D3D",
        width: "90%",
        [theme.breakpoints.down("xs")]: {
            marginLeft: "24px",
        },
    },
    focusedInput: {
        borderColor: theme.palette.secondary.main,
        backgroundcolor: theme.palette.background.default,
    },
}));

const Row = (props: {
    icon?: JSX.Element;
    value?: string;
    contactKey: keyof IContact;
    handleFocus: Function;
    handleOnChange: Function;
    tag: string;
}) => {
    const classes = useStyles();
    const { icon, value, contactKey, handleFocus, handleOnChange, tag } = props;
    return (
        <div className={classes.root}>
            <div className={classes.detailTagContainer}>
                {icon && icon}
                <span className={classes.detailTag}>{tag}</span>
            </div>
            <InputBase
                id="outlined-basic"
                value={value}
                className={classes.input}
                onFocus={() => handleFocus(contactKey, true)}
                onBlur={() => handleFocus(contactKey, false)}
                onChange={(event) => handleOnChange(contactKey, event.target.value)}
            />
        </div>
    );
};

export default Row;
