import React, { useState } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { observer } from "mobx-react-lite";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import clsx from "clsx";

import ContactCard from "./Card";
import { useStores } from "../../../../hooks";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "grid",
        "grid-template-columns": "2fr 3fr 1fr",
        gridGap: theme.spacing(1),
        alignItems: "center",
        justifyItems: "flex-start",
    },
    detailTagContainer: {
        display: "flex",
        alignItems: "center",
        size: 16,
        fontSize: "16px",
        color: theme.palette.primary.main,
    },
    input: {
        borderRadius: 4,
        border: "1px solid #fff",
        "&:hover": {
            borderColor: theme.palette.secondary.main,
            backgroundcolor: theme.palette.background.default,
        },
        "&:focus-within": {
            borderColor: theme.palette.secondary.main,
            backgroundcolor: theme.palette.background.default,
        },
        paddingLeft: theme.spacing(1),
        color: "#3D3D3D",
        width: "100%",
    },
    newInput: {
        background: theme.palette.background.default,
        borderRadius: 4,
        border: "1px solid #fff",
        paddingLeft: theme.spacing(1),
        color: "#3D3D3D",
        width: "100%",
    },
    iconContainer: {
        width: "100%",
        cursor: "pointer",
    },
}));

export interface IOnHover {
    [key: string]: boolean;
}

const Data = observer(() => {
    const classes = useStyles();
    const { contactStore } = useStores();
    const theme = useTheme();
    const { selectedContact } = contactStore;
    const [addRow, setAddRow] = useState(false);
    const [newRowKey, setNewRowKey] = useState("");
    const [newRowValue, setNewRowVaue] = useState("");
    const [onHover, setOnHover] = useState<IOnHover>({});
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const onFocus = (key: string, value: boolean) => {
        if (!value) {
            contactStore.saveUpdatedContact();
        }
    };

    const addData = () => {
        setAddRow(true);
    };

    const handleOnHover = (key: string, value: boolean) => {
        setOnHover({ [key]: value });
    };

    const saveNewRowData = () => {
        if (newRowKey !== "" && newRowValue !== "") {
            contactStore.addPropertyFromContactData(newRowKey, newRowValue);
            setAddRow(false);
            setNewRowKey("");
            setNewRowVaue("");
        }
    };
    return (
        <ContactCard title={"Data"} button={!addRow} handleClick={addData}>
            <div>
                {Object.entries((selectedContact?.data as Object) || {}).map(([key, value]) => (
                    <div
                        key={key}
                        className={classes.root}
                        onMouseEnter={() => handleOnHover(key, true)}
                        onMouseLeave={() => handleOnHover(key, false)}
                    >
                        <div className={classes.detailTagContainer}>
                            <span>{key}</span>
                        </div>
                        <InputBase
                            id="outlined-basic"
                            value={value}
                            className={clsx(classes.input)}
                            onFocus={() => onFocus(key, true)}
                            onBlur={() => onFocus(key, false)}
                            onChange={(event) => contactStore.updateSelectedContactData(key, event.target.value)}
                        />
                        {onHover[key as keyof IOnHover] && !mobile && (
                            <div
                                className={classes.iconContainer}
                                onClick={() => {
                                    contactStore.removePropertyFromContactData(key);
                                }}
                            >
                                <HighlightOffIcon color="secondary" />
                            </div>
                        )}
                        {mobile && (
                            <div
                                className={classes.iconContainer}
                                onClick={() => {
                                    contactStore.removePropertyFromContactData(key);
                                }}
                            >
                                <HighlightOffIcon color="secondary" />
                            </div>
                        )}
                    </div>
                ))}
                {addRow && (
                    <div className={classes.root}>
                        <InputBase
                            id="outlined-basic"
                            className={clsx(classes.newInput)}
                            onBlur={() => saveNewRowData()}
                            onChange={(event) => setNewRowKey(event.target.value)}
                            placeholder={"Enter key..."}
                        />
                        <InputBase
                            id="outlined-basic"
                            className={clsx(classes.newInput)}
                            onBlur={() => saveNewRowData()}
                            onChange={(event) => setNewRowVaue(event.target.value)}
                            placeholder={"Enter value..."}
                        />
                    </div>
                )}
            </div>
        </ContactCard>
    );
});

export default Data;
