import { twitterAxiosInstance } from "../utils/axios.instance";

export const getProfile = async (
    id: string,
    consumerKey: string,
    consumerSecret: string,
    accessToken: string,
    tokenSecret: string,
    devEnvironment: string
) => {
    const response = await twitterAxiosInstance({
        url: `profile/${id}/${consumerKey}/${consumerSecret}/${accessToken}/${tokenSecret}/${devEnvironment}`,
        method: "GET",
    });

    return response.data;
};
