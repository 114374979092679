import React, { useState, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Checkbox from "@material-ui/core/Checkbox";
import { observer } from "mobx-react-lite";

import Modal from "../../common/Modal";
import { addAccount } from "../../../services/account.service";
import { error } from "../../../services/toast.service";
import AccountProvider from "./AccountProvider";
import { useStores } from "../../../hooks";

const useStyles = makeStyles((theme) =>
    createStyles({
        container: {
            display: "grid",
            gap: "1em",
            [theme.breakpoints.up("md")]: {
                "grid-template-columns": "1fr 1fr 1fr",
            },
            [theme.breakpoints.down("sm")]: {
                "grid-template-columns": "1fr 1fr",
            },
            [theme.breakpoints.down("xs")]: {
                "grid-template-columns": "1fr",
            },
        },
        title: {
            color: "#6D6D6D",
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2.5),
        },
        avatar: {
            width: "55px",
            height: "55px",
        },
        card: {
            display: "flex",
            flexDirection: "row-reverse",
            height: "90px",
            position: "relative",
            borderRadius: "5px",
            padding: "20px 5px 20px 20px",
            minWidth: "200px",
        },
        checkBoxWrapper: {
            position: "absolute",
            top: "-2px",
        },
        cardBody: {
            display: "flex",
            alignItems: "center",
            minWidth: "220px",
            width: "100%",
        },
        nameWrapper: {
            paddingLeft: theme.spacing(3),
            display: "flex",
            flexDirection: "column",
            minWidth: "0px",
        },
        name: {
            fontWeight: 700,
            margin: "2px 0 2px 0",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        selectedCard: {
            background: "#F2F9FF !important",
        },
        defaultCard: {
            background: "#F2F2F2",
        },
    })
);

interface IAccountState {
    [key: string]: boolean;
}

const Channels = observer(
    (props: { open: boolean; setOpen: Function; accounts: Array<any>; addNewAccount: Function }) => {
        const classes = useStyles();
        const { workspaceStore } = useStores();
        const accounts = props.accounts || [];
        const [accountsState, setAccountsState] = useState<IAccountState>({});

        const createChannel = async (account: any) => {
            let result;
            let workspace = workspaceStore.workspaces[0];
            try {
                result = await addAccount({ ...account, socialId: account.id, workspaceId: workspace?._id });
                props.addNewAccount(result.data);
            } catch (err) {
                error(err.response.data.data);
            }
        };

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setAccountsState({ ...accountsState, [event.target.name]: event.target.checked });
        };

        const handleSave = async () => {
            let promisesArray: any = [];
            accounts.map((account: any, index: number) => {
                if (accountsState[index.toString()]) {
                    promisesArray.push(
                        new Promise((resolve, reject) => {
                            createChannel(account);
                        })
                    );
                }
            });
            Promise.all([...promisesArray]).then((values) => {});
        };

        return (
            <Modal
                open={props.open}
                onClose={() => props.setOpen(false)}
                handleConfirm={handleSave}
                disableBackdropClick={true}
                displaySaveButton={accounts.length > 0 ? false : true}
            >
                <div>
                    {accounts.length > 0 && <AccountProvider provider={accounts[0]?.provider} />}
                    <div className={classes.title}>
                        {accounts.length > 0
                            ? "Select all the accounts you want to add"
                            : "There are no accounts to add!"}
                    </div>
                    <div className={classes.container}>
                        {accounts.map((account: any, index: number) => {
                            return (
                                <div
                                    className={` ${classes.card} ${
                                        accountsState[index] ? classes.selectedCard : classes.defaultCard
                                    }`}
                                    key={index}
                                >
                                    <div className={classes.checkBoxWrapper}>
                                        <Checkbox
                                            checked={accountsState[index.toString()] || false}
                                            onChange={handleChange}
                                            name={index.toString()}
                                            color="primary"
                                        />
                                    </div>
                                    <div className={classes.cardBody}>
                                        <Avatar className={classes.avatar} src={account.picture} />
                                        <div className={classes.nameWrapper}>
                                            <div className={classes.name}>{account.name}</div>
                                            {/* <div>{account.name}</div> */}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Modal>
        );
    }
);

export default Channels;
