import React from "react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const Alert = () => {
    return <ToastContainer />;
};

export default Alert;
